import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import CON from '../../CON';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useSelector} from "react-redux";
import background from "../../assets/background.jpg";
import RedeemReward from "./RedeemReward";

const RewardCard = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let today = new Date();

    return (
        <div>
            <Button style={{width: '90vw', overflow: 'hidden', height: 135, marginBottom: 25, borderRadius: 20}} onClick={handleClickOpen}>
                <img style={styles.image} src={`${CON.IMAGE_BASE_URL}${props.reward.background}` || '/path/to/local/fallback/image.png'} alt={props.reward.name} />
                <Typography style={styles.name} gutterBottom variant="h5" component="div" noWrap>
                    {props.reward.name}
                </Typography>
            </Button>
            <Dialog
                onClose={handleClose}
                fullWidth
                aria-labelledby="reward-detail-dialog"
                open={open}
                // PaperProps={{
                //     style: { backgroundColor: 'unset' },
                // }}
                // style={{ backgroundColor: 'unset' }}
            >
                <AppBar style={{ position: 'relative', backgroundColor: '#9c9a9a' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {props.reward.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    <div
                        style={{
                            backgroundImage: `url(${background})`,
                            backgroundSize: 'cover',
                            height: '100%',
                            filter: 'blur(6px)', // Adjust the blur value as needed
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: -1, // Ensure the background is behind the content
                        }}
                    />
                    <Box style={{margin: 15, paddingBottom: 20}}>
                        {/*<Box>*/}
                        {/*    <Typography style={detailStyles.header}>{props.reward.name}</Typography>*/}
                        {/*    /!*<Text style={styles.message}>{props.route.params.reward.description}</Text>*!/*/}
                        {/*</Box>*/}
                        <Box>
                            <Typography style={detailStyles.message}>{props.reward.description}</Typography>
                        </Box>
                        {props.reward.storeName &&
                            <Box>
                                <Typography style={detailStyles.date}>{props.reward.storeName}</Typography>
                            </Box>
                        }
                        <Box>
                            <Typography style={detailStyles.date}>{today.toDateString()}</Typography>
                        </Box>
                        <Box style={{ textAlign: 'center'}}>
                            <RedeemReward handleClose={handleClose} reward={props.reward} />
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const styles = {
    container: {
        padding: 0,
        width: '90vw',
        height: '25vw',
        marginBottom: 30,
        borderRadius: 6,
        borderColor: "transparent",
        backgroundColor: 'transparent',
    },
    image: {
        objectFit: 'contain',
        // height: '30vw',
        borderRadius: 12,
        overflow: 'hidden',
    },
    header: {
        fontWeight: '500',
        fontSize: 15,
        position: 'absolute',
        top: '40%',
        left: 10,
        textAlignVertical: 'center',
        textAlign: 'center',
    },
    name: {
        position: "absolute",
        fontSize: 22,
        top: 50,
        left: 15,
        fontWeight: 'bold',
        color: "#fff"
    }
};

const detailStyles = {
    sectionHeader: {
        fontSize: 22,
        fontWeight: '500',
        alignSelf: 'flex-start',
        marginLeft: 15,
        color: '#ffffff',
    },
    header: {
        fontSize: 27,
        fontWeight: '500',
        textAlign:'center',
        marginLeft: 15,
        color: '#ffffff',
    },
    validity: {
        fontSize: 25,
        fontWeight: '500',
        textAlign:'center',
        marginLeft: 15,
        color: 'lightgreen',
    },
    date: {
        fontSize: 20,
        fontWeight: '500',
        textAlign:'center',
        marginLeft: 15,
        // marginTop: 15,
        // color: 'lightgreen',
    },
    expires: {
        fontSize: 15,
        fontWeight: '500',
        textAlign:'center',
        marginLeft: 15,
        color: '#ffffff',
    },
    message: {
        fontSize: 25,
        // fontWeight: '500',
        textAlign:'center',
        marginLeft: 15,
        color: '#ffffff',
    },
    background: {
        flex: 1,
        resizeMode: 'cover', // or 'stretch' or 'contain' based on your preference
    },
};

export default RewardCard;
