import axios from 'axios';
import CON from '../CON';

class RewardService {

    getRewards(accountID) {
    return axios
      .post(CON.API_URL + 'promotion/u/getAllU',{accountID})
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log('API failed:' + err);
        return null;
      });
  }

    redeemReward(promotionID, accountID) {
        return axios
            .post(CON.API_URL + 'promotion/u/redeemReward', {promotionID, accountID})
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return null;
                }
            })
            .catch(err => {
                console.log('API failed:' + err);
                return null;
            });
    }
}

export default new RewardService();
