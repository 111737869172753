import { createSlice } from '@reduxjs/toolkit'

export const drinkReducer = createSlice({
    name: 'drinkReducer',
    initialState: {
        drinks: []
    },
    reducers: {
        setDrinks: (state, action) => {
            state.drinks = action.payload.drinks;
        },
    },
})

export const { setDrinks } = drinkReducer.actions

export default drinkReducer;
