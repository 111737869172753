import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import getText from "../../language/getText";
import RoleInfoCard from "./RoleInfoCard";
import {Alert} from "@mui/material";


const AccountCreationReview = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [justView, setJustView] = React.useState(true);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    General Information
                </Typography>

                <hr />

                <div className="d-inline-block m-1">
                    <div className="m-1">
                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'AM9')}:
                            </Typography>

                            <Typography variant="h3" style={{fontSize: 15, display: "inline-block"}}>
                                {props.firstname}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'AM10')}:
                            </Typography>

                            <Typography variant="h3" style={{fontSize: 15, display: "inline-block"}}>
                                {props.middleName}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'AM11')}:
                            </Typography>

                            <Typography variant="h3" style={{fontSize: 15, display: "inline-block"}}>
                                {props.lastname}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'AM12')}:
                            </Typography>

                            <Typography variant="h3" style={{fontSize: 15, display: "inline-block"}}>
                                {props.email}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'AM25')}:
                            </Typography>

                            <Typography variant="h3" style={{fontSize: 15, display: "inline-block"}}>
                                {props.phoneNumber}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                Membership Type:
                            </Typography>

                            <Typography variant="h3" style={{fontSize: 15, display: "inline-block"}}>
                                {props.membershipType}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                Store:
                            </Typography>

                            <Typography variant="h3" style={{fontSize: 15, display: "inline-block"}}>
                                {props.store.name}
                            </Typography>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    Roles & Permissions
                </Typography>

                <hr />



                <div className="d-inline-block m-1">
                    <div className="d-inline-block m-1">
                        {props.selectedRole !== null ?
                            <RoleInfoCard role={props.selectedRole} selectedRole={null} setSelectedRole={null} justView={justView}  />
                            :
                            (((props.accountPermissions !== null) && (props.accountPermissions.length > 0)) ?
                                    <div>
                                        {props.accountPermissions.map((permission, index) => {
                                            return (
                                                <div key={index} style={{display: "inline-block", marginRight: 20}}>
                                                    <Typography style={{fontWeight: "500", marginLeft: 15, fontSize: 17, textAlign: "left"}}>{permission.name}</Typography>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    :
                                    <Alert severity={"error"}>No Role Selected</Alert>
                            )
                        }
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}


export default AccountCreationReview;
