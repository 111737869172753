import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";

const ProductStockSelect = (props) => {

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="production-variation-select-label" required>Product Variations</InputLabel>
                <Select
                    labelId="production-variation-select-label"
                    id="production-variation-select-label"
                    value={props.selectedProductVariation}
                    label="Production Variation"
                    onChange={(event) => {props.setSelectedProductVariation(event.target.value);}}
                >
                    {props.product.productVariations.map((productVariation,index) => (
                        <MenuItem key={productVariation.productVariationID} value={productVariation}>
                            {productVariation.value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default ProductStockSelect;
