import * as React from 'react';
import ZipCodeTransfer from "./ZipCodeTransfer";
import Button from "@mui/material/Button";
import StoreService from "../../services/storeService";
import {setZipCodes} from "../../reducers/storeReducer";
import {useSelector} from "react-redux";

const StoreZipCodes = (props) => {
    const zipCodes = useSelector(state => state.storeManager.zipCodes);

    const handleZipCode = () => {
        StoreService.updateZipCodes(zipCodes).then((response) =>{
            if (response.status === true){
                console.log(response)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <div style={{flexGrow: 1}}>
            <ZipCodeTransfer store={props.store} />
            <Button
                onClick={() => {
                    handleZipCode();
                }}
                style={{float: "right"}}
                variant={"contained"}
            >
                Save
            </Button>
        </div>
    );
}

export default StoreZipCodes;
