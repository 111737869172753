import axios from "axios";
import CON from "../CON";

class StockService {
    create(data) {
        return axios
            .post(CON.IP + "/stock/create", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changePrice(stockID, price) {
        return axios
            .post(CON.IP + "/stock/changePrice", {stockID, price})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    voidStock(stockID, voidReason) {
        return axios
            .post(CON.IP + "/stock/voidStock", {stockID, voidReason})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeQty(stockID, qty) {
        return axios
            .post(CON.IP + "/stock/changeQty", {stockID, qty})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    getAll() {
        return axios
            .post(CON.IP + "/stock/getAll")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }
}

export default new StockService();
