module.exports = {
    // IP: 'http://localhost:5001',
    // API_URL: 'http://localhost:5001/',
    // IMAGE_BASE_URL: 'http://localhost:5001/asset/image/',
    // GET_IMAGE: 'http://localhost:5001/asset/image/',
    IP: 'https://api.risingstormbrewingapi.com',
    API_URL: 'https://api.risingstormbrewingapi.com/',
    IMAGE_BASE_URL: 'https://api.risingstormbrewingapi.com/asset/image/',
    GET_IMAGE: 'https://api.risingstormbrewingapi.com/asset/image/'
}
