import { createSlice } from '@reduxjs/toolkit'

export const reportReducer = createSlice({
    name: 'reportReducer',
    initialState: {
        orderReports: null,
        userReports: null,
        dashboardCards: null,
    },
    reducers: {
        setOrderReports: (state, action) => {
            if (action.payload.orderReports){
                state.orderReports = action.payload.orderReports;
            }
        },
        setUserReports: (state, action) => {
            if (action.payload.userReports){
                state.userReports = action.payload.userReports;
            }
        },
        setDashboardCards: (state, action) => {
            if (action.payload.dashboardCards){
                state.dashboardCards = action.payload.dashboardCards;
            }
        },
    },
})

export const { setOrderReports, setUserReports, setDashboardCards } = reportReducer.actions

export default reportReducer;
