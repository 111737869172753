import { configureStore } from '@reduxjs/toolkit'
import appReducer from "./reducers/appReducer";
import authenticationReducer from "./reducers/authenticationReducer";

// Reducers
import rewardReducer from "./reducers/rewardReducer";
import categoryReducer from "./reducers/categoryReducer";
import drinkReducer from "./reducers/drinkReducer";
import preferenceReducer from "./reducers/preferenceReducer";
import locationReducer from './reducers/locationReducer';
import promotionReducer from "./reducers/promotionReducer";

import accountReducer from "./reducers/accountReducer";
import storeReducer from "./reducers/storeReducer";
import productReducer from "./reducers/productReducer";
import metaReducer from "./reducers/metaReducer";
import brandReducer from "./reducers/brandReducer";
import orderReducer from "./reducers/orderReducer";
import reportReducer from "./reducers/reportReducer";

export default configureStore({
    reducer: {
        authManager: authenticationReducer.reducer,
        preferenceManager: preferenceReducer.reducer,
        promotionManager: promotionReducer.reducer,
        appManager: appReducer.reducer,
        drinkManager: drinkReducer.reducer,
        rewardManager: rewardReducer.reducer,
        categoryManager: categoryReducer.reducer,
        locationManager: locationReducer.reducer,

        accountManager: accountReducer.reducer,
        productManager: productReducer.reducer,
        reportManager: reportReducer.reducer,
        orderManager: orderReducer.reducer,
        storeManager: storeReducer.reducer,
        brandManager: brandReducer.reducer,
        metaManager: metaReducer.reducer,

    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});
