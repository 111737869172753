import axios from "axios";
import CON from "../CON";


class AccountService {

    createAccount(firstname, lastname, middleName, email, phoneNumber, dob, gender, accountType, accountPermissions, selectedRole, membershipType, storeID) {
        return axios
            .post(CON.IP + "/account/create", { firstname, lastname, middleName, email, phoneNumber, dob, gender, accountType, accountPermissions, selectedRole, membershipType, storeID })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('API failed: No data received!');
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***');
                console.log(err.toString());
                return null;
            });
    }

    search(term) {
        return axios
            .post(CON.IP + "/account/search", { term })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('API failed: No data received!');
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***');
                console.log(err.toString());
                return null;
            });
    }

    getAllAccounts() {
        return axios
            .post(CON.IP + "/account/getAll")
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('API failed: No data received!');
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    getPermissionsSelect() {
        return axios
            .post(CON.IP + "/account/getPermissionsSelect")
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    getRoles() {
        return axios
            .post(CON.IP + "/account/getRoles")
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    changeMembershipStatus(accountID, newValue) {
        return axios
            .post(CON.IP + "/account/changeMembershipStatus",{ accountID, newValue })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    changeStatus(accountID, newValue) {
        return axios
            .post(CON.IP + "/account/changeStatus",{ accountID, newValue })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    updateAccountStore(accountID, newValue) {
        return axios
            .post(CON.IP + "/account/updateAccountStore",{ accountID, newValue })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    changeMembershipType(accountID, newValue) {
        return axios
            .post(CON.IP + "/account/changeMembershipType",{ accountID, newValue })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }
}

export default new AccountService();
