import React from 'react';
import 'chart.js/auto'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import {useSelector} from "react-redux";
import getText from "../../language/getText";

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend );

const LineChart = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },
        },
    };

    const data = {
        labels: props.labels,
        datasets: [
            {
                label: props.label,
                borderRadius: 6,
                data: props.data,
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
                fill: true,
                tension: 0.3
            }
        ],
    };

    return (
        <Line options={options} data={data} />
    );
}


export default LineChart;
