import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import getText from "../../language/getText";
import {Alert, CardActions, CardMedia} from "@mui/material";
import Card from "@mui/material/Card";
import CON from "../../CON";
import CardContent from "@mui/material/CardContent";


const ProductCreationReview = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const isDark = useSelector(state => state.preferenceManager.isDark);

    const renderAttributeCard = (tag, index) => {
        return (
            <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                {(tag.value.assetID !== undefined && tag.value.assetID !== null) &&
                    <CardMedia
                        component="img"
                        style={{width: 100, margin: '0 auto', filter: (isDark ?
                                    "invert(100%) sepia(96%) saturate(6727%) hue-rotate(181deg) brightness(130%) contrast(106%)"
                                    :
                                    "invert(0%) sepia(96%) saturate(0%) hue-rotate(262deg) brightness(98%) contrast(100%);"
                            )}}
                        image={CON.GET_IMAGE + tag.value.assetID}
                        alt={tag.value.value + "-icon"}
                    />
                }
                <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                        ID: {tag.meta.metaID} | Name: {tag.meta.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Value: {tag.value.value !== undefined ? tag.value.value : tag.value}
                    </Typography>
                </CardContent>
            </Card>
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    General Information
                </Typography>

                <hr />

                <div className="d-inline-block m-1">
                    <div className="m-1">
                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'PCM17')}:
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.name}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'PCM20')}:
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.category !== '' ? props.category.name : ''}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'PCM18')}:
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.description}
                            </Typography>
                        </div>

                    </div>
                </div>
            </Grid>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    Product Images
                </Typography>

                <hr />

                <div className="d-inline-block m-1">
                    {props.isFilePicked ? (
                        (props.selectedFiles.map((file, index) => {
                            return props.renderImagePreview(file, index, true);
                        }))
                    ) : (
                        <p>Upload product images</p>
                    )}
                </div>
            </Grid>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    Product Attributes
                </Typography>

                <hr />

                <div className="d-inline-block m-1">
                    {props.metaTags.length > 0 ? (
                        (props.metaTags.map((tag, index) => {
                            return renderAttributeCard(tag, index);
                        }))
                    ) : (
                        <p>Product has no attributes</p>
                    )}
                </div>
            </Grid>
        </Grid>
    );
}


export default ProductCreationReview;
