import * as React from 'react';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Card from "@mui/material/Card";
import {Alert, CardActions, CardMedia} from "@mui/material";
import CON from "../../CON";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MetaSelect from "./MetaSelect";
import MetaPresetSelect from "./MetaPresetSelect";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ProductService from "../../services/productService";
import {updateProduct} from "../../reducers/productReducer";
import getText from "../../language/getText";

const ProductMeta = (props) => {
    const dispatch = useDispatch();
    const isDark = useSelector(state => state.preferenceManager.isDark);

    const [value, setValue] = React.useState('');
    const [selectedMetaTag, setSelectedMetaTag] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const handleAdd = (meta, value, isPresetValue) => {
        if (value !== ''){
            let metaStr = JSON.stringify(meta);
            let valueStr = null;
            if (isPresetValue){
                valueStr = JSON.stringify(value);
            } else {
                valueStr = value
            }
            ProductService.addMeta(props.product.productID, metaStr, valueStr, (isPresetValue ? 1 : 0)).then(response => {
                if (response){
                    if (response.status === true){
                        dispatch(updateProduct({
                            product: {
                                ...props.product,
                                metaTags: [
                                    ...props.product.metaTags,
                                    response.result
                                ]
                            }
                        }));
                        showAlertMsg(response.message, 'success');
                        clearFields();
                    } else {
                        showAlertMsg(response.message, 'error');
                    }
                } else {
                    showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            showAlertMsg('Must enter a value for the meta tag.', 'error');
        }
    }

    const removeAttribute = (productMeta) => {
        console.log(productMeta.productMetaID)
        ProductService.removeMeta(props.product.productID, productMeta.productMetaID).then(response => {
            if (response){
                if (response.status === true){
                    // let newArray = [];
                    // let objIndex = props.product.metaTags.findIndex((obj => obj.productMetaID === productMeta.productMetaID));
                    // newArray[objIndex] = props.product.metaTags;
                    // dispatch(updateProduct({
                    //     product: {
                    //         ...props.product,
                    //         metaTags: [
                    //             ...props.product.metaTags,
                    //             response.result
                    //         ]
                    //     }
                    // }));
                    showAlertMsg(response.message + " Please refresh page.", 'success');
                    clearFields();
                } else {
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const renderAttributeCard = (tag, index, metaID) => {
        console.log(tag)
        console.log(index)
        console.log(metaID)
        if (tag.productVariationID === null || tag.productVariationID === undefined){
            if (tag.meta.metaID === metaID){
                return (
                    <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                        {tag.value !== null &&
                            ((tag.value.assetID !== undefined && tag.value.assetID !== null) &&
                                <CardMedia
                                    component="img"
                                    style={{width: 100, margin: '0 auto', filter: (isDark ?
                                                "invert(100%) sepia(96%) saturate(6727%) hue-rotate(181deg) brightness(130%) contrast(106%)"
                                                :
                                                "invert(0%) sepia(96%) saturate(0%) hue-rotate(262deg) brightness(98%) contrast(100%);"
                                        )}}
                                    image={CON.GET_IMAGE + tag.value.assetID}
                                    alt={tag.value.value + "-icon"}
                                />
                            )
                        }
                        <CardContent>
                            <Typography gutterBottom variant="body1" component="div">
                                ID: {tag.meta.metaID} | Name: {tag.meta.name}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                Value: {tag.value.value !== undefined ? tag.value.value : tag.value}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant={"outlined"} size={"small"} onClick={() => {
                                removeAttribute(tag);
                            }}>
                                Remove
                            </Button>
                        </CardActions>
                    </Card>
                );
            } else if (tag.meta.metaID !== 5 && tag.meta.metaID !== 6 && tag.meta.metaID !== 7 && metaID === null){
                return (
                    <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                        {tag.value !== null &&
                            ((tag.value.assetID !== undefined && tag.value.assetID !== null) &&
                                <CardMedia
                                    component="img"
                                    style={{width: 100, margin: '0 auto', filter: (isDark ?
                                                "invert(100%) sepia(96%) saturate(6727%) hue-rotate(181deg) brightness(130%) contrast(106%)"
                                                :
                                                "invert(0%) sepia(96%) saturate(0%) hue-rotate(262deg) brightness(98%) contrast(100%);"
                                        )}}
                                    image={CON.GET_IMAGE + tag.value.assetID}
                                    alt={tag.value.value + "-icon"}
                                />
                            )
                        }
                        <CardContent>
                            <Typography gutterBottom variant="body1" component="div">
                                ID: {tag.meta.metaID} | Name: {tag.meta.name}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                Value: {tag.value.value !== undefined ? tag.value.value : tag.value}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant={"outlined"} size={"small"} onClick={() => {
                                removeAttribute(tag);
                            }}>
                                Remove
                            </Button>
                        </CardActions>
                    </Card>
                );
            }
        }
    };

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setValue('');
        setSelectedMetaTag('');
    }

    useEffect(() => {

    },[]);

    return (
        <Box noValidate sx={{ mt: 3 }}>
            {showAlert &&
                <Alert severity={alertSeverity}>{alertMsg}</Alert>
            }
            <Grid container spacing={2}>
                <Grid item  xs={12} sm={6}>
                    <MetaSelect selectedMetaTag={selectedMetaTag} setSelectedMetaTag={setSelectedMetaTag} />
                </Grid>
                <Grid item  xs={12} sm={12}>
                    {selectedMetaTag !== '' &&
                        (selectedMetaTag.presets.length > 0 ?
                                <MetaPresetSelect
                                    meta={selectedMetaTag}
                                    value={value} setValue={setValue}
                                />
                                :
                                <TextField
                                    autoFocus
                                    value={value}
                                    onChange={event => setValue(event.target.value)}
                                    name="value"
                                    required
                                    fullWidth
                                    id="value"
                                    label="value"
                                />
                        )
                    }
                </Grid>
                <Grid item  xs={12} sm={12}>
                    <Button variant="outlined" disabled={selectedMetaTag === '' || value === ''} style={{minWidth:175}} onClick={() => { handleAdd(selectedMetaTag, value, selectedMetaTag.presets.length > 0) }}>
                        Add Attribute
                    </Button>
                </Grid>
                <Grid item  xs={12} sm={12}>
                    <div>
                        <Typography id="productMetaNegativesTitle" variant="h6" component="div">
                            General
                        </Typography>
                        {props.product.metaTags.length > 0 ? (
                            (props.product.metaTags.map((tag, index) => {
                                return renderAttributeCard(tag, index, null);
                            }))
                        ) : (
                            <p>No other attributes set</p>
                        )}
                    </div>

                    {/*<div>*/}
                    {/*    <Typography id="productMetaFeelingsTitle" variant="h6" component="div">*/}
                    {/*        Feelings*/}
                    {/*    </Typography>*/}
                    {/*    {props.product.metaTags.length > 0 ? (*/}
                    {/*        (props.product.metaTags.map((tag, index) => {*/}
                    {/*            return renderAttributeCard(tag, index, 5);*/}
                    {/*        }))*/}
                    {/*    ) : (*/}
                    {/*        <p>No feeling attributes set</p>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                    {/*<div>*/}
                    {/*    <Typography id="productMetaFlavorsTitle" variant="h6" component="div">*/}
                    {/*        Flavors*/}
                    {/*    </Typography>*/}
                    {/*    {props.product.metaTags.length > 0 ? (*/}
                    {/*        (props.product.metaTags.map((tag, index) => {*/}
                    {/*            return renderAttributeCard(tag, index, 6);*/}
                    {/*        }))*/}
                    {/*    ) : (*/}
                    {/*        <p>No flavours attributes set</p>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                    {/*<div>*/}
                    {/*    <Typography id="productMetaNegativesTitle" variant="h6" component="div">*/}
                    {/*        Negatives*/}
                    {/*    </Typography>*/}
                    {/*    {props.product.metaTags.length > 0 ? (*/}
                    {/*        (props.product.metaTags.map((tag, index) => {*/}
                    {/*            return renderAttributeCard(tag, index, 7);*/}
                    {/*        }))*/}
                    {/*    ) : (*/}
                    {/*        <p>No flavours attributes set</p>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                </Grid>
            </Grid>
        </Box>
    );
}

export default ProductMeta;
