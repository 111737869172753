import { createSlice } from '@reduxjs/toolkit'

export const metaReducer = createSlice({
    name: 'metaReducer',
    initialState: {
        metas: [],
    },
    reducers: {
        setMetas: (state, action) => {
            if (action.payload.metas){
                state.metas = action.payload.metas;
            }
        },
        addMeta: (state, action) => {
            if (action.payload.meta){
                state.metas = [
                    ...state.metas,
                    action.payload.meta
                ];
            }
        },
        updateMeta: (state, action) => {
            if (action.payload.meta){
                let newArray = state.metas;
                let objIndex = newArray.findIndex((obj => obj.metaID === action.payload.meta.metaID));
                newArray[objIndex] = action.payload.meta;
                state.metas = newArray;
            }
        },
    },
})

export const { setMetas, addMeta, updateMeta } = metaReducer.actions

export default metaReducer;
