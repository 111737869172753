import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import PromotionTable from "./PromotionTable";
import PromotionTableFilter from "./PromotionTableFilter";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PromotionManagement = () => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const stores = useSelector(state => state.storeManager.stores);
    const dispatch = useDispatch();
    const orders = useSelector(state => state.orderManager.orders);
    const alertLst = useSelector(state => state.orderManager.alertLst);

    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = React.useState('');

    const [searchResults, setSearchResults] = React.useState(null);

    const [value, setValue] = React.useState(0);

    const handleSearch = (name, searchType) => {
        switch (searchType) {
            case 'NAME':
                if (name !== ''){
                    // ProductService.searchByName(name).then((response) =>{
                    //     if (response.status === true){
                    //         setSearchResults(response.results);
                    //     } else {
                    //         setSearchResults([]);
                    //     }
                    // }).catch((err) => {
                    //     console.log(err);
                    // });
                } else {
                    setSearchResults(null);
                }
                break;
            default:
                return;
        }
    };

    useEffect(() => {

    },[]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={(event, value) => {setValue(value)}} aria-label="order management">
                    <Tab label={getText(languagePreference.lang, 'PM1')} {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {/*<PromotionTableFilter searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearch={handleSearch} />*/}
                <PromotionTable searchResults={searchResults} />
            </TabPanel>
        </Box>
    );
}

export default PromotionManagement;
