import * as React from 'react';
import Box from '@mui/material/Box';
import { FormGroup, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import getText from "../../language/getText";
import {useSelector} from "react-redux";

const StoreTableFilter = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    return (
        <Box sx={{ flexWrap: 'wrap', '& > :not(style)': { marginBottom: 2},}}>
            <div>
                <FormGroup style={{ marginTop: 10, marginLeft: 10 }}>
                    <TextField
                        id="input-store-table-filter"
                        placeholder={getText(languagePreference.lang, 'SM4')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        style={{ marginRight: 10 }}
                        onChange={(event) => {
                            props.handleSearch(event.target.value);
                        }}
                    />
                </FormGroup>
            </div>
        </Box>
    );
}

export default StoreTableFilter;
