import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDispatch, useSelector} from "react-redux"; // Assuming you're using react-router for navigation
import {clearToken, setToken} from "../reducers/authenticationReducer";
import AuthorizationService from "../services/AuthorizationService";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const GetMissingAccountInfo = (props) => {
    const dispatch = useDispatch();

    const tokenData = useSelector(state => state.authManager.tokenData);
    const [dob, setDob] =  React.useState(new Date());
    const [email, setEmail] = React.useState(null);

    const handleClickOpen = () => {
        props.setOpen(true);
    };

    const handleClose = () => {
        props.setOpen(false);
    };

    function signOut() {
        dispatch(clearToken());
    }

    function updateAccount(){

        if (!email){
            alert('Must enter email.', 'error');
            return;
        }

        if (!props.accountData.dob) {
            if (dob.split('-').reverse().join('/') === new Date().toLocaleDateString()){
                alert('Please select a valid date of birth.');
                return;
            } else {
                if(dob >= new Date()) {
                    alert('The date of birth you selected is in the future. Please try again.');
                    return;
                } else {
                    const today = new Date();
                    const minAgeDate = new Date(today.getFullYear() - 21, today.getMonth(), today.getDate());
                    if(new Date(dob.split('-').reverse().join('/')) >= minAgeDate) {
                        alert('You must be at least 21 years old to sign up.');
                        return;
                    } else {

                    }
                }
            }
        }


        AuthorizationService.updateAccountMissingInformation(email, (props.accountData.dob ? props.accountData.dob : dob), props.userToken).then((res) => {
            if (res.status === true) {
                alert(res.message);
                dispatch(setToken({
                    token: props.userToken
                }));
            } else {
                alert(res.message)
            }
        }).catch((err) => {
            alert("Account update failed. Please try again later.")
            console.log(err);
        });
    }


    React.useEffect(() => {
        if (props.accountData?.email) {
            setEmail(props.accountData?.email);
        }
    }, [props.accountData]);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Enter Birthday</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                    <Grid container spacing={2}>
                        {!props.accountData?.email &&
                            <Grid item xs={12}>
                                <TextField
                                    style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    onChange={(val) => {setEmail(val.target.value);}}
                                />
                            </Grid>
                        }
                        {!props.accountData?.dob &&
                            <Grid item xs={12}>
                                <TextField
                                    style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                    required
                                    fullWidth
                                    id="bday"
                                    label="Birth Date"
                                    name="bday"
                                    type="date"
                                    autoComplete="bday"
                                    value={dob}
                                    onChange={(val) => { setDob(val.target.value); }}
                                />
                            </Grid>
                        }
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={updateAccount} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const styles = {
    modalView: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    contentTitle: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 5,
    },
    mainText: {
        color: '#fff',
        fontWeight: '600',
        fontSize: 17,
        marginBottom: 10,
    },
    content: {
        width: '90vw',
        backgroundColor: '#18191a',
        padding: 10,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    textInput: {
        width: '70vw',
        backgroundColor: '#999999',
        padding: 5,
        borderRadius: 6,
        fontSize: '6vw',
    },
    subscriptionBtn: {
        width: '70vw',
        height: 40,
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        borderRadius: 10
    },
    subscriptionBtnText: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    action: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#f2f2f2',
        paddingBottom: 5
    },
    message: {
        fontSize: 20,
        alignSelf: 'center',
        fontWeight: 'bold',
    },
    text: {
        fontSize: 18,
        fontWeight: '300',
        alignSelf: 'flex-start',
        marginLeft: 15,
        marginRight: 5,
        color: '#ffffff',
    },
    subHeader: {
        fontSize: 16,
        fontWeight: '700',
        alignSelf: 'flex-start',
        marginLeft: 15,
        marginRight: 5,
        color: '#ffffff',
    },
};

export default GetMissingAccountInfo;
