import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import {Divider, FormControlLabel, FormGroup, ListItem, Switch} from "@mui/material";
import List from "@mui/material/List";
import CON from "../../CON";
import ChangePromotionValue from "./ChangePromotionValue";

const PromotionConditionsInfo = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const getRewardTypeLabel = (status) => {
        switch (status) {
            case "FREE_ITEM":
                return "Free Item";
            case "BUY_ONE_GET_ONE":
                return "Buy One Get One";
            case "BUY_ONE_GET_X_OFF":
                return "BUY_ONE_GET_X_OFF";
            case "VARIABLE_DISCOUNT":
                return "Variable Discount";
            case "EXACT_DISCOUNT":
                return "Exact Discount";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "ACTIVE":
                return "Active";
            case "INACTIVE":
                return "Inactive";
            case "DELETED":
                return "Deleted";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography style={{fontSize: '0.75rem'}} variant={"subtitle1"}>Referral</Typography>
                            <FormGroup style={{display: "flex", flexDirection: "row"}}>
                                {/*<FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.isReferral === 1} />} label="isReferral" />*/}
                                <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.timeConstraint === 1} />} label="Time of Day" />
                                {/*<FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.minAmountConstraint === 1} />} label="Minimum Amount" />*/}
                                {/*<FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.requireCode === 1} />} label="Require Code" />*/}
                            </FormGroup>
                        </div>
                        <div className="d-inline-block align-middle m-2">

                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography style={{fontSize: '0.75rem'}} variant={"subtitle1"}>Day Constraints</Typography>
                            <FormGroup style={{display: "flex", flexDirection: "row"}}>
                                <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.onlyMonday === 1} />} label="Monday" />
                                <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.onlyTuesday === 1} />} label="Tuesday" />
                                <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.onlyWednesday === 1} />} label="Wednesday" />
                                <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.onlyThursday === 1} />} label="Thursday" />
                                <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.onlyFriday === 1} />} label="Friday" />
                                <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.onlySaturday === 1} />} label="Saturday" />
                                <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.onlySunday === 1} />} label="Sunday" />
                            </FormGroup>
                        </div>
                        <div className="d-inline-block align-middle m-2">

                        </div>
                    </div>
                    {/*<div className="d-inline-block" >*/}
                    {/*    <div className="d-inline-block align-middle m-1">*/}
                    {/*        <Typography variant="caption" display="block" gutterBottom>*/}
                    {/*            Code*/}
                    {/*        </Typography>*/}
                    {/*        <Typography variant="h5" component="h2">*/}
                    {/*            {props.promotion.code === "" ? 'N/A' : props.promotion.code}*/}
                    {/*        </Typography>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-inline-block align-middle m-2">*/}
                    {/*        /!*<ChangeProductValue type={'BRAND'} product={props.product} />*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="d-inline-block" >*/}
                    {/*    <div className="d-inline-block align-middle m-1">*/}
                    {/*        <Typography variant="caption" display="block" gutterBottom>*/}
                    {/*            Min Amount*/}
                    {/*        </Typography>*/}
                    {/*        <Typography variant="h5" component="h2">*/}
                    {/*            ${props.promotion.minAmount}*/}
                    {/*        </Typography>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-inline-block align-middle m-2">*/}
                    {/*        /!*<ChangeProductValue type={'BRAND'} product={props.product} />*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Start Time
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.startTime === null ? 'N/A' : props.promotion.startTime}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangePromotionValue type={'DESCRIPTION'} promotion={props.promotion} />*/}
                        </div>
                    </div>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                End Time
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.endTime === null ? 'N/A' : props.promotion.endTime}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangePromotionValue type={'DESCRIPTION'} promotion={props.promotion} />*/}
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>

                </Grid>
            </Grid>
        </div>
    );
}

export default PromotionConditionsInfo;
