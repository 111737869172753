import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Tooltip} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {logout} from "../../reducers/authenticationReducer";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import getText from "../../language/getText";
import {changeSiteTheme, setMobileOpen} from "../../reducers/preferenceReducer";

const ResponsiveAppBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const Theme = useSelector(state => state.preferenceManager);
    const loginStatus = useSelector(state => state.authManager.loginStatus);
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const mobileOpen = useSelector(state => state.preferenceManager.mobileOpen);
    const dispatch = useDispatch();

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const pages = [
        // {
        //     show: !loginStatus,
        //     label: "Login",
        //     link: "/login",
        // },
        {
            show: loginStatus,
            label: "Dashboard",
            link: "admin/dashboard",
        }
        ];

    const settings = [
        {
            title: 'Home',
            url: '/',
            type: 'LINK'
        },
        {
            title: 'Dashboard',
            url: 'admin/dashboard',
            type: 'LINK'
        },
        {
            title: 'Logout',
            url: '',
            type: 'LOGOUT'
        },
    ];

    const changeTheme = () => {
        dispatch(changeSiteTheme());
    }

    const handleOpenNavMenu = (event) => {
        // setAnchorElNav(event.currentTarget);
        dispatch(setMobileOpen({
            mobileOpen: mobileOpen
        }));
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        console.log(`Logging User Out`);
        handleCloseNavMenu()
        dispatch(logout());
        navigate("/");
    };

    const handleLinkActions = (type, url) => {
        switch (type) {
            case 'LINK':
                console.log(`Go to URL: ${url}`);
                navigate(url)
                break;
            case 'LOGOUT':
                handleLogout();
                break;
            default:
                break;
        }
    };

    return (
        <AppBar position="static" style={{flexGrow: 1, overflow: "hidden", position: "fixed", top: 0, width: "100%", zIndex: 1 }}>
            <Container maxWidth="xl" style={{zIndex: 2000}}>
                <Toolbar disableGutters>
                    <Button
                        variant="h6"
                        component="a"
                        onClick={() => navigate("/")}
                        sx={{
                            mr: 2,
                            fontSize: 25,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Control Center
                    </Button>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                        {location.pathname === '/dashboard' &&
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        }
                        {/*<Menu*/}
                        {/*    id="menu-appbar"*/}
                        {/*    anchorEl={anchorElNav}*/}
                        {/*    anchorOrigin={{ vertical: 'bottom', horizontal: 'left',}}*/}
                        {/*    keepMounted*/}
                        {/*    transformOrigin={{ vertical: 'top', horizontal: 'left',}}*/}
                        {/*    open={Boolean(anchorElNav)}*/}
                        {/*    onClose={handleCloseNavMenu}*/}
                        {/*    sx={{ display: { xs: 'block', md: 'none' }}}*/}
                        {/*>*/}
                        {/*    {pages.map((page, index) => {*/}
                        {/*        if (page.show){*/}
                        {/*            return (*/}
                        {/*                <MenuItem key={index} onClick={() => {handleCloseNavMenu(); navigate(page.link);}}>*/}
                        {/*                    <Typography textAlign="center">{page.label}</Typography>*/}
                        {/*                </MenuItem>*/}
                        {/*            );*/}
                        {/*        }*/}
                        {/*    })}*/}
                        {/*</Menu>*/}
                    </Box>
                    <Typography
                        variant="h5"
                        component="a"
                        onClick={() => navigate("/")}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        {pages.map((page, index) => {
                            if (page.show){
                                return (
                                    <Button
                                        key={index}
                                        onClick={() => {handleCloseNavMenu(); navigate(page.link);}}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page.label}
                                    </Button>
                                );
                            }
                        })}
                    </Box>

                    {loginStatus === true ?
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: "white", margin: "0px 20px" }}>
                                    <AccountCircleIcon fontSize={"large"} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting.title} onClick={() => {
                                        handleLinkActions(setting.type, setting.url);
                                    }}>
                                        <Typography textAlign="center" className="m-1">{setting.title}</Typography>
                                        {setting.type === 'LOGOUT' &&
                                            <LogoutIcon className="m-1" />
                                        }
                                    </MenuItem>
                                ))}
                                <Tooltip title="Swap Light/Dark Theme">
                                    <MenuItem key={"Swap Light/Dark Theme\""} onClick={() => {
                                        changeTheme();
                                    }}>
                                        {Theme.isDark === true ? <DarkModeIcon style={{marginRight: 10}} /> : <LightModeIcon style={{marginRight: 10}} />}
                                        {getText(languagePreference.lang, 'NB6')}
                                    </MenuItem>
                                </Tooltip>
                            </Menu>
                        </Box>
                        :
                        <Box style={{flexDirection: "row"}} sx={{borderRight: '2px solid white',display: { md: "flex"}}}>
                            <Button endIcon={<LoginIcon />} style={{color: 'white'}} onClick={() => {handleLinkActions('LINK', '/login')}}>{getText(languagePreference.lang, 'NB3')}</Button>
                        </Box>
                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
