import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import {Divider, FormControlLabel, FormGroup, ListItem, Switch} from "@mui/material";
import List from "@mui/material/List";
import CON from "../../CON";
import ChangePromotionValue from "./ChangePromotionValue";

const PromotionRewardInfo = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const categories = useSelector(state => state.categoryManager.categories);
    const products = useSelector(state => state.productManager.products);
    const getRewardTypeLabel = (status) => {
        switch (status) {
            case "FREE_ITEM":
                return "Free Item";
            case "BUY_ONE_GET_ONE":
                return "Buy One Get One";
            case "BUY_ONE_GET_X_OFF":
                return "BUY_ONE_GET_X_OFF";
            case "VARIABLE_DISCOUNT":
                return "Variable Discount";
            case "EXACT_DISCOUNT":
                return "Exact Discount";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "ACTIVE":
                return "Active";
            case "INACTIVE":
                return "Inactive";
            case "DELETED":
                return "Deleted";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <div className="d-inline-block align-middle m-1">
                                <Typography style={{fontSize: '0.75rem'}} variant={"subtitle1"}>Reward Type</Typography>
                                <FormGroup style={{display: "flex", flexDirection: "row"}}>
                                    <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.isCategory === 1} />} label="isCategory" />
                                    <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled checked={props.promotion.isProduct === 1} />} label="isProduct" />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Reward Type
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getRewardTypeLabel(props.promotion.rewardType)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangeProductValue type={'BRAND'} product={props.product} />*/}
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Discount
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.discount === null ? 'N/A' : props.promotion.discount}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangeProductValue type={'BRAND'} product={props.product} />*/}
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Category
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.categoryID === null ? 'N/A' : (categories.map((cat) => { if (cat.categoryID === props.promotion.categoryID) { return (cat.name) } } )) }
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangeProductValue type={'BRAND'} product={props.product} />*/}
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Product
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.productID === null ? 'N/A' : (products.map((product) => { if (product.productID === props.promotion.productID) { return (product.name) } } )) }
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">

                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Product Variation
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.productID === null ? 'N/A' : (products.map((product) => { if (product.productID === props.promotion.productID) { return product.stock.map((stock) => { if (stock.variation.stockVariationID === props.promotion.stockVariationID) { return `${stock.variation.value} ${stock.variation.variation.name}` } } ) } } )) }
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">

                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default PromotionRewardInfo;
