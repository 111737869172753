import axios from "axios";
import CON from "../CON";

class BrandService {
    create(data) {
        return axios
            .post(CON.IP + "/brand/create", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    getAll() {
        return axios
            .post(CON.IP + "/brand/getAll")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    searchByName(name) {
        return axios
            .post(CON.IP + "/brand/searchByName", { name })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeStatus(brandID, newValue) {
        return axios
            .post(CON.IP + "/brand/changeStatus", {brandID, newValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeName(brandID, newValue) {
        return axios
            .post(CON.IP + "/brand/changeName", {brandID, newValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeEmail(brandID, newValue) {
        return axios
            .post(CON.IP + "/brand/changeEmail", {brandID, newValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeLogo(data) {
        return axios
            .post(CON.IP + "/brand/changeLogo", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }
}

export default new BrandService();
