import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import ProductTable from "../Stock/ProductTable";

const BrandProductsInfo = (props) => {
    const dispatch = useDispatch();
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const products = useSelector(state => state.productManager.products);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const [searchResults, setSearchResults] = React.useState(null);
    const [type, setType] = React.useState("");


    React.useMemo(() => {
        let lst = [];
        let bLst = [];
        for (let i = 0; i < products.length; i++) {
            if (props.brand.brandID === products[i].brand.brandID){
                bLst.push(products[i]);
            }
            if (props.brand.brandID === products[i].brand.brandID){
                if (type.name === products[i].category.name){
                    lst.push(products[i]);
                }
            }
        }
        if (lst.length > 0){
            setSearchResults(lst);
        } else {
            setSearchResults(bLst);
        }
    }, [type]);

    return (
        <div style={{flexGrow: 1}}>
            <ProductTable searchResults={searchResults} type={type} setType={setType} brand={props.brand} />
        </div>
    );
}

export default BrandProductsInfo;
