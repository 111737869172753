const landingPage = {

};

const navBar = {
    "NB1" : "Home",
    "NB2" : "Dashboard",
    "NB3" : "Login",
    "NB4" : "Sign Up",
    "NB5" : "Language Preference",
    "NB6" : "Theme",
};

const signIn = {
    "SI1" : "Email Address",
    "SI2" : "Password",
    "SI3" : "Remember me",
    "SI4" : "Forgot Password",
    "SI5" : "Don't have an account? Sign Up",
    "SI6" : "Copyright",
    "SI7" : "Sign in",
    "SI8" : "Sign Up",
    "SI9" : "Password Reset",
    "SI10" : "Enter your email address you to receive a verification code.",
    "SI11" : "Request Reset Code",
    "SI12" : "Copyright",
};

const signUp = {
    "SU1" : "First Name",
    "SU2" : "Last Name",
    "SU3" : "Email Address",
    "SU4" : "Phone Number",
    "SU5" : "Password",
    "SU6" : "Password Repeat",
    "SU7" : "I want to receive marketing promotions and updates via email.",
    "SU8" : "Already have an account? Sign in",
    "SU9" : "Sign Up",
    "SU10" : "Middle Name"
};

const dashboard = {
    "DV1" : "Dashboard",
    "DV2" : "Catalog/Stock Management",
    "DV3" : "Account Management",
    "DV4" : "Location Management",
    "DV5" : "Product/Category Management",
    "DV6" : "Reports",
    "DV7" : "Settings",
    "DV8" : "New Accounts",
    "DV9" : "Orders",
    "DV10" : "New Replies",
    "DV11" : "Appointments",
    "DV12" : "Available Vaccines",
    "DV13" : "Completed & No Show Appointments",
    "DV14" : "Completed Orders",
    "DV15" : "Pending Appointments",
    "DV16" : "Brand Management",
    "DV17" : "Order Management",
    "DV18" : "Promotion Management",
};

const account = {
    "AC1" : "Your Account",
    "AC2" : "Save Changes",
    "AC3" : "Change Password",
    "AC4" : "Marketing",
    "AC5" : "Password and Authentication",
    "AC6" : "Click the button below to receive an email confirmation code.",
    "AC7" : "Confirmation Code: ",
    "AC8" : "Send Code",
    "AC9" : "Confirm Password Change",
    "AC10" : "Confirm",
    "AC11" : "Set New Password",
    "AC12" : "New password",
    "AC13" : "Confirm New Password",
    "AC14" : "Confirm Password Change",
    "AC15" : "Notification Settings",
    "AC16" : "I want to be reminded of my appointment."
}

const profile = {
    "PF1" : "Appointment History",
    "PF2" : "Active Appointments",
    "PF3" : "Email",
    "PF4" : "Phone Number",
    "PF5" : "Patient Profile",
    "PF6" : "Enter your email or phone number and then verify it to get a glimpse of your patient profile without having to create an account.",
    "PF7" : "Verify",
    "PF8" : "Click the button below to receive an SMS confirmation code."
}

const days = {
    "DAY1" : "Monday",
    "DAY2" : "Tuesday",
    "DAY3" : "Wednesday",
    "DAY4" : "Thursday",
    "DAY5" : "Friday",
    "DAY6" : "Saturday",
    "DAY7" : "Sunday",
};

const scheduledAppointment = {
    "SA1" : "Schedule Appointment",
    "SA2" : "No Data",
    "SA3" : "Vaccination Appointment",
    "SA4" : "Date",
    "SA5" : "View More",
    "SA6" : "Patient Information",
    "SA7" : "Vaccination Status",
    "SA8" : "Patient Information",
    "SA9" : "Date of Birth",
    "SA10" : "Address",
    "SA11" : "Phone Number",
    "SA12" : "Email",
    "SA13" : "Insurance Information",
    "SA14" : "Subscriber Name",
    "SA15" : "Subscriber ID",
    "SA16" : "Effective Date",
    "SA17" : "Plan Code",
    "SA18" : "CIN Number",
    "SA19" : "Provider Name",
    "SA20" : "Start Appointment",
    "SA21" : "End Observation",
};

const accountManagement = {
    "AM1" : "User",
    "AM2" : "Roles",
    "AM3" : "Filter",
    "AM4" : "User Account",
    "AM5" : "More",
    "AM6" : "Users from all locations",
    "AM7" : "Users from selected locations",
    "AM8" : "ID",
    "AM9" : "First Name",
    "AM10" : "Middle Name",
    "AM11" : "Last Name",
    "AM12" : "Email",
    "AM13" : "Status",
    "AM14" : "Last Updated",
    "AM15" : "Rows per page",
    "AM16" : "Search for location",
    "AM17" : "Location Groups",
    "AM18" : "Account Roles",
    "AM19" : "Provider Name",
    "AM20" : "Start Appointment",
    "AM21" : "None",
    "AM22" : "Location Groups",
    "AM23" : "Active",
    "AM24" : "Unknown",
    "AM25" : "Phone Number",
    "AM26" : "N/A",
    "AM27" : "Search for account",
    "AM28" : "Count",
    "AM29" : "Close",
    "AM30" : "Account View",
    "AM31" : "Users",
};

const storeManagement = {
    "SM1" : "Location Management",
    "SM2" : "Name",
    "SM3" : "Locations",
    "SM4" : "Search for locations",
    "SM5" : "Inactive",
    "SM6" : "Location View",
    "SM7" : "Geo-fence",
    "SM8" : "Zip Codes",
};


const brandManagement = {
    "BM1" : "Brand Management",
    "BM2" : "Brands",
    "BM3" : "Brand",
    "BM4" : "Brand View",
};

const courierManagement = {
    "COU1" : "Couriers",
    "COU2" : "Brands",
    "COU3" : "Brand",
    "COU4" : "Courier ID",
};

const stockManagement = {
    "STOCK1" : "Stock",
    "STOCK2" : "Stock ID",
    "STOCK3" : "Quantity",
    "STOCK4" : "Sold",
    "STOCK5" : "Available",
    "STOCK6" : "Defective",
    "STOCK7" : "Location",
    "STOCK8" : "Variation",
    "STOCK9" : "Price",
    "STOCK10" : "Variation",
    "STOCK11" : "Void",
};

const metaManagement = {
    "META1" : "Meta Management",
    "META2" : "Meta Tags",
    "META3" : "Meta ID",
    "META4" : "Name",
    "META5" : "Search for meta tags",
    "META6" : "Meta Tag View",
    "META7" : "Value",
    "META8" : "Meta Tag Presets",
    "META9" : "Icon",
    "META10" : "Meta Preset ID",
};

const productCategoryManagement = {
    "PCM1" : "Products",
    "PCM2" : "Categories",
    "PCM3" : "Product ID",
    "PCM4" : "Value",
    "PCM5" : "Brand ID",
    "PCM6" : "Search for products",
    "PCM7" : "Archived",
    "PCM8" : "Visible",
    "PCM9" : "Hidden",
    "PCM10" : "Category ID",
    "PCM11" : "Search for categories",
    "PCM12" : "Parent Name",
    "PCM13" : "Product View",
    "PCM14" : "Category View",
    "PCM15" : "Meta Tags",
    "PCM16" : "Variations",
    "PCM17" : "Product Name",
    "PCM18" : "Description",
    "PCM19" : "Location Name",
    "PCM20" : "Category",
    "PCM21" : "Deleted",
};

const orderManagement = {
    "OM1" : "Orders",
    "OM2" : "Search by accountID",
    "OM3" : "Pending",
    "OM4" : "EnRoute",
    "OM5" : "Canceled",
    "OM6" : "Completed",
    "OM7" : "Sub Total",
    "OM8" : "Total",
    "OM9" : "Discount",
    "OM10" : "Ordered BY",
    "OM11" : "Grand Total",
    "OM12" : "Items",
    "OM13" : "Order View",
};

const promotionManagement = {
    "PM1" : "Promotions",
    "PM2" : "ID",
    "PM3" : "Name",
    "PM4" : "Active",
    "PM5" : "Inactive",
    "PM6" : "Deleted",
    "PM7" : "Type",
    "PM8" : "Status",
    "PM9" : "Search by promotion name",
    "PM10" : "Duration",
    "PM11" : "Start Date",
    "PM12" : "End Date",
    "PM13" : "Reward Type",
    "PM14" : "Discount",
    "PM15" : "Category",
    "PM16" : "Product",
    "PM17" : "Quantity",
    "PM18" : "Minimum Amount",
    "PM19" : "Code",
    "PM20" : "Start Time",
    "PM21" : "End Time",
    "PM22" : "Promotion View",
};

const scheduleView = {
    "SV1": "Previous",
    "SV2": "Next",
    "SV3": "Find Vaccines and Boosters",
    "SV4": "Select Date and Time",
    "SV5": "Pre-screening Questions",
    "SV6": "Confirm Submission",
    "SV7": "Find Vaccine Location",
    "SV8": "Search for vaccination locations near you",
    "SV9": "Select Date & Time",
    "SV10": "Choose a timeslot for your appointment",
    "SV11": "Fill out the pre-screening question form and patient information",
    "SV12": "Review appointment details",
    "SV13": "General Information",
    "SV14": "Please enter your personal details"
};

const generalInfo = {
    "GI1": "First Name",
    "GI10": "Last Name",
    "GI2": "Date of Birth",
    "GI3": "Phone No",
    "GI4": "Email",
    "GI5": "Address",
    "GI6": "City",
    "GI7": "State",
    "GI8": "Zip Code",
    "GI9": "Please complete all fields",
}

const location = {
    "LO1": "Zip Code",
    "LO2": "Vaccines & Boosters",
    "LO3": "Search",
    "LO4": "Choose available date and time",
    "LO5": "Please Enter a Zip Code!",
    "LO6": "Must choose at least one vaccine!",
    "LO7": "Previous"
};

const datePick = {
    "DP1": "Please choose a valid date and time!"
}

const timeSlot = {
    "TS1": "Choose one of the available timeslots"
};

const confirmModal = {
    "CM1": "We've Confirmed your Appointment",
    "CM2": "An email or text message has been sent confirming your vaccination appointment. To cancel or change your appointment, please follow instructions",
    "CM3": "I did not receive an email",
    "CM4": "Submit",
    "CM5": "Error!",
    "CM6": "Could not book appointment.",
    "CM7": "Please refresh and try again.",
    "CM8": "Confirmation Code",
    "CM9": "Return to Homepage"
};

const reminder = {
    "RE1": "Reminder and Alert Preference",
    "RE2": "You've requested to change your reminder and alert settings for your appointment. Please change the following options to your preference.",
    "RE3": "I would like to be reminded about my appointment through:",
    "RE4": "Email Address",
    "RE5": "SMS/Text Message",
    "RE6": "Phone Call",
    "RE7": "I would like to be reminded about my appointment through:",
    "RE8": "Range",
    "RE9": "1 Day Prior",
    "RE10": "1 Week Prior",
    "RE11": "1 Month Prior",
    "RE12": "Cancel",
    "RE13": "Save",
};

const authorizationKey = {
    "AK1": "ID",
    "AK2": "Authorization Key",
    "AK3": "Status",
    "AK4": "Balance",
    "AK5": "Created By",
    "AK6": "Date Created",
    "AK7" : "Search for authorization key",
    "AK8" : "Active",
    "AK9" : "Void",
    "AK10" : "Revoked",
    "AK11" : "Generate Key",
    "AK12" : "Generating Key",
    "AK13" : "Generate Authorization Key",
    "AK14" : "Generating Authorization Key",
    "AK15": "Authorization Keys",
    "AK16": "Authorization Key View",
    "AK17": "Change Authorization Key Information",

};

const englishText = {
    ...productCategoryManagement,
    ...scheduledAppointment,
    ...promotionManagement,
    ...accountManagement,
    ...courierManagement,
    ...authorizationKey,
    ...orderManagement,
    ...stockManagement,
    ...storeManagement,
    ...brandManagement,
    ...metaManagement,
    ...scheduleView,
    ...generalInfo,
    ...confirmModal,
    ...landingPage,
    ...dashboard,
    ...location,
    ...datePick,
    ...timeSlot,
    ...reminder,
    ...navBar,
    ...signIn,
    ...signUp,
    ...account,
    ...profile,
    ...days,
};

export default englishText;
