import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';


const CustomPermissionList = (props) => {
    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = props.accountPermissions.indexOf(value);
        const newChecked = [...props.accountPermissions];

        let newLst = [];
        if (isChecked(value.permissionID)){
            newLst = props.accountPermissions.filter((item, index) => item.permissionID !== value.permissionID);
        } else {
            newLst = [...props.accountPermissions, value];
        }



        // if (currentIndex === -1) {
        //     newChecked.push(value);
        // } else {
        //     newChecked.splice(currentIndex, 1);
        // }

        props.setAccountPermissions(newLst);
    };

    const isChecked = (permissionID) => {
        for (const permission of props.accountPermissions) {
            if (permissionID === permission.permissionID){
                return true;
            }
        }

        return false;
    }

    return (
        <List
            sx={{ width: 410, bgcolor: 'background.paper', position: 'relative', overflow: 'auto',
                maxHeight: 220, '& ul': { padding: 0 } }}
            subheader={<li />}
        >
            {props.data.map((item, index) => (
                <ListItem key={`permission-${index}-${item.permissionID}`}>
                    <ListItemText id={`switch-list-label-${index}-${item.permissionID}`} primary={item.name} />
                    <Switch
                        edge="end"
                        onChange={handleToggle(item)}
                        checked={isChecked(item.permissionID)}
                        inputProps={{
                            'aria-labelledby': `switch-list-label-${index}-${item.permissionID}`,
                        }}
                    />
                </ListItem>
            ))}
        </List>
    );
}


export default CustomPermissionList;
