import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import BrandSelect from "../Brand/BrandSelect";
import CategorySelect from "../Stock/CategorySelect";
import {CardActions, CardMedia, FormControlLabel, FormGroup, Switch} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ProductSelect from "../Stock/ProductSelect";
import {DesktopDatePicker, DesktopDateTimePicker, TimePicker} from "@mui/x-date-pickers";
import Card from "@mui/material/Card";
import StoreSelect from "../Store/StoreSelect";
import AccountSelect from "../AccountManagement/AccountSelect";
//078909620
const GeneralPromotionInfoCreation = (props) =>  {

    const promotionTypes = [
        {
            name: 'Category',
            type: 'CATEGORY'
        },
        {
            name: 'Product',
            type: 'PRODUCT'
        }
    ];

    const RewardSelect = () => {
        const types = [
            {
                name: 'Free Item',
                type: 'FREE_ITEM'
            },
            {
                name: 'Buy One Get One',
                type: 'BUY_ONE_GET_ONE'
            },
            {
                name: 'Buy One Get One X Off',
                type: 'BUY_ONE_GET_ONE_X_OFF'
            },
            {
                name: 'Variable Discount',
                type: 'VARIABLE_DISCOUNT'
            },
            {
                name: 'Exact Discount',
                type: 'EXACT_DISCOUNT'
            }
        ];

        return (
            <FormControl fullWidth>
                <InputLabel id="rewardType-select-label" required>Reward Type</InputLabel>
                <Select
                    labelId="rewardType-select-label"
                    id="rewardType-select-label"
                    value={props.rewardType}
                    label="Reward Type"
                    onChange={(event) => {props.setRewardType(event.target.value);}}
                >
                    {types.map((type,index) => (
                        <MenuItem key={index} value={type}>
                            {type.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    const MembershipTypeSelect = () => {
        const types = [
            {
                name: 'Single',
                type: 'SINGLE'
            },
            {
                name: 'Couples',
                type: 'COUPLES'
            },
            {
                name: 'Single & Couples',
                type: 'SINGLE_COUPLES'
            }
        ];

        return (
            <FormControl fullWidth>
                <InputLabel id="membershipType-select-label" required>Membership Type</InputLabel>
                <Select
                    labelId="membershipType-select-label"
                    id="membershipType-select-label"
                    value={props.membershipType}
                    label="Membership Type"
                    onChange={(event) => {props.setMembershipType(event.target.value);}}
                >
                    {types.map((type,index) => (
                        <MenuItem key={index} value={type.type}>
                            {type.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    const referralDefault = 'By participating in our user referral program, you acknowledge and agree to these terms and conditions.\n' +
        'Referral codes are non-transferable and cannot be sold or traded.\n' +
        'We reserve the right to modify, suspend or terminate the user referral program at any time without notice.\n' +
        'By participating in the user referral program, you agree to all of the above terms and conditions.\n' +
        'Both the person referring and the referred user must be at least 21 years of age to participate in the user referral program.';

    const generalDefault = 'This promotion program is only available for users who have registered and created an account with our store.\n' +
        'The promotion is valid for a limited time only and may be terminated at any time without notice.\n' +
        'The promotion cannot be combined with any other offers or discounts.\n' +
        'The promotion is not transferable and cannot be redeemed for cash.\n' +
        'The promotion is subject to availability and the terms and conditions of our website.\n' +
        'The promotion is void where prohibited by law.\n' +
        'By participating in this promotion, users agree to abide by these terms and conditions.\n' +
        'To participate in this promotion, users must be 21 years of age or older and must comply with all applicable laws and regulations.\n';

    const uploadHandler = async (event) => {
        let file = event.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = async () => {
                props.setLogo(event.target.files[0])
                props.setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <Box noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item  xs={12} sm={6}>
                    <TextField
                        autoFocus
                        value={props.name}
                        onChange={event => props.setName(event.target.value)}
                        name="name"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                    />
                </Grid>
                <Grid style={{display: "flex", flexDirection: "row"}} item xs={12} sm={6}>
                    <div style={{marginRight: 10}}>
                        <DesktopDatePicker
                            label="Start Date"
                            value={props.startDate}
                            onChange={(date) => {
                                props.setStartDate(date);
                            }}
                            renderInput={(params) => <TextField required {...params} />}
                        />
                    </div>
                    <div style={{marginRight: 10}}>
                        <DesktopDatePicker
                            label="End Date"
                            value={props.endDate}
                            onChange={(date) => {
                                props.setEndDate(date);
                            }}
                            renderInput={(params) => <TextField required {...params} />}
                        />
                    </div>
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <TextField id="promotion-description-multiline" required value={props.description} onChange={event => props.setDescription(event.target.value)} label="Description" placeholder="Description" multiline rows={6} fullWidth />
                </Grid>

                <Grid item  xs={12} sm={6}>
                    <Button style={{margin: 10}} variant="outlined" component="label">
                        Upload background
                        <input hidden accept="image/*" multiple={false} onChange={uploadHandler} type="file" />
                    </Button>
                    {props.logoPreview !== null ? (
                        <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                            <CardMedia
                                component="img"
                                width="140"
                                image={props.logoPreview}
                                alt={props.logo.name}
                            />
                            <CardActions>
                                <Button variant={"outlined"} size={"small"} onClick={() => {
                                    props.setLogo(null);
                                    props.setLogoPreview(null);
                                }}>
                                    Remove
                                </Button>
                            </CardActions>
                        </Card>
                    ) : (
                        <p>Upload background</p>
                    )}
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <MembershipTypeSelect />
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <StoreSelect required={false} store={props.store} setStore={props.setStore}  />
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <AccountSelect account={props.account} setAccount={props.setAccount} multiple={true}  />
                </Grid>
                {/*<Grid item  xs={12} sm={6}>*/}
                {/*    <TextField id="promotion-termsAndCondition-multiline" required value={props.termsAndCondition} onChange={event => props.setTermsAndCondition(event.target.value)} label="Terms & Conditions" placeholder="Terms & Agreement" multiline rows={6} fullWidth />*/}
                {/*</Grid>*/}
                {/*<Grid item  xs={12} sm={12}>*/}
                {/*    <Button variant={"contained"} style={{margin: 10}} onClick={() => {*/}
                {/*        props.setTermsAndCondition(generalDefault);*/}
                {/*    }}>*/}
                {/*        Load Default Terms*/}
                {/*    </Button>*/}
                {/*    <Button variant={"contained"} style={{margin: 10}} onClick={() => {*/}
                {/*        props.setTermsAndCondition(referralDefault);*/}
                {/*    }}>*/}
                {/*        Load Default Referral Terms*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </Grid>
        </Box>
    );
}


export default GeneralPromotionInfoCreation;
