import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AccountManagement from "../../components/AccountManagement/AccountManagement";
import Dashboard from "../../components/Dashboard/Dashboard";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";
import getText from "../../language/getText";
import StoreManagement from "../../components/Store/StoreManagement";
import {useEffect} from "react";
import StockManagement from "../../components/Stock/StockManagement";
import BrandManagement from "../../components/Brand/BrandManagement";
import AccountService from "../../services/accountService";
import {setAccounts, setRoles} from "../../reducers/accountReducer";
import StoreService from "../../services/storeService";
import {setStores} from "../../reducers/storeReducer";
import ProductService from "../../services/productService";
import {setProducts, setVariations} from "../../reducers/productReducer";
import MetaService from "../../services/metaService";
import {setMetas} from "../../reducers/metaReducer";
import CategoryService from "../../services/categoryService";
import {setCategories} from "../../reducers/categoryReducer";
import BrandService from "../../services/brandService";
import {setBrands} from "../../reducers/brandReducer";
// import OrderManagement from "../../components/Order/OrderManagement";
import {setOrders} from "../../reducers/orderReducer";
import OrderService from "../../services/orderService";
import AuthService from "../../services/authService";
import {Drawer} from "@mui/material";
import {setMobileOpen} from "../../reducers/preferenceReducer";
import PromotionManagement from "../../components/Promotion/PromotionManagement";
import PromotionService from "../../services/promotionService";
import {setPromotions} from "../../reducers/promotionReducer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const DashboardView = (props) => {
    const dispatch = useDispatch();

    const variations = useSelector(state => state.productManager.variations);
    const categories = useSelector(state => state.categoryManager.categories);
    const promotions = useSelector(state => state.promotionManager.promotions);
    const accounts = useSelector(state => state.accountManager.accounts);
    const products = useSelector(state => state.productManager.products);
    const brands = useSelector(state => state.brandManager.brands);
    const stores = useSelector(state => state.storeManager.stores);
    const orders = useSelector(state => state.orderManager.orders);
    const roles = useSelector(state => state.accountManager.roles);
    const metas = useSelector(state => state.metaManager.metas);

    const loginStatus = useSelector(state => state.authManager.loginStatus);
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const mobileOpen = useSelector(state => state.preferenceManager.mobileOpen);

    const navigate = useNavigate();

    const [value, setValue] = React.useState(0);
    const [m, setM] = React.useState(false);
    useEffect(() => {
        let localDate = AuthService.getTokenData();

        if (!loginStatus) { navigate('/'); }

        if (localDate?.type === 'MASTER') {
            setM(true);
        }

        setTimeout(function() {
            if (accounts.length <= 0){
                console.log("**** Getting Accounts ****")
                AccountService.getAllAccounts().then((response) =>{
                    console.log(response)
                    if (response.status === true){
                        dispatch(setAccounts({
                            accounts: response.accounts
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (stores.length <= 0){
                StoreService.getAll().then((response) =>{
                    console.log(response)
                    if (response.status === true){
                        dispatch(setStores({
                            stores: response.results
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (brands.length <= 0){
                console.log("**** Getting Brands ****")
                BrandService.getAll().then((response) =>{
                    if (response.status === true){
                        dispatch(setBrands({
                            brands: response.results
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (roles.length <= 0){
                AccountService.getRoles().then((response) =>{
                    if (response.status === true){
                        dispatch(setRoles({
                            roles: response.results
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (variations.length <= 0){
                console.log("**** Getting Variations ****")
                ProductService.getAllVariation().then((response) =>{
                    if (response.status === true){
                        dispatch(setVariations({
                            variations: response.results
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (categories.length <= 0){
                console.log("**** Getting Categories ****")
                CategoryService.getAll().then((response) =>{
                    if (response.status === true){
                        dispatch(setCategories({
                            categories: response.results
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (metas.length <= 0){
                console.log("**** Getting Metas ****")
                MetaService.getAll().then((response) =>{
                    if (response.status === true){
                        dispatch(setMetas({
                            metas: response.results
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (promotions.length <= 0){
                console.log("**** Getting Promotions ****")
                PromotionService.getAll().then((response) =>{
                    console.log(response.results)
                    if (response.status === true){
                        dispatch(setPromotions({
                            promotions: response.results
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (products.length <= 0){
                console.log("**** Getting Products ****")
                ProductService.getAll().then((response) =>{
                    if (response.status === true){
                        dispatch(setProducts({
                            products: response.results
                        }));
                    } else {
                        // show error could not get data
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        }, 3000);

    }, []);

    const { window } = props;

    const handleDrawerToggle = () => {
        dispatch(setMobileOpen({
            mobileOpen: mobileOpen
        }));
    };
    const drawerWidth = 240;

    const drawer = (
        <div>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={(event, value) => { setValue(value) }}
                aria-label="dashboard tab"
            >
                <Tab label={getText(languagePreference.lang, 'DV1')} {...a11yProps(0)} />
                <Tab label={getText(languagePreference.lang, 'DV3')} {...a11yProps(1)} />
                <Tab label={getText(languagePreference.lang, 'DV4')} {...a11yProps(2)} />
                <Tab label={getText(languagePreference.lang, 'DV16')} {...a11yProps(3)} />
                <Tab label={getText(languagePreference.lang, 'DV2')} {...a11yProps(4)} />
                {/*<Tab label={getText(languagePreference.lang, 'DV17')} {...a11yProps(5)} />*/}
                <Tab label={getText(languagePreference.lang, 'DV18')} {...a11yProps(5)} />
            </Tabs>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    if (loginStatus){
        return (
            <Box sx={{ flexGrow: 1, display: 'flex', minHeight: 400 }}>
                {m === true &&
                    <>
                        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, marginTop: 70 }} aria-label="mailbox folders">
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }} sx={{display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, zIndex: '1' }}}>
                                {drawer}
                            </Drawer>
                            <Drawer variant="permanent" sx={{display: { xs: 'none', sm: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, zIndex: '1', marginTop: '70px', backgroundColor: 'rgba(40,40,40,0)' }}} open>
                                {drawer}
                            </Drawer>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Dashboard />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <AccountManagement />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <StoreManagement />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <BrandManagement />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <StockManagement />
                        </TabPanel>
                        {/*<TabPanel value={value} index={5}>*/}
                        {/*    <OrderManagement />*/}
                        {/*</TabPanel>*/}
                        <TabPanel value={value} index={5}>
                            <PromotionManagement />
                        </TabPanel>
                    </>
                }

                {/*{m === false &&*/}
                {/*    <>*/}
                {/*        <Tabs*/}
                {/*            orientation="vertical"*/}
                {/*            variant="scrollable"*/}
                {/*            value={value}*/}
                {/*            onChange={(event, value) => { setValue(value) }}*/}
                {/*            aria-label="dashboard tab"*/}
                {/*            sx={{ borderRight: 1, borderColor: 'divider', maxWidth: '200px' }}*/}
                {/*        >*/}
                {/*            <Tab label={getText(languagePreference.lang, 'DV17')} {...a11yProps(0)} />*/}
                {/*        </Tabs>*/}
                {/*        <TabPanel value={value} index={0}>*/}
                {/*            <OrderManagement />*/}
                {/*        </TabPanel>*/}
                {/*    </>*/}
                {/*}*/}
            </Box>
        );
    } else {
        return (
            <Navigate to={'/login'} />
        );
    }
}

export default DashboardView;
