import axios from "axios";
import CON from "../CON";

class PromotionService {
    create(data) {
        return axios
            .post(CON.IP + "/promotion/create", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    getAll() {
        return axios
            .post(CON.IP + "/promotion/getAll")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    grp() {
        return axios
            .post(CON.IP + "/promotion/u/grp")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    searchByName(name) {
        return axios
            .post(CON.IP + "/promotion/searchByName", { name })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeName(promotionID, newValue) {
        return axios
            .post(CON.IP + "/promotion/changeName", { promotionID, newValue })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changePromotionDescription(promotionID, newValue) {
        return axios
            .post(CON.IP + "/promotion/changePromotionDescription", { promotionID, newValue })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeStatus(promotionID, newValue) {
        return axios
            .post(CON.IP + "/promotion/changeStatus", { promotionID, newValue })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeBackground(data) {
        return axios
            .post(CON.IP + "/promotion/changeBackground", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    revokeAccountPromotion(data) {
        return axios
            .post(CON.IP + "/promotion/revokeAccountPromotion", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }
}

export default new PromotionService();
