import { createSlice } from '@reduxjs/toolkit'

export const brandReducer = createSlice({
    name: 'brandReducer',
    initialState: {
        brands: [],
    },
    reducers: {
        setBrands: (state, action) => {
            if (action.payload.brands){
                state.brands = action.payload.brands;
            }
        },
        addBrand: (state, action) => {
            if (action.payload.brand){
                state.brands = [
                    ...state.brands,
                    action.payload.brand
                ];
            }
        },
        updateBrand: (state, action) => {
            if (action.payload.brand){
                let newArray = state.brands;
                let objIndex = newArray.findIndex((obj => obj.brandID === action.payload.brand.brandID));
                newArray[objIndex] = action.payload.brand;
                state.brands = newArray;
            }
        },
    },
})

export const { setBrands, addBrand, updateBrand } = brandReducer.actions

export default brandReducer;
