import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";


const MetaSelect = (props) => {
    const metas = useSelector(state => state.metaManager.metas);

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="meta-select-label" required>Meta Tags</InputLabel>
                <Select
                    labelId="meta-select-label"
                    id="meta-select-label"
                    value={props.selectedMetaTag}
                    label="Meta Tags"
                    onChange={(event) => {props.setSelectedMetaTag(event.target.value);}}
                >
                    {metas.map((meta,index) => (
                        <MenuItem key={meta.metaID} value={meta}>
                            {meta.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default MetaSelect;
