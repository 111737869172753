import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import ChangeProductValue from "./ChangeProductValue";
import Card from "@mui/material/Card";
import {CardActions, CardMedia, Divider} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CON from "../../CON";
import ChangeProductVariationValue from "./ChangeProductVariationValue";

const ProductVariationGeneralInfo = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const getStatusLabel = (status) => {
        switch (status) {
            case "ARCHIVE":
                return getText(languagePreference.lang, 'PCM7');
            case "VISIBLE":
                return getText(languagePreference.lang, 'PCM8');
            case "HIDDEN":
                return getText(languagePreference.lang, 'PCM9');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const renderImagePreview = (asset, index) => {
        return (
            <Card key={index} style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                <CardMedia
                    component="img"
                    height="300"
                    image={CON.GET_IMAGE + asset.assetID}
                    alt={props.product.name}
                />
                <CardActions>
                    <Button variant={"outlined"} size={"medium"} onClick={() => {
                        removeImage(props.product.productID, asset.productAssetID)
                    }}>
                        Remove
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const removeImage = (productID, productAssetID) => {

    }

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Variation Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.productVariation.value}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductVariationValue type={'NAME'} productVariation={props.productVariation} product={props.product} />
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Description
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {((props.productVariation.description === null) || (props.productVariation.description === undefined) || (props.productVariation.description === '')) ? 'N/A' : props.productVariation.description}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductVariationValue type={'DESCRIPTION'} productVariation={props.productVariation} product={props.product} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default ProductVariationGeneralInfo;
