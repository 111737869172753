import React from 'react';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Alert} from "@mui/lab";
import MetaService from "../../services/metaService";
import {updateAccount} from "../../reducers/accountReducer";
import AccountService from "../../services/accountService";
import {useDispatch} from "react-redux";
import StoreSelect from "../Store/StoreSelect";


const ChangeAccountValue = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [newValue, setNewValue] = React.useState(null);
    const [newValueError, setNewValueError] = React.useState(false);
    const [newValueErrorMsg, setNewValueErrorMsg] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const handleSetNewValue = (event) => {
        setNewValue(event.target.value);
    };

    const clearInputs = () => {
        setNewValue(null);
    }

    const handleNewValueChange = () => {
        if (newValue !== null && newValue !== ''){
            switch (props.type) {
                case 'MEMBER_STATUS':
                    AccountService.changeMembershipStatus(props.account.accountID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        isMember: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'FIRST_NAME':
                    AccountService.changeFirstname(props.account.accountID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        firstname: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'LAST_NAME':
                    AccountService.changeLastname(props.account.accountID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        lastname: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'PHONE_NUMBER':
                    AccountService.changePhoneNumber(props.account.accountID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        phoneNumber: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'EMAIL':
                    AccountService.changeEmail(props.account.accountID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        firstname: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'STATUS':
                    AccountService.changeStatus(props.account.accountID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        isActive: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'LOCATION':
                    AccountService.updateAccountStore(props.account.accountID, newValue.storeID).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        store: {
                                            storeID: newValue.storeID,
                                            name: newValue.name
                                        },
                                        storeID: newValue.updateAccountStore
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'MEMBER_TYPE':
                    AccountService.changeMembershipType(props.account.accountID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        membershipType: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                default:
                    break;
            }
        } else {
            showAlertMsg('Must enter a value to proceed with change!', 'error');
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearInputs();
    };

    const getChangeLabel = () => {
        switch (props.type) {
            case 'FIRST_NAME':
                return (
                    `You are about to change the first name ${props.account.firstname}`
                );
            case 'LAST_NAME':
                return (
                    `You are about to change the last name ${props.account.lastname}`
                );
            case 'MIDDLE_NAME':
                return (
                    `You are about to change the middle name ${props.account.middleName}`
                );
            case 'EMAIL':
                return (
                    `You are about to change the email ${props.account.email}`
                );
            case 'USER_NAME':
                return (
                    `You are about to change the user name ${props.account.username}`
                );
            case 'PHONE_NUMBER':
                return (
                    `You are about to change the phone number ${props.account.phoneNumber}`
                );
            case 'MEMBER_STATUS':
                return (
                    `You are about to change the account membership status of accountID: ${props.account.accountID} | First Name: ${props.account.firstname} | Last Name: ${props.account.lastname}`
                );
            case 'MEMBER_TYPE':
                return (
                    `You are about to change the account membership type of accountID: ${props.account.accountID} | First Name: ${props.account.firstname} | Last Name: ${props.account.lastname}`
                );
            case 'STATUS':
                return (
                    `You are about to change the account status of accountID: ${props.account.accountID} | First Name: ${props.account.firstname} | Last Name: ${props.account.lastname}`
                );
            case 'LOCATION':
                return (
                    `You are about to change the account location of accountID: ${props.account.accountID} | Location ${props.account.store ? props.account.store.name : "--"}`
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const getChangeContent = () => {
        switch (props.type) {
            case 'FIRST_NAME':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New First Name"
                        fullWidth
                    />
                );
            case 'LAST_NAME':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New Last Name"
                        fullWidth
                    />
                );
            case 'MIDDLE_NAME':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New Middle Name"
                        fullWidth
                    />
                );
            case 'EMAIL':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New Email Name"
                        fullWidth
                    />
                );
            case 'USER_NAME':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New User Name"
                        fullWidth
                    />
                );
            case 'PHONE_NUMBER':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New Phone Number"
                        fullWidth
                    />
                );
            case 'STATUS':
                return (
                    <div style={{textAlign: "center"}}>
                        <Button style={{marginLeft: "10px"}} variant="contained"  color="primary"  onClick={() => setNewValue(1)} disabled={((newValue !== null ? newValue : props.account.isActive) === 1)} >
                            Active
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue(0)} disabled={((newValue !== null ? newValue : props.account.isActive) === 0)} >
                            Inactive
                        </Button>
                    </div>
                );
            case 'MEMBER_STATUS':
                return (
                    <div style={{textAlign: "center"}}>
                        <Button style={{marginLeft: "10px"}} variant="contained"  color="primary"  onClick={() => setNewValue(1)} disabled={((newValue ? newValue : props.account.isMember) === 1)} >
                            Active
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue(0)} disabled={((newValue ? newValue : props.account.isMember) === 0)} >
                            Inactive
                        </Button>
                    </div>
                );
            case 'MEMBER_TYPE':
                return (
                    <div style={{textAlign: "center"}}>
                        <Button style={{marginLeft: "10px"}} variant="contained"  color="primary"  onClick={() => setNewValue('SINGLE')} disabled={((newValue  ? newValue : props.account.membershipType) === 'SINGLE')} >
                            Single
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue('COUPLES')} disabled={((newValue  ? newValue : props.account.membershipType) === 'COUPLES')} >
                            Couples
                        </Button>
                    </div>
                );
            case 'LOCATION':
                return (
                    <div style={{textAlign: "center"}}>
                        <StoreSelect store={newValue} setStore={setNewValue}  />
                    </div>
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    React.useState(() => {

    }, [])

    return (
        <div>
            <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen} startIcon={<EditIcon />}>
                Change
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Account Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {showAlert && (
                            <Alert severity={alertSeverity}>{alertMsg}</Alert>
                        )}
                        {getChangeLabel()}
                    </DialogContentText>
                    {getChangeContent()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleNewValueChange} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ChangeAccountValue;
