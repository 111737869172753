import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import RewardService from "../../services/RewardService";
import {useDispatch, useSelector} from "react-redux"; // Assuming you're using react-router for navigation
import {setRewards} from '../../reducers/rewardReducer';
import {Typography} from "@mui/material";

const RedeemReward = (props) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tokenData = useSelector(state => state.authManager.tokenData);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getRewards = () => {
        RewardService.getRewards(tokenData.accountID).then((res) => {
            if (res.status === true) {
                alert("Reward redeemed successfully");
                handleClose();
                if (props.handleClose){
                    props.handleClose();
                }
                dispatch(setRewards({
                    rewards: res.results
                }));
            } else {
                dispatch(setRewards({
                    rewards: []
                }));
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const redeemReward = () => {
        RewardService.redeemReward(props.reward.promotionID, tokenData.accountID).then(res =>{
            console.log(res);
            if (res.status === true) {
                getRewards();
            }
        }).catch((err) => setImmediate(() => {
            // throw err
            console.log(err);
        }));
    }

    return (
        <div>
            <Button style={{backgroundColor: 'lightgreen', padding: 5, marginLeft: 20, marginRight: 20, borderRadius: 6}} variant="outlined" onClick={handleClickOpen}>
                <Typography style={{
                    textAlign: 'center',
                    fontSize: 20,
                    color: '#000'
                }}>
                    Redeem
                </Typography>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Do you want to redeem ${props.reward.name} reward?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={redeemReward} autoFocus>
                        Redeem
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RedeemReward;
