import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CategorySelect from "./CategorySelect";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import BrandSelect from "../Brand/BrandSelect";

const GeneralProductInfoCreation = (props) =>  {

    return (
        <Box noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item  xs={12} sm={6}>
                    <TextField
                        autoFocus
                        value={props.name}
                        onChange={event => props.setName(event.target.value)}
                        name="name"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                    />
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <BrandSelect required={true} disabled={false} brand={props.brand} setBrand={props.setBrand} />
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <CategorySelect category={props.category} setCategory={props.setCategory} isParent={true} />
                </Grid>
                <Grid item  xs={12} sm={12}>
                    <TextField
                        id="product-description-multiline"
                        value={props.description}
                        onChange={event => props.setDescription(event.target.value)}
                        label="Product Description"
                        placeholder="Product Description"
                        multiline
                        rows={4}
                        fullWidth
                    />
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <Button variant="outlined" component="label">
                        Upload Images
                        <input hidden accept="image/*" onChange={props.changeHandler} multiple={false} type="file" />
                    </Button>
                </Grid>
                <Grid item  xs={12} sm={12}>
                    {props.isFilePicked ? (
                        (props.selectedFiles.map((file, index) => {
                            return props.renderImagePreview(file, index, false);
                        }))
                    ) : (
                        <p>Upload product images</p>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}


export default GeneralProductInfoCreation;
