import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import ChangeAccountValue from "./ChangeAccountValue";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const AccountGeneralInfo = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getStatusLabel = (status) => {
        // console.log(props.account)
        switch (status) {
            case 1:
                return 'Active';
            case 0:
                return 'Inactive';
            default:
                return 'Unknown';
        }
    }

    const getMembershipTypeLabel = (status) => {
        switch (status) {
            case 'SINGLE':
                return 'Single';
            case 'COUPLES':
                return 'Couples';
            default:
                return 'Unknown';
        }
    }

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                First Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.account.firstname}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'FIRST_NAME'} account={props.account} />
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Last Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.account.lastname}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'LAST_NAME'} account={props.account} />
                        </div>
                    </div>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Middle Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.account.middleName !== null ? props.account.middleName : getText(languagePreference.lang,'AM26')}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'MIDDLE_NAME'} account={props.account} />
                        </div>
                    </div>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Email
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {(props.account.email !== null || props.account.email === '') ? props.account.email : getText(languagePreference.lang,'AM26')}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'EMAIL'} account={props.account} />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                User Name
                            </Typography>

                            <Typography variant="h5" component="h2">
                                {(props.account.username !== null || props.account.username === '') ? props.account.username : getText(languagePreference.lang,'AM26')}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'USER_NAME'} account={props.account} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Phone Number
                            </Typography>

                            <Typography variant="h5" component="h2">
                                {props.account.phoneNumber}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'PHONE_NUMBER'} account={props.account} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Membership Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.account.isMember)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'MEMBER_STATUS'} account={props.account} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Membership Type
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getMembershipTypeLabel(props.account.membershipType)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'MEMBER_TYPE'} account={props.account} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Location
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.account.store ? props.account.store.name : "--"}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'LOCATION'} account={props.account} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Account Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.account.isActive)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeAccountValue type={'STATUS'} account={props.account} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default AccountGeneralInfo;
