import axios from "axios";
import jwt_decode from "jwt-decode";
import CON from "../CON";


class AuthService {
    login(username, password) {
        return axios
            .post(CON.IP + "/auth/login", { username, password })
            .then((response) => {
                if (response.data) {
                    if (response.data.status === true){
                        localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
                        //AXIOS AUTHORIZATION HEADER
                        axios.defaults.headers.common["Authorization"] = `${response.data.accessToken}`;
                        return response.data.accessToken;
                    } else {
                        console.log('*** API Call Failed ***')
                        console.log(response.data.message);
                        return null;
                    }
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    userLogin(authorizationKey) {
        return axios
            .post(CON.IP + "/auth/u/login", { authorizationKey })
            .then((response) => {
                if (response.data) {
                    if (response.data.status === true){
                        localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
                        //AXIOS AUTHORIZATION HEADER
                        axios.defaults.headers.common["Authorization"] = `${response.data.accessToken}`;
                        return response.data.accessToken;
                    } else {
                        console.log('*** API Call Failed ***')
                        console.log(response.data.message);
                        return null;
                    }
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    getIPAddress() {
        return axios
            .post("https://geolocation-db.com/json/")
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('API failed: No data received!');
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***');
                console.log(err.toString());
                return null;
            });
    }

    logout() {
        localStorage.removeItem("accessToken");
    }

    getTokenData() {
        if (this.hasLocalToken()){
            return jwt_decode(this.getLocalToken());
        } else {
            return null;
        }
    }

    checkCode(codes){
        if (this.hasLocalToken()){
            for (let i = 0; i < codes.length; i++) {
                if (this.getTokenData().roles.some(el => el.code === codes[i])){
                    return true;
                }
            }
            return false;
        }
    }

    getLocalToken() {
        if (localStorage.getItem('accessToken') === '' || localStorage.getItem('accessToken') !== null){
            return JSON.parse(localStorage.getItem('accessToken'));
        } else {
            return null;
        }
    }

    hasLocalToken() {
        return localStorage.getItem('accessToken') === '' || localStorage.getItem('accessToken') !== null;
    }
}

export default new AuthService();
