import { createSlice } from '@reduxjs/toolkit'

export const accountReducer = createSlice({
    name: 'accountReducer',
    initialState: {
        accounts: [],
        roles: [],
        permissions: []
    },
    reducers: {
        setAccounts: (state, action) => {
            if (action.payload.accounts){
                state.accounts = action.payload.accounts;
            }
        },
        addAccount: (state, action) => {
            if (action.payload.account){
                state.accounts = [
                    ...state.accounts,
                    action.payload.account
                ];
            }
        },
        setRoles: (state, action) => {
            if (action.payload.roles){
                state.roles = action.payload.roles;
            }
        },
        addRole: (state, action) => {
            if (action.payload.role){
                state.roles = [
                    ...state.roles,
                    action.payload.role
                ];
            }
        },
        setPermissions: (state, action) => {
            if (action.payload.permissions){
                state.permissions = action.payload.permissions;
            }
        },
        addPermission: (state, action) => {
            if (action.payload.permission){
                state.permissions = [
                    ...state.permissions,
                    action.payload.permission
                ];
            }
        },
        updateAccount: (state, action) => {
            if (action.payload.account){
                let newArray = state.accounts;
                let objIndex = newArray.findIndex((obj => obj.accountID === action.payload.account.accountID));
                newArray[objIndex] = action.payload.account;
                state.accounts = newArray;
            }
        },
    },
})

export const { setAccounts, addAccount, setRoles, addRole, setPermissions, addPermission, updateAccount} = accountReducer.actions

export default accountReducer;
