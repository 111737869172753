import axios from "axios";
import CON from "../CON";

class ReportService {
    getReports() {
        return axios
            .post(CON.IP + "/report/getReports")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

}

export default new ReportService();
