import { createSlice } from '@reduxjs/toolkit'

export const productReducer = createSlice({
    name: 'productReducer',
    initialState: {
        products: [],
        variations: [],
    },
    reducers: {
        setProducts: (state, action) => {
            if (action.payload.products){
                state.products = action.payload.products;
            }
        },
        setVariations: (state, action) => {
            if (action.payload.variations){
                state.variations = action.payload.variations;
            }
        },
        addProduct: (state, action) => {
            if (action.payload.product){
                state.products = [
                    ...state.products,
                    action.payload.product
                ];
            }
        },
        updateProduct: (state, action) => {
            if (action.payload.product){
                let newArray = state.products;
                let objIndex = newArray.findIndex((obj => obj.productID === action.payload.product.productID));
                newArray[objIndex] = action.payload.product;
                state.products = newArray;
            }
        },
    },
})

export const { setProducts, addProduct, updateProduct, setVariations } = productReducer.actions

export default productReducer;
