import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import StoreSelect from "../Store/StoreSelect";
import VariationSelect from "./VariationSelect";
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {Input} from "@mui/icons-material";
import ProductionVariationSelect from "./ProductStockSelect";
import ProductStockSelect from "./ProductStockSelect";

const GeneralStockInfoCreation = (props) =>  {

    return (
        <Box noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                {/*<Grid item  xs={12} sm={6}>*/}
                {/*    <TextField*/}
                {/*        autoFocus*/}
                {/*        value={props.quantity}*/}
                {/*        onChange={event => props.setQuantity(event.target.value)}*/}
                {/*        name="quantity"*/}
                {/*        required*/}
                {/*        fullWidth*/}
                {/*        id="quantity"*/}
                {/*        type={"number"}*/}
                {/*        label="Quantity"*/}
                {/*    />*/}
                {/*</Grid>*/}
                <Grid item  xs={12} sm={6} style={{textAlign: "center"}}>
                    {props.selectedVariation !== '' ?
                        <div>
                            <div style={{display: "inline-block", marginLeft: 10, marginRight: 10, width: '46%'}}>
                                <VariationSelect selectedVariation={props.selectedVariation} setSelectedVariation={props.setSelectedVariation} />
                            </div>
                            <div style={{display: "inline-block",  marginLeft: 10, marginRight: 10, width: '46%'}}>
                                <TextField
                                    value={props.selectedVariationValue}
                                    onChange={event => props.setSelectedVariationValue(event.target.value)}
                                    name="variationValue"
                                    fullWidth
                                    required
                                    id="variationValue"
                                    type={"number"}
                                    label="Variation Value"
                                />
                            </div>
                        </div>
                        :
                        <div >
                            <VariationSelect selectedVariation={props.selectedVariation} setSelectedVariation={props.setSelectedVariation} />
                        </div>
                    }
                </Grid>
                {/*<Grid item  xs={12} sm={6}>*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <InputLabel htmlFor="stock-price">Price</InputLabel>*/}
                {/*        <OutlinedInput*/}
                {/*            id="stock-price"*/}
                {/*            value={props.price}*/}
                {/*            type={"number"}*/}
                {/*            onChange={event => props.setPrice(event.target.value)}*/}
                {/*            startAdornment={<InputAdornment position="start">$</InputAdornment>}*/}
                {/*            label="Amount"*/}
                {/*        />*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}
                <Grid item  xs={12} sm={6}>
                    <StoreSelect store={props.store} setStore={props.setStore}  />
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <ProductStockSelect product={props.product} selectedProductVariation={props.selectedProductVariation} setSelectedProductVariation={props.setSelectedProductVariation}  />
                </Grid>
            </Grid>
        </Box>
    );
}


export default GeneralStockInfoCreation;
