import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardActions, CardMedia, Divider} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CON from "../../CON";
import ChangeBrandValue from "./ChangeBrandValue";
import BrandService from "../../services/brandService";
import {updateBrand} from "../../reducers/brandReducer";

const BrandGeneralInfo = (props) => {
    const dispatch = useDispatch();
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [didReceivesResponse, setDidReceivesResponse] = React.useState(false);
    const [didSave, setDidSave] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case 0:
                return getText(languagePreference.lang, 'PCM9');
            case 1:
                return getText(languagePreference.lang, 'PCM8');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const removeImage = (productID, productAssetID) => {
        // ProductService.removeImage(productID, productAssetID).then(response =>{
        //     if (response){
        //         if (response.status === true){
        //             let newLst = props.product.images.filter((item, index) => item.productAssetID !== productAssetID);
        //             dispatch(updateProduct({
        //                 product: {
        //                     ...props.product,
        //                     images: newLst
        //                 }
        //             }));
        //             setDidReceivesResponse(true);
        //             setDidSave(true);
        //             showAlertMsg(response.message, 'success');
        //         } else {
        //             setDidReceivesResponse(true);
        //             setDidSave(false);
        //             showAlertMsg(response.message, 'error');
        //         }
        //     } else {
        //         showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
        //     }
        // }).catch(err => {
        //     console.log(err);
        // });
    }

    // const removeImage = (removeAt) => {
    //     let newLst = selectedFiles.filter((item, index) => index !== removeAt);
    //     if (newLst.length <= 0) { setIsFilePicked(false); }
    //     setSelectedFiles(newLst);
    // }

    const changeHandler = (event) => {
        let data = new FormData();
        data.append("brandID", props.brand.brandID);

        if (event.target.files.length > 0) {
            data.append(`file`, event.target.files[0]);
        }

        BrandService.changeLogo(data).then(response =>{
            if (response){
                if (response.status === true){
                    console.log(response)
                    dispatch(updateBrand({
                        brand: {
                            ...props.brand,
                            logo: response.result
                        }
                    }));
                    setDidReceivesResponse(true);
                    setDidSave(true);
                    showAlertMsg(response.message, 'success');
                } else {
                    setDidReceivesResponse(true);
                    setDidSave(false);
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch(err => {
            console.log(err);
        });
    };


    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.brand.name}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeBrandValue type={'NAME'} brand={props.brand} />
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Address
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {(props.brand.address !== null && props.brand.addressID !== null) ? props.brand.address : getText(languagePreference.lang,'AM26')}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangeProductValue type={'BRAND'} product={props.product} />*/}
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Email
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.brand.email !== null ? props.brand.email : getText(languagePreference.lang,'AM26')}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeBrandValue type={'EMAIL'} brand={props.brand} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.brand.status)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeBrandValue type={'STATUS'} brand={props.brand} />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <Divider />
                    <Card style={{display: "inline-block", margin: 10, marginTop: 15, textAlign: "center"}}>
                        <CardMedia
                            component="img"
                            width="300"
                            style={{maxHeight: 300}}
                            image={CON.GET_IMAGE + props.brand.logo}
                            alt={props.brand.name}
                        />
                        <Button variant="outlined" component="label" style={{marginTop: 10}}>
                            Change Brand Logo
                            <input hidden accept="image/*" onChange={changeHandler} multiple={false} type="file" />
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default BrandGeneralInfo;
