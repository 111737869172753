import * as React from 'react';
import ProductStockTable from "./ProductStockTable";

const ProductStock = (props) => {

    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState(null);

    return (
        <div style={{flexGrow: 1}}>
            <ProductStockTable product={props.product} searchResults={searchResults} />
        </div>
    );
}

export default ProductStock;
