import React from 'react';
import './App.css';
import {useDispatch, useSelector} from "react-redux";
import {createTheme, CssBaseline} from "@mui/material";
import AppRoutes from "./routers/routes";
import {ThemeProvider} from "@emotion/react";
import AuthenticationService from "./services/AuthorizationService";
import {login} from "./reducers/authenticationReducer";
import { LocalizationProvider } from '@mui/x-date-pickers';
// If you are using date-fns v2.x, please import `AdapterDateFns`
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

function App() {
  const theme = useSelector(state => state.appManager);
  const isDark = useSelector(state => state.preferenceManager.isDark);
  const dispatch = useDispatch();

  const darkTheme = createTheme({
    typography: {
      fontFamily: 'Segoe UI, Arial',
      h3: {
        fontSize: 20,
        fontWeight: 600
      },
      body1: {
        fontSize: 16,
        fontWeight: 300,
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffffff',
      },
      background: {
        default: '#282828',
      },
      text: {
        primary: '#fff',
      },
    },
  });

  const lightTheme = createTheme({
    typography: {
      fontFamily: 'Segoe UI, Arial',
      h3: {
        fontSize: 20,
        fontWeight: 600
      },
      body1: {
        fontSize: 16,
        fontWeight: 300,
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#f6062a',
      },
      text: {
        primary: '#404147',
      },
    },
  });

  React.useEffect(() => {
    console.log(`******* App Initialized *******`);
    console.log(`********  Hello There  ********`);
    console.log(`*******************************`);
    let localToken = AuthenticationService.getLocalToken();
    dispatch(login({
      accessToken: localToken
    }));
  });

  return (
      <ThemeProvider theme={isDark === true ? darkTheme : lightTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <AppRoutes />
        </LocalizationProvider>
      </ThemeProvider>
  );
}

export default App;
