import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";


const AccountSelect = (props) => {
    const accounts = useSelector(state => state.accountManager.accounts);

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="account-select-label" >Account</InputLabel>
                <Select
                    labelId="account-select-label"
                    id="account-select-label"
                    multiple={props.multiple ? props.multiple : false}
                    value={props.account}
                    label="Account"
                    onChange={(event) => props.setAccount(event.target.value)}
                >
                    {accounts.map((account,index) => (
                        <MenuItem key={accounts.accountID} value={account}>
                            {account.firstname + ' ' + account.lastname}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default AccountSelect;
