import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from "react";

const ProductStockSelect = (props) => {

    useEffect(() => {
        if (props.stock){
            console.log(props.stock)
        }
    }, []);

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="product-variation-select-label">Product Variation</InputLabel>
                <Select
                    labelId="product-variation-select-label"
                    id="product-variation-select-label"
                    value={props.productStock}
                    label="Product Variation"
                    onChange={(event) => {console.log(event.target.value); props.setProductStock(event.target.value)}}
                >
                    {props.product.stock.map((stk, index) => {
                        return (
                            <MenuItem key={index} value={stk}>
                                {((stk.productVariationName !== null) && (stk.productVariationName !== undefined)) && `${stk.productVariationName} | ` }{stk.variation.value} {stk.variation.variation.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}

export default ProductStockSelect;
