import * as React from 'react';
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import getText from "../../language/getText";
import {useDispatch, useSelector} from "react-redux";
import VerticalBarChart from "./VerticalBarChart";
import DashboardBasicCard from "./DashboardBasicCard";
import PieChart from "./PieChart";
import ReportService from "../../services/reportService";
import {setDashboardCards, setOrderReports, setUserReports} from "../../reducers/reportReducer";
import LineChart from "./LineChart";

const Dashboard = () => {
    const dispatch = useDispatch();
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const accounts = useSelector(state => state.accountManager.accounts);

    const orderReports = useSelector(state => state.reportManager.orderReports);
    const userReports = useSelector(state => state.reportManager.userReports);
    const dashboardCards = useSelector(state => state.reportManager.dashboardCards);

    const cards = [
        {
            title: "DV8",
            value: Math.floor(Math.random() * 20),
            change: "+" + Math.floor(Math.random() * 5),
            color: 'lightgreen'
        },
        {
            title: "DV9",
            value: Math.floor(Math.random() * 20),
            change: "-" + Math.floor(Math.random() * 5),
            color: 'red'
        }
    ];

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const [orderTypeReport, setOrderTypeReport] = React.useState(null);
    const [weeklySaleReport, setWeeklySaleReport] = React.useState(null);
    const [weeklyNewUsersReport, setWeeklyNewUsersReport] = React.useState(null);

    const formatDate = (dateStr) => {
      let date = new Date(dateStr);
      return `${date.getUTCMonth()}-${date.getDate()}-${date.getFullYear()}`
    }

    React.useEffect(() => {
        // if (orderReports == null && userReports == null){
            ReportService.getReports().then((response) =>{
                if (response.status === true){
                    if (response.result.orderReports !== null){
                        dispatch(setOrderReports({
                            orderReports: response.result.orderReports
                        }));
                    }
                    if (response.result.userReports !== null){
                        dispatch(setUserReports({
                            userReports: response.result.userReports
                        }));
                    }
                    if (response.result.dashboardCards !== null){
                        dispatch(setDashboardCards({
                            dashboardCards: response.result.dashboardCards
                        }));
                    }
                } else {
                    // show error could not get data
                }
            }).catch((err) => {
                console.log(err);
            });
        // }
    },[]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={{fontWeight: "bold"}}>{getText(languagePreference.lang, 'DV1')}</Typography>
            </Grid>
            <Grid item xs={12} style={{textAlign: "center"}}>
                {dashboardCards !== null &&
                    (dashboardCards.map((data,index) => {
                        return (
                            <DashboardBasicCard key={index} width={150} height={110} data={data} />
                        );
                    }))
                }
            </Grid>


            {/*{orderReports !== null &&*/}
            {/*    (orderReports.orderTypeReport !== null &&*/}
            {/*        <Grid item>*/}
            {/*            <Paper elevation={12} style={{width: 350, padding: 10}}>*/}
            {/*                <Typography variant={"subtitle1"} style={{textAlign: "center"}}>Orders by Category</Typography>*/}
            {/*                <PieChart data={orderReports.orderTypeReport.map((item) => item.count)} labels={orderReports.orderTypeReport.map((item) => item.name)} label={'Orders by Category'} />*/}
            {/*            </Paper>*/}
            {/*        </Grid>*/}
            {/*    )*/}
            {/*}*/}

            {/*{orderReports !== null &&*/}
            {/*    (orderReports.weeklySaleReport !== null &&*/}
            {/*        <Grid item>*/}
            {/*            <Paper elevation={12} style={{width: 500, padding: 10}}>*/}
            {/*                <Typography variant={"subtitle1"} style={{textAlign: "center"}}>Sales by Week</Typography>*/}
            {/*                <LineChart data={orderReports.weeklySaleReport.map((item) => item.total)} labels={orderReports.weeklySaleReport.map((item) => formatDate(item.Week))} label={'Total Sales'} />*/}
            {/*            </Paper>*/}
            {/*        </Grid>*/}
            {/*    )*/}
            {/*}*/}

            {userReports !== null &&
                (userReports.weeklyNewUsersReport !== null &&
                    <Grid item>
                        <Paper elevation={12} style={{width: 500, padding: 10}}>
                            <Typography variant={"subtitle1"} style={{textAlign: "center"}}>New Users by Week</Typography>
                            <VerticalBarChart data={userReports.weeklyNewUsersReport.map((item) => item.total)} labels={userReports.weeklyNewUsersReport.map((item) => formatDate(item.Week))} label={'Total New Users'} />
                        </Paper>
                    </Grid>
                )
            }
        </Grid>
    );
}


export default Dashboard;
