import { createSlice } from '@reduxjs/toolkit'

export const appReducer = createSlice({
    name: 'appReducer',
    initialState: {
        isDark: true,
        isDense: true,
        languagePreference: {
            lang: 'ENG',
            language: 'English'
        },
        drawerOpen: false,
        isConnected: false,
    },
    reducers: {
        toggleDrawerOpen: (state, action) => {
            state.drawerOpen = !state.drawerOpen;
        },
        changeSiteTheme: (state, action) => {
            state.isDark = !state.isDark;
        },
        setIsConnected: (state, action) => {
            if (action.payload.isConnected) {
                state.isConnected = action.payload.isConnected;
            }
        },
        changeTableViewMode: (state, action) => {
            console.log(`Table View Mode Changed: ${state.isDense}`);
            state.isDense = !state.isDense;
        },
    },
})

export const { changeSiteTheme, setIsConnected, changeTableViewMode, toggleDrawerOpen } = appReducer.actions

export default appReducer;
