import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";


const StoreSelect = (props) => {
    const stores = useSelector(state => state.storeManager.stores);

    const handleChange = (event) => {
        props.setStore(event.target.value);
    };

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="store-select-label" required={props.required !== undefined ? props.required : true}>Location</InputLabel>
                <Select
                    labelId="store-select-label"
                    id="store-select-label"
                    value={props.store}
                    label="Location"
                    onChange={handleChange}
                >
                    {stores.map((store,index) => (
                        <MenuItem key={store.storeID} value={store}>
                            {store.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default StoreSelect;
