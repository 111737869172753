import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {useSelector} from "react-redux";
import getText from "../../language/getText";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: getText(languagePreference.lang, 'DV13'),
            },
        },
    };

    const data = {
        labels: props.labels,
        datasets: [
            {
                label: props.label,
                data: props.data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(86,255,230,0.2)',
                    'rgba(207,86,255,0.2)',
                    'rgba(255,137,86,0.2)',
                    'rgba(142,255,86,0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgb(86,255,230)',
                    'rgb(207,86,255)',
                    'rgb(255,137,86)',
                    'rgb(142,255,86)',

                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Pie data={data} />
    );
}


export default PieChart;
