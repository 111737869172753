import * as React from 'react';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useSelector} from "react-redux";

const DashboardBasicCard = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [date, setDate] = React.useState(null);

    React.useEffect(() => {
        if (props.data.date !== null){
            let dateObj = new Date(props.data.date);
            setDate(`${dateObj.getDate()}/${dateObj.getMonth()+1}/${dateObj.getFullYear()}`);
        }
    },[]);

    return (
        <Box
            style={{verticalAlign: 'top'}}
            sx={{display: 'inline-block', flexWrap: 'wrap', '& > :not(style)': { width: props.width, height: props.height,},
                textAlign: 'center', marginRight: 5
            }}
        >
            <Paper elevation={12} style={{paddingTop: 5}}>
                <Typography style={{fontWeight: "bold", marginBottom: 5}}>
                    {props.data.title}
                </Typography>

                <Typography>
                    {props.data.value}
                </Typography>

                <Typography variant={"subtitle2"} style={{color: props.data.color}}>
                    {props.data.change}
                </Typography>

                {date !== null &&
                    <Typography variant={"caption"} fontSize={11}>
                        {date}
                    </Typography>
                }
            </Paper>
        </Box>
    );
}

export default DashboardBasicCard;
