import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright = (props) => {

    return (
        <Typography style={{ color: 'rgb(38 37 37)' }} variant="body2" color="text.secondary" align="center" {...props}>
            Copyright {' © '}
            <Link color="inherit" href="/">
                Rising Storm Brewing Company
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;
