import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {Alert} from "@mui/material";
import {useDispatch} from "react-redux";
import StateSelect from "../StateSelect";
import StoreService from "../../services/storeService";
import {addStore} from "../../reducers/storeReducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateStore = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('+1');
    const [country, setCountry] = React.useState({
        name: "United States",
        abbreviation: "US"
    });
    const [state, setState] = React.useState( 'NY');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setName('');
        setAddress('');
        setCity('');
        setZipCode('');
        setState('');
        setPhoneNumber('')
    }

    const handleAdd = () => {
        if (name === ''){
            showAlertMsg('Name field is required!', 'error');
            return;
        }

        if (address === ''){
            showAlertMsg('Address field is required!', 'error');
            return;
        }

        if (city === ''){
            showAlertMsg('City field is required!', 'error');
            return;
        }

        if (state === ''){
            showAlertMsg('State field is required!', 'error');
            return;
        }

        // Uncomment if ever adding support for multiple countries.
        // if (country === ""){
        //     alert("Country field is required!");
        //     showAlertMsg('Country field is required!', 'error');
        //     return;
        // }

        if (zipCode === ""){
            showAlertMsg('Zip code field is required!', 'error');
            return;
        }

        StoreService.create(name, address, city, state, country.abbreviation, zipCode, phoneNumber).then(response => {
            if (response.status === true){
                dispatch(addStore({
                    store: response.result
                }));
                showAlertMsg(response.message, 'success');
                clearFields();
            } else {
                showAlertMsg(response.message, 'error');
            }
        }).catch((err) => {
            console.log(err);
            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
        });
    };

    return (
        <div>
            <Button variant="outlined" style={{minWidth:175}} onClick={handleClickOpen}>
                Create Location
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='lg'
                aria-labelledby="create-new-store-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="create-new-store-dialog-title" onClose={handleClose}>
                    Creating New Location
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                    name="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={phoneNumber}
                                    onChange={event => setPhoneNumber(event.target.value)}
                                    name="phoneNumber"
                                    required
                                    fullWidth
                                    id="phoneNumber"
                                    label="Phone Number"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={12}>
                                <TextField
                                    value={address}
                                    onChange={(event => setAddress(event.target.value))}
                                    required
                                    fullWidth
                                    id="address"
                                    label="Address"
                                    name="address"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    value={city}
                                    onChange={event => setCity(event.target.value)}
                                    required
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <StateSelect state={state} setState={setState} />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    value={country.name}
                                    id="country"
                                    label="Country"
                                    name="country"
                                    disabled
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    value={zipCode}
                                    onChange={event => setZipCode(event.target.value)}
                                    required
                                    fullWidth
                                    id="zipCode"
                                    label="Zip Code"
                                    name="zipCode"
                                />
                            </Grid>
                        </Grid>
                        {/*<Grid container justifyContent="flex-end">*/}
                        {/*    <Grid item>*/}
                        {/*        <FormControlLabel*/}
                        {/*            control={<Checkbox value="allowExtraEmails" color="primary" />}*/}
                        {/*            label="I want to receive marketing promotions and updates via email."*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} autoFocus onClick={handleAdd}>
                        Create Location
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateStore;
