import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import {CardMedia, Divider, ListItem} from "@mui/material";
import List from "@mui/material/List";
import CON from "../../CON";
import ChangePromotionValue from "./ChangePromotionValue";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CategoryService from "../../services/categoryService";
import {updateCategory} from "../../reducers/categoryReducer";
import PromotionService from "../../services/promotionService";
import {updatePromotion} from "../../reducers/promotionReducer";

const PromotionGeneralInfo = (props) => {
    const dispatch = useDispatch();

    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [didReceivesResponse, setDidReceivesResponse] = React.useState(false);
    const [didSave, setDidSave] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "ACTIVE":
                return "Active";
            case "INACTIVE":
                return "Inactive";
            case "DELETED":
                return "Deleted";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    };

    const getMembershipTypeLabel = (status) => {
        switch (status) {
            case "SINGLE":
                return "Single";
            case "COUPLES":
                return "Couples";
            case "SINGLE_COUPLES":
                return "Single & Couples";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    };

    const changeHandler = (event) => {
        let data = new FormData();
        data.append("categoryID", props.category.categoryID);

        if (event.target.files.length > 0) {
            data.append(`file`, event.target.files[0]);
        }

        PromotionService.changeBackground(data).then(response =>{
            if (response){
                if (response.status === true){
                    console.log(response)
                    dispatch(updatePromotion({
                        promotion: {
                            ...props.promotion,
                            background: response.result
                        }
                    }));
                    setDidReceivesResponse(true);
                    setDidSave(true);
                    showAlertMsg(response.message, 'success');
                } else {
                    setDidReceivesResponse(true);
                    setDidSave(false);
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch(err => {
            console.log(err);
        });
    };


    const handlePromotionAccountRevoke = (promotionAccountID) => {
        let data = new FormData();
        data.append("promotionAccountID", promotionAccountID);

        PromotionService.revokeAccountPromotion(data).then(response =>{
            if (response){
                if (response.status === true){
                    console.log(response)
                    let newLst = [];
                    for (let i = 0; i < props.promotion.accounts.length; i++) {
                        let account = props.promotion.accounts[i];
                        if (account.promotionAccountID !== promotionAccountID) {
                            newLst.push(account);
                        }
                    }
                    dispatch(updatePromotion({
                        promotion: {
                            ...props.promotion,
                            accounts: newLst
                        }
                    }));
                    setDidReceivesResponse(true);
                    setDidSave(true);
                    showAlertMsg(response.message, 'success');
                } else {
                    setDidReceivesResponse(true);
                    setDidSave(false);
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                ID/#
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.promotionID}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.name}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangePromotionValue type={'NAME'} promotion={props.promotion} />
                        </div>
                    </div>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Duration
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.startDate.split("T")[0].split('-').join('/')} - {props.promotion.endDate ? props.promotion.endDate.split("T")[0].split('-').join('/') : 'N/A'}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangeProductValue type={'BRAND'} product={props.product} />*/}
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.promotion.status)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {((props.promotion.status !== 'DELETED')) &&
                                <ChangePromotionValue type={'STATUS'} promotion={props.promotion} />
                            }
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Membership Type
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getMembershipTypeLabel(props.promotion.membershipType)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*{((props.promotion.status !== 'DELETED')) &&*/}
                            {/*    <ChangePromotionValue type={'STATUS'} promotion={props.promotion} />*/}
                            {/*}*/}
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Store
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.storeID}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*{((props.promotion.status !== 'DELETED')) &&*/}
                            {/*    <ChangePromotionValue type={'STATUS'} promotion={props.promotion} />*/}
                            {/*}*/}
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Description
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.promotion.description}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangePromotionValue type={'DESCRIPTION'} promotion={props.promotion} />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Accounts
                            </Typography>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row',}} className="align-middle m-2">
                            {props.promotion?.accounts?.map((account, index) => {
                                return (
                                    <div key={index} style={{ backgroundColor: 'lightgray', margin: 10, padding: 10, borderRadius: 6, color: '#000' }}>
                                        <Typography>{account.firstname} {account.lastname}</Typography>
                                        <Button onClick={() => { handlePromotionAccountRevoke(account.promotionAccountID) }} variant={'contained'} size={'small'} color={'error'}>Revoke</Button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <Divider/>
                    <Card style={{display: "inline-block", margin: 10, marginTop: 15, textAlign: "center"}}>
                        <CardMedia
                            component="img"
                            width="300"
                            style={{maxHeight: 300}}
                            image={CON.GET_IMAGE + props.promotion.background}
                            alt={props.promotion.name}
                        />
                        <Button variant="outlined" component="label" style={{marginTop: 10}}>
                            Change Background
                            <input hidden accept="image/*" onChange={changeHandler} multiple={false} type="file" />
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default PromotionGeneralInfo;
