import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {Alert, CardActions, CardMedia} from "@mui/material";
import {useDispatch} from "react-redux";
import MetaService from "../../services/metaService";
import {updateMeta} from "../../reducers/metaReducer";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateMetaPreset = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');

    const [icon, setIcon] = React.useState(null);
    const [iconPreview, setIconPreview] = React.useState(null);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setIconPreview(null);
        setIcon(null);
        setValue('');
    }

    const uploadHandler = async (event) => {
        let file = event.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = async () => {
                setIcon(event.target.files[0])
                setIconPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAdd = () => {
        let data = new FormData();
        data.append("value", value);
        data.append("metaID", props.meta.metaID);
        data.append("icon", icon);

        if (value === ''){
            showAlertMsg('Name field is required!', 'error');
            return;
        }

        MetaService.createPreset(data).then(response => {
            if (response){
                if (response.status === true){
                    dispatch(updateMeta({
                        meta: {
                            ...props.meta,
                            presets: [
                                ...props.meta.presets,
                                response.result
                            ]
                        }
                    }));
                    showAlertMsg(response.message, 'success');
                    clearFields();
                } else {
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <div>
            <Button variant="outlined" style={{minWidth:200}} onClick={handleClickOpen}>
                Add Preset Value
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='sm'
                aria-labelledby="create-new-meta-tag-preset-value-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="create-new-meta-tag-preset-value-dialog-title" onClose={handleClose}>
                    Creating New Meta Tag Preset
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={value}
                                    onChange={event => setValue(event.target.value)}
                                    name="value"
                                    required
                                    fullWidth
                                    id="new-meta-preset-value"
                                    label="Value"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <Button variant="outlined" component="label">
                                    Upload Icon
                                    <input hidden accept="image/*" multiple={false} onChange={uploadHandler} type="file" />
                                </Button>
                                {iconPreview !== null ? (
                                    <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={iconPreview}
                                            alt={icon.name}
                                        />
                                        <CardActions>
                                            <Button variant={"outlined"} size={"small"} onClick={() => {
                                                setIcon(null);
                                                setIconPreview(null);
                                            }}>
                                                Remove
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ) : (
                                    <p>Upload meta icon svg</p>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} autoFocus onClick={handleAdd}>
                        Create Preset Value
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateMetaPreset;
