import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from "react";

const RewardSelect = (props) => {
    const types = [
        {
            id: 1,
            name: 'Free Item',
            type: 'FREE_ITEM'
        },
        {
            id: 2,
            name: 'Buy One Get One',
            type: 'BUY_ONE_GET_ONE'
        },
        {
            id: 3,
            name: 'Buy One Get One X Off (Variable)',
            type: 'BUY_ONE_GET_ONE_X_OFF_VARIABLE'
        },
        {
            id: 4,
            name: 'Buy One Get One X Off (Exact)',
            type: 'BUY_ONE_GET_ONE_X_OFF_EXACT'
        },
        {
            id: 5,
            name: 'Variable Discount',
            type: 'VARIABLE_DISCOUNT'
        },
        {
            id: 6,
            name: 'Exact Discount',
            type: 'EXACT_DISCOUNT'
        }
    ];

    useEffect(() => {

    }, []);

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="rewardType-select-label" required>Reward Type</InputLabel>
                <Select
                    labelId="rewardType-select-label"
                    id="rewardType-select-label"
                    value={props.rewardType}
                    defaultValue={null}
                    label="Reward Type"
                    onChange={(event) => { props.handleRewardType(event.target.value); }}
                >
                    {types.map((type,index) => (
                        <MenuItem key={type.id} value={type.type}>
                            {type.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default RewardSelect;
