import React from 'react';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Alert} from "@mui/lab";
import MetaService from "../../services/metaService";
import {updateMeta} from "../../reducers/metaReducer";
import {useDispatch} from "react-redux";


const ChangeMetaValue = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [newValue, setNewValue] = React.useState('');
    const [newValueError, setNewValueError] = React.useState(false);
    const [newValueErrorMsg, setNewValueErrorMsg] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const handleSetNewValue = (event) => {
        setNewValue(event.target.value);
    };

    const clearFields = () => {
        setNewValue('');
    }

    const handleNewValueChange = () => {
        if (newValue !== ''){
            switch (props.type) {
                case 'NAME':
                    MetaService.changeName(props.meta.metaID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateMeta({
                                    meta: {
                                        ...props.meta,
                                        name: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'DESCRIPTION':
                    MetaService.changeDescription(props.meta.metaID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateMeta({
                                    meta: {
                                        ...props.meta,
                                        description: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'STATUS':
                    MetaService.changeStatus(props.meta.metaID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateMeta({
                                    meta: {
                                        ...props.meta,
                                        status: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                default:
                    break;
            }
        } else {
            showAlertMsg('Must enter a value to proceed with change!', 'error');
        }
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearFields();
    };

    const getChangeLabel = () => {
        switch (props.type) {
            case 'NAME':
                return (
                    `You are about to change the name ${props.meta.name}`
                );
            case 'DESCRIPTION':
                return (
                    `You are about to change the description ${props.meta.description}`
                );
            case 'STATUS':
                return (
                    `You are about to change the meta tag status of metaID: ${props.meta.metaID} | Name: ${props.meta.name}`
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const getChangeContent = () => {
        switch (props.type) {
            case 'NAME':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New Name"
                        fullWidth
                    />
                );
            case 'DESCRIPTION':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        multiline
                        rows={4}
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New description"
                        fullWidth
                    />
                );
            case 'STATUS':
                return (
                    <div style={{textAlign: "center"}}>
                        <Button style={{marginLeft: "10px"}} variant="contained"  color="primary"  onClick={() => setNewValue('ARCHIVE')} disabled={props.meta.status === 'ARCHIVE' ? true : false} >
                            Archive
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue('VISIBLE')} disabled={props.meta.status === 'VISIBLE' ? true : false} >
                            Visible
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue('HIDDEN')} disabled={props.meta.status === 'HIDDEN' ? true : false} >
                            Hidden
                        </Button>
                    </div>
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    return (
        <div>
            <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen} startIcon={<EditIcon />}>
                Change
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Meta Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {showAlert && (
                            <Alert severity={alertSeverity}>{alertMsg}</Alert>
                        )}
                        {getChangeLabel()}
                    </DialogContentText>
                    {getChangeContent()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleNewValueChange} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ChangeMetaValue;
