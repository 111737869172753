import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import ChangeMetaValue from "./ChangeMetaValue";

const MetaGeneralInfo = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const getStatusLabel = (status) => {
        switch (status) {
            case "ARCHIVE":
                return getText(languagePreference.lang, 'PCM7');
            case "VISIBLE":
                return getText(languagePreference.lang, 'PCM8');
            case "HIDDEN":
                return getText(languagePreference.lang, 'PCM9');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.meta.name}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeMetaValue type={'NAME'} meta={props.meta} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.meta.status)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeMetaValue type={'STATUS'} meta={props.meta} />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Description
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.meta.description !== null ? props.meta.description : "None"}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeMetaValue type={'DESCRIPTION'} meta={props.meta} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default MetaGeneralInfo;
