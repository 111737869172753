import { createSlice } from '@reduxjs/toolkit'

export const promotionReducer = createSlice({
    name: 'promotionReducer',
    initialState: {
        promotions: [],
    },
    reducers: {
        setPromotions: (state, action) => {
            if (action.payload.promotions){
                state.promotions = action.payload.promotions;
            }
        },
        addPromotion: (state, action) => {
            if (action.payload.order){
                state.promotions = [
                    ...state.promotions,
                    action.payload.promotion
                ];
            }
        },
        updatePromotion: (state, action) => {
            if (action.payload.promotion){
                let newArray = state.promotions;
                let objIndex = newArray.findIndex((obj => obj.promotionID === action.payload.promotion.promotionID));
                newArray[objIndex] = action.payload.promotion;
                state.promotions = newArray;
            }
        },
    },
})

export const { setPromotions, addPromotion, updatePromotion } = promotionReducer.actions

export default promotionReducer;
