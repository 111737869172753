import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";
import StoreSelect from "../Store/StoreSelect";



const GeneralAccountInfoCreation = (props) =>  {


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField autoFocus value={props.firstname} onChange={event => props.setFirstname(event.target.value)}
                           autoComplete="given-name" name="firstname" required fullWidth id="firstname"
                           label="First Name"/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField value={props.middleName} onChange={event => props.setMiddleName(event.target.value)}
                           autoComplete="given-name" name="middleName" fullWidth id="middleName" label="Middle Name"/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField value={props.lastname} onChange={event => props.setLastname(event.target.value)}
                           autoComplete="given-name" name="lastname" required fullWidth id="lastname"
                           label="Last Name"/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField value={props.email} onChange={event => props.setEmail(event.target.value)}
                           autoComplete="given-name" name="email" required fullWidth id="email" label="Email"/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField value={props.phoneNumber} onChange={event => props.setPhoneNumber(event.target.value)}
                           autoComplete="given-name" name="phoneNumber" required fullWidth id="phoneNumber"
                           label="Phone Number"/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="body2" component="p">Membership Type</Typography>
                <div style={{textAlign: "center"}}>
                    <Button style={{marginLeft: "10px"}} variant="contained" color="primary"
                            onClick={() => props.setMembershipType('SINGLE')}
                            disabled={(props.membershipType === 'SINGLE')}>
                        Single
                    </Button>

                    <Button style={{marginLeft: "10px"}} variant="contained" color="primary"
                            onClick={() => props.setMembershipType('COUPLES')}
                            disabled={(props.membershipType === 'COUPLES')}>
                        Couples
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <StoreSelect store={props.store} setStore={props.setStore}  />
            </Grid>
</Grid>
)
    ;
}


export default GeneralAccountInfoCreation;
