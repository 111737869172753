import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import Box from "@mui/material/Box";
import MetaPresetTable from "./MetaPresetTable";

const MetaPresets = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    return (
        <Box sx={{ width: '100%' }}>
            <MetaPresetTable meta={props.meta} />
        </Box>
    );
}

export default MetaPresets;
