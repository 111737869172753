import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CategorySelect from "../Stock/CategorySelect";
import {FormControlLabel, FormGroup, Switch, Typography} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ProductSelect from "../Stock/ProductSelect";
import {DesktopDateTimePicker, DesktopTimePicker} from "@mui/x-date-pickers";

const ConditionsPromotionInfoCreation = (props) =>  {

    const RewardSelect = () => {
        const types = [
            {
                name: 'Free Item',
                type: 'FREE_ITEM'
            },
            {
                name: 'Buy One Get One',
                type: 'BUY_ONE_GET_ONE'
            },
            {
                name: 'Buy One Get One X Off',
                type: 'BUY_ONE_GET_ONE_X_OFF'
            },
            {
                name: 'Variable Discount',
                type: 'VARIABLE_DISCOUNT'
            },
            {
                name: 'Exact Discount',
                type: 'EXACT_DISCOUNT'
            }
        ];

        return (
            <FormControl fullWidth>
                <InputLabel id="rewardType-select-label" required>Reward Type</InputLabel>
                <Select
                    labelId="rewardType-select-label"
                    id="rewardType-select-label"
                    value={props.rewardType}
                    label="Reward Type"
                    onChange={(event) => { props.handleRewardType(event); }}
                >
                    {types.map((type,index) => (
                        <MenuItem key={index} value={type.type}>
                            {type.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    return (
        <Box noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item  xs={12} sm={12}>
                    <Typography variant={"subtitle1"}>Day Constraints</Typography>
                    <FormGroup style={{display: "flex", flexDirection: "row"}}>
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.mondayOnly} />} onChange={(event, checked) => { props.setMondayOnly(checked) }} label="Monday" />
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.tuesdayOnly} />} onChange={(event, checked) => { props.setTuesdayOnly(checked) }} label="Tuesday" />
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.wednesdayOnly} />} onChange={(event, checked) => { props.setWednesdayOnly(checked) }} label="Wednesday" />
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.thursdayOnly} />} onChange={(event, checked) => { props.setThursdayOnly(checked) }} label="Thursday" />
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.fridayOnly} />} onChange={(event, checked) => { props.setFridayOnly(checked) }} label="Friday" />
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.saturdayOnly} />} onChange={(event, checked) => { props.setSaturdayOnly(checked) }} label="Saturday" />
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.sundayOnly} />} onChange={(event, checked) => { props.setSundayOnly(checked) }} label="Sunday" />
                    </FormGroup>
                </Grid>
                <Grid item  xs={12} sm={12}>
                    <Typography variant={"subtitle1"}>Other Constraints</Typography>
                    <FormGroup style={{display: "flex", flexDirection: "row"}}>
                        {/*<FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.isReferral} />} onChange={(event, checked) => { props.setTimeConstraint(false); props.setRequireCode(false); props.setIsReferral(checked); }} label="Referral" />*/}
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.timeConstraint} />} onChange={(event, checked) => { props.setTimeConstraint(checked); props.setIsReferral(false); }} label="Time of Day" />
                        {/*<FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.minAmountConstraint} />} onChange={(event, checked) => { props.setMinAmountConstraint(checked); }} label="Minimum Amount" />*/}
                        {/*<FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.requireCode} />} onChange={(event, checked) => { props.setRequireCode(checked); props.setIsReferral(false); }} label="Require Code" />*/}
                    </FormGroup>
                </Grid>

                {/*<Grid item  xs={12} sm={6}>*/}
                {/*    <TextField id="minAmount" value={props.minAmount} disabled={!props.minAmountConstraint} onChange={event => props.setMinAmount(event.target.value)} name="minAmount" label="Minimum Cart Amount" placeholder="0" fullWidth />*/}
                {/*</Grid>*/}
                {/*<Grid item  xs={12} sm={6}>*/}
                {/*    <TextField id="code" disabled={!props.requireCode} value={props.code} onChange={event => props.setCode(event.target.value)} label="Code" placeholder="Code" fullWidth />*/}
                {/*</Grid>*/}
                <Grid style={{display: "flex", flexDirection: "row"}} item  xs={12} sm={6}>
                    <div style={{marginRight: 10}}>
                        <DesktopTimePicker
                            label="Start Time"
                            disabled={!props.timeConstraint}
                            value={props.startTime}
                            onChange={(date) => {
                                props.setStartTime(date);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                    <div style={{marginRight: 10}}>
                        <DesktopTimePicker
                            label="End Time"
                            disabled={!props.timeConstraint}
                            value={props.endTime}
                            onChange={(date) => {
                                props.setEndTime(date);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>

                </Grid>
            </Grid>
        </Box>
    );
}

export default ConditionsPromotionInfoCreation;
