import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {Alert, CardActions, CardMedia} from "@mui/material";
import {useDispatch} from "react-redux";
import CategorySelect from "./CategorySelect";
import CategoryService from "../../services/categoryService";
import {addCategory} from "../../reducers/categoryReducer";
import Card from "@mui/material/Card";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateCategory = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [category, setCategory] = React.useState('');

    const [description, setDescription] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const [logo, setLogo] = React.useState(null);
    const [logoPreview, setLogoPreview] = React.useState(null);

    const uploadHandler = async (event) => {
        let file = event.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = async () => {
                setLogo(event.target.files[0])
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setName('');
        setDescription('');
        setLogo(null);
        setLogoPreview(null);
    }

    const handleAdd = () => {
        let data = new FormData();
        if (name === ''){
            showAlertMsg('Name field is required!', 'error');
            return;
        }
        data.append("name", name);

        if (description === ''){
            showAlertMsg('description field is required!', 'error');
            return;
        }
        data.append("description", description);

        if (logo === null){
            showAlertMsg('Background is required!', 'error');
            return;
        }
        data.append("background", logo);



        CategoryService.create(data).then(response => {
            if (response){
                if (response.status === true){
                    dispatch(addCategory({
                        category: response.result
                    }));
                    showAlertMsg(response.message, 'success');
                    clearFields();
                } else {
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <div>
            <Button size={"small"} variant="outlined" style={{minWidth:175}} onClick={handleClickOpen}>
                Add Category
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='lg'
                aria-labelledby="create-new-category-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="create-new-category-dialog-title" onClose={handleClose}>
                    Creating New Category
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                    name="name"
                                    required
                                    fullWidth
                                    id="category-name"
                                    label="Name"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <CategorySelect category={category} setCategory={setCategory} isParent={true} />
                            </Grid>
                            <Grid item  xs={12} sm={12}>
                                <TextField
                                    id="category-description-multiline"
                                    value={description}
                                    onChange={event => setDescription(event.target.value)}
                                    label="Category Description"
                                    placeholder="Category Description"
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Grid>
                            {/*<Grid item  xs={12} sm={6}>*/}
                            {/*    <Button variant="outlined" component="label">*/}
                            {/*        Upload Icon*/}
                            {/*        <input hidden accept="image/*" multiple={false} type="file" />*/}
                            {/*    </Button>*/}
                            {/*</Grid>*/}
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <Button style={{margin: 10}} variant="outlined" component="label">
                                Upload background
                                <input hidden accept="image/*" multiple={false} onChange={uploadHandler} type="file" />
                            </Button>
                            {logoPreview !== null ? (
                                <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                                    <CardMedia
                                        component="img"
                                        width="140"
                                        image={logoPreview}
                                        alt={logo.name}
                                    />
                                    <CardActions>
                                        <Button variant={"outlined"} size={"small"} onClick={() => {
                                            setLogo(null);
                                            setLogoPreview(null);
                                        }}>
                                            Remove
                                        </Button>
                                    </CardActions>
                                </Card>
                            ) : (
                                <p>Upload background</p>
                            )}
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} autoFocus onClick={handleAdd}>
                        Create Category
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateCategory;
