import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";


const StoreCourierSelect = (props) => {
    const accounts = useSelector(state => state.accountManager.accounts);

    const getAccount = (account) => {
        if (account.type === 'MASTER' || account.type === 'EMPLOYEE'){
            return (
                <MenuItem key={account.accountID} value={account}>
                    {account.firstname + ' ' + account.lastname}
                </MenuItem>
            );
        }
    }

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="account-select-label" required>Account</InputLabel>
                <Select
                    labelId="account-select-label"
                    id="account-select-label"
                    value={props.account}
                    label="Account"
                    onChange={(event) => props.setAccount(event.target.value)}
                >
                    {accounts.map((account,index) => (
                        getAccount(account)
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default StoreCourierSelect;
