import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


const RoleInfoCard = (props) => {

    return (
        <Card style={{ width: 300, display: "inline-block", minHeight: 200, margin: 10}} >
            <CardContent>
                <Typography variant="h5" component="div">
                    {props.role.roleName}
                </Typography>
                <Typography variant="body2" style={{height: 100}}>
                    {props.role.description}
                </Typography>
            </CardContent>

            <CardActions>
                {props.justView === false &&
                    (props.selectedRole !== null ?
                            (props.selectedRole.roleID === props.role.roleID ?
                                    <Button variant={"contained"} size="medium" onClick={() => {
                                        props.setSelectedRole(null);
                                    }}>Deselect Role</Button>
                                    :
                                    <Button disabled variant={"contained"} size="medium">Select Role</Button>
                            )
                            :
                            <Button variant={"contained"} size="medium" onClick={() => {
                                props.setSelectedRole(props.role);
                            }}>Select Role</Button>
                    )
                }
            </CardActions>
        </Card>
    );
}

export default RoleInfoCard;
