import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import ChangeCategoryValue from "./ChangeCategoryValue";
import {CardMedia, Divider} from "@mui/material";
import Card from "@mui/material/Card";
import CON from "../../CON";
import Button from "@mui/material/Button";
import CategoryService from "../../services/categoryService";
import {updateCategory} from "../../reducers/categoryReducer";

const CategoryGeneralInfo = (props) => {
    const dispatch = useDispatch();

    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [didReceivesResponse, setDidReceivesResponse] = React.useState(false);
    const [didSave, setDidSave] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "ARCHIVE":
                return getText(languagePreference.lang, 'PCM7');
            case "VISIBLE":
                return getText(languagePreference.lang, 'PCM8');
            case "HIDDEN":
                return getText(languagePreference.lang, 'PCM9');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    };

    const changeHandler = (event) => {
        let data = new FormData();
        data.append("categoryID", props.category.categoryID);

        if (event.target.files.length > 0) {
            data.append(`file`, event.target.files[0]);
        }

        CategoryService.changeBackground(data).then(response =>{
            if (response){
                if (response.status === true){
                    console.log(response)
                    dispatch(updateCategory({
                        category: {
                            ...props.category,
                            background: response.result
                        }
                    }));
                    setDidReceivesResponse(true);
                    setDidSave(true);
                    showAlertMsg(response.message, 'success');
                } else {
                    setDidReceivesResponse(true);
                    setDidSave(false);
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.category.name}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeCategoryValue type={'NAME'} category={props.category} />
                        </div>
                    </div>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Parent Category
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.category.parentName !== null ? props.category.parentName : "None"}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangeAccountValue type={'MIDDLE_NAME'} account={props.account} />*/}
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.category.status)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeCategoryValue type={'STATUS'} category={props.category} />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <Divider />
                    <Card style={{display: "inline-block", margin: 10, marginTop: 15, textAlign: "center"}}>
                        <CardMedia
                            component="img"
                            width="300"
                            style={{maxHeight: 300}}
                            image={CON.GET_IMAGE + props.category.background}
                            alt={props.category.name}
                        />
                        <Button variant="outlined" component="label" style={{marginTop: 10}}>
                            Change Background
                            <input hidden accept="image/*" onChange={changeHandler} multiple={false} type="file" />
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default CategoryGeneralInfo;
