import axios from "axios";
import CON from "../CON";

class OrderService {
    getAll() {
        return axios
            .post(CON.IP + "/order/getAll")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    searchByName(name) {
        return axios
            .post(CON.IP + "/order/searchByName", { name })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeStatus(orderID, newValue) {
        return axios
            .post(CON.IP + "/order/changeStatus", { orderID, newValue })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    sendMessage(message, orderID) {
        return axios
            .post(CON.IP + "/order/u/sendMessage", {message, orderID})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }
}

export default new OrderService();
