import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import getText from "../../language/getText";
import {Alert, Button, CardActions, CardMedia} from "@mui/material";
import Card from "@mui/material/Card";
import CON from "../../CON";
import CardContent from "@mui/material/CardContent";
// const Dymo = require('dymojs'), dymo = new Dymo();

const ProductStockCreationReview = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const isDark = useSelector(state => state.preferenceManager.isDark);

    const renderAttributeCard = (tag, index) => {
        return (
            <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                {(tag.value.assetID !== undefined && tag.value.assetID !== null) &&
                    <CardMedia
                        component="img"
                        style={{width: 100, margin: '0 auto', filter: (isDark ?
                                    "invert(100%) sepia(96%) saturate(6727%) hue-rotate(181deg) brightness(130%) contrast(106%)"
                                    :
                                    "invert(0%) sepia(96%) saturate(0%) hue-rotate(262deg) brightness(98%) contrast(100%);"
                            )}}
                        image={CON.GET_IMAGE + tag.value.assetID}
                        alt={tag.value.value + "-icon"}
                    />
                }
                <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                        ID: {tag.meta.metaID} | Name: {tag.meta.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Value: {tag.value.value !== undefined ? tag.value.value : tag.value}
                    </Typography>
                </CardContent>
            </Card>
        );
    };

    let label = ``;

    const renderLabel = () => {
        // dymo.renderLabel(label).then(imageData => {
        //     console.log(imageData)
        //     // returns imageData as base64 encoded png.
        //     // use <img src="data:image/png;base64,${imageData}"/>
        //     // or require("fs").writeFile("out.png", imageData, 'base64', function(err) {...});
        // });
        // dymo.print('DYMO LabelWriter 550',  '');
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    General Information
                </Typography>

                <hr />

                <div className="d-inline-block m-1">
                    <div className="m-1">
                        {/*<div style={{margin: 15, display: "inline-block"}}>*/}
                        {/*    <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>*/}
                        {/*        {getText(languagePreference.lang, 'STOCK3')}:*/}
                        {/*    </Typography>*/}

                        {/*    <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>*/}
                        {/*        {props.quantity}*/}
                        {/*    </Typography>*/}
                        {/*</div>*/}

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'STOCK7')}:
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.store !== '' ? props.store.name : 'null'}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'STOCK8')}:
                            </Typography>
                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.selectedVariationValue !== '' &&
                                   `${props.selectedVariationValue} ${props.selectedVariation.unit}`
                                }
                            </Typography>
                        </div>

                        {/*<div style={{margin: 15, display: "inline-block"}}>*/}
                        {/*    <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>*/}
                        {/*        {getText(languagePreference.lang, 'STOCK9')}:*/}
                        {/*    </Typography>*/}
                        {/*    <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>*/}
                        {/*        ${props.price}*/}
                        {/*    </Typography>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </Grid>
            {/*<Grid item xs={12} >*/}
            {/*    <Typography variant="h5" display="block" gutterBottom>*/}
            {/*        Label Preview*/}
            {/*    </Typography>*/}

            {/*    <hr />*/}

            {/*    <div className="d-inline-block m-1">*/}
            {/*        <Button onClick={() => {*/}
            {/*            renderLabel();*/}
            {/*        }}>*/}
            {/*            Print*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} >*/}
            {/*    <Typography variant="h5" display="block" gutterBottom>*/}
            {/*        Product Attributes*/}
            {/*    </Typography>*/}

            {/*    <hr />*/}

            {/*    <div className="d-inline-block m-1">*/}
            {/*        {props.metaTags.length > 0 ? (*/}
            {/*            (props.metaTags.map((tag, index) => {*/}
            {/*                return renderAttributeCard(tag, index);*/}
            {/*            }))*/}
            {/*        ) : (*/}
            {/*            <p>Stock has no attributes</p>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</Grid>*/}
        </Grid>
    );
}


export default ProductStockCreationReview;
