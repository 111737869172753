import { createSlice } from '@reduxjs/toolkit'

export const preferenceReducer = createSlice({
    name: 'preferenceReducer',
    initialState: {
        isDark: true,
        isDense: true,
        languagePreference: {
            lang: 'ENG',
            language: 'English'
        },
        mobileOpen: false
    },
    reducers: {
        setMobileOpen: (state, action) => {
            state.mobileOpen = !state.mobileOpen;
        },
        changeSiteTheme: (state, action) => {
            state.isDark = !state.isDark;
        },
        changeTableViewMode: (state, action) => {
            console.log(`Table View Mode Changed: ${state.isDense}`);
            state.isDense = !state.isDense;
        },
        changeLanguage: (state, action) => {
            switch (action.payload.languagePreference) {
                case 'ENG':
                    state.languagePreference = {
                        lang: 'ENG',
                        language: 'English'
                    };
                    break;
                default:
                    state.languagePreference = {
                        lang: 'ENG',
                        language: 'English'
                    };
                    break;
            }
            console.log(`Language Changed --> ${state.languagePreference}`);
        },
    },
})

export const { changeSiteTheme, changeLanguage, changeTableViewMode, setMobileOpen } = preferenceReducer.actions

export default preferenceReducer;
