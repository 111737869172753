import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import {Alert, CardActions, CardMedia, CircularProgress} from "@mui/material";
import {useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {AlertTitle} from "@mui/lab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ProductService from "../../services/productService";
import {addProduct} from "../../reducers/productReducer";
import GeneralProductInfoCreation from "./GeneralProductInfoCreation";
import ProductCreationReview from "./ProductCreationReview";
import ProductAttributeInfoCreation from "./ProductAttributeInfoCreation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateProduct = (props) => {
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = React.useState(0);

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [brand, setBrand] = React.useState('');
    const [category, setCategory] = React.useState('');

    const [description, setDescription] = React.useState(null);

    const [metaTags, setMetaTags] = React.useState([]);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const [didReceivesResponse, setDidReceivesResponse] = React.useState(false);
    const [didSave, setDidSave] = React.useState(false);

    const [selectedFiles, setSelectedFiles] = React.useState([]);

    const [isFilePicked, setIsFilePicked] = React.useState(false);

    const handleAddAttribute = (meta, value, isPresetValue) => {
        if (value !== ''){
            setMetaTags([
                ...metaTags,
                { meta, value, isPresetValue }
            ]);
        } else {
            showAlertMsg('Must enter a value for the meta tag.', 'error');
        }
    }

    const changeHandler = async (event) => {
        if (event.target.files) {
            for (const file of event.target.files) {
                let reader = new FileReader();
                reader.onloadend = async () => {
                    setSelectedFiles([
                        ...selectedFiles,
                        {
                            imagePreview: reader.result,
                            file: file
                        }
                    ]);
                };
                reader.readAsDataURL(file);
            }
            setIsFilePicked(true);
        }
    };

    const removeImage = (removeAt) => {
        let newLst = selectedFiles.filter((item, index) => index !== removeAt);
        if (newLst.length <= 0) { setIsFilePicked(false); }
        setSelectedFiles(newLst);
    }

    const renderImagePreview = (data, index, justView) => {
        return (
            <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                <CardMedia
                    component="img"
                    image={data.imagePreview}
                    alt={data.file.name}
                />
                <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                        Filename: {data.file.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        File Type: {data.file.type}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Size in bytes: {data.file.size}
                    </Typography>
                </CardContent>
                {justView === false &&
                    <CardActions>
                        <Button variant={"outlined"} size={"small"} onClick={() => {removeImage(index)}}>
                            Remove
                        </Button>
                    </CardActions>
                }
            </Card>
        );
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setName('');
        // setDescription('');
        setBrand('');
        setCategory('');
        setSelectedFiles([]);
        setIsFilePicked(false);
    }

    const handleNext = () => {

        if (activeStep === 0){
            if (name === ''){
                showAlertMsg('Name field is required!', 'error');
                return;
            }

            if (description === ''){
                showAlertMsg('description field is required!', 'error');
                return;
            }

            if (category === ''){
                showAlertMsg('category field is required!', 'error');
                return;
            }

            if (brand === ''){
                showAlertMsg('store field is required!', 'error');
                return;
            }
            if (selectedFiles.length <= 0){
                showAlertMsg('must upload at least one product image!', 'error');
                return;
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (activeStep === 1){


            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (activeStep === 2){
            let data = new FormData();
            data.append("name", name);
            data.append("category", JSON.stringify(category));
            data.append("brand", JSON.stringify(brand));
            data.append("description", description);
            data.append("metaTags", JSON.stringify(metaTags));

            for (let i = 0; i < selectedFiles.length; i++) {
                data.append(`file-${i}`, selectedFiles[i].file);
            }

            ProductService.create(data).then(response => {
                if (response){
                    if (response.status === true){
                        dispatch(addProduct({
                            product: response.result
                        }));
                        setDidReceivesResponse(true);
                        setDidSave(true);
                        showAlertMsg(response.message, 'success');
                        // clearFields();
                    } else {
                        setDidReceivesResponse(true);
                        setDidSave(false);
                        showAlertMsg(response.message, 'error');
                    }
                } else {
                    showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                }
            }).catch((err) => {
                console.log(err);
            });

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        clearFields();
        setDidReceivesResponse(false);
        setDidSave(false);
        setActiveStep(0);
    };

    const EndView = () => {
        return (
            (didReceivesResponse ?
                    (didSave ?
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                <strong>Product Created</strong> — {alertMsg}
                            </Alert>
                            :
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                <strong>Product Not Created </strong> — Unfortunately something went wrong in the backend
                                refresh the browser and try again. If you still are experiencing problems contact the IT department.
                            </Alert>
                    )
                    :
                    <CircularProgress color="primary" />
            )
        )
    };

    const steps = [
        {
            label: 'General Information',
            content: <GeneralProductInfoCreation
                name={name} setName={setName}
                brand={brand} setBrand={setBrand}
                category={category} setCategory={setCategory}
                description={description} setDescription={setDescription}
                selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}
                isFilePicked={isFilePicked} setIsFilePicked={setIsFilePicked}
                changeHandler={changeHandler} renderImagePreview={renderImagePreview}
            />
        },
        {
            label: 'Product Attributes',
            content: <ProductAttributeInfoCreation
                metaTags={metaTags} setMetaTags={setMetaTags}
                handleAddAttribute={handleAddAttribute}
            />
        },
        {
            label: 'Review',
            content: <ProductCreationReview
                name={name}
                category={category} description={description}
                selectedFiles={selectedFiles} isFilePicked={isFilePicked}
                metaTags={metaTags} renderImagePreview={renderImagePreview}
            />
        },
        {
            label: 'Create',
            content: <EndView />
        }
    ];

    return (
        <div>
            <Button size={"small"} variant="outlined" style={{minWidth:175, marginLeft: 10}} onClick={handleClickOpen}>
                Create Product
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='xl'
                aria-labelledby="new-product-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="new-product-dialog-title" onClose={handleClose}>
                    Creating New Product
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3, mb: 3 }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((step, index) => {
                                const stepProps = {};
                                const labelProps = {};

                                return (
                                    <Step key={index} {...stepProps}>
                                        <StepLabel {...labelProps}>{step.label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                    {steps[activeStep].content}
                </DialogContent>
                <DialogActions>
                    {activeStep + 1 === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, mr: 2 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button variant={"contained"} onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button variant={"contained"} onClick={handleNext}>
                                    {activeStep + 1 === steps.length - 1 ? 'Create Product' : 'Next'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateProduct;
