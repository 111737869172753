import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import ChangeProductValue from "./ChangeProductValue";
import Card from "@mui/material/Card";
import {CardActions, CardMedia, Divider} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CON from "../../CON";
import ProductService from "../../services/productService";
import {addProduct, updateProduct} from "../../reducers/productReducer";

const ProductGeneralInfo = (props) => {
    const dispatch = useDispatch();
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [didReceivesResponse, setDidReceivesResponse] = React.useState(false);
    const [didSave, setDidSave] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "ARCHIVE":
                return getText(languagePreference.lang, 'PCM7');
            case "VISIBLE":
                return getText(languagePreference.lang, 'PCM8');
            case "HIDDEN":
                return getText(languagePreference.lang, 'PCM9');
            case "DELETED":
                return getText(languagePreference.lang, 'PCM21');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const removeImage = (productID, productAssetID) => {
        ProductService.removeImage(productID, productAssetID).then(response =>{
            if (response){
                if (response.status === true){
                    let newLst = props.product.images.filter((item, index) => item.productAssetID !== productAssetID);
                    dispatch(updateProduct({
                        product: {
                            ...props.product,
                            images: newLst
                        }
                    }));
                    setDidReceivesResponse(true);
                    setDidSave(true);
                    showAlertMsg(response.message, 'success');
                } else {
                    setDidReceivesResponse(true);
                    setDidSave(false);
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const renderImagePreview = (asset, index) => {
        return (
            <Card key={index} style={{display: "inline-block", margin: 10, textAlign: "center"}}>
                <CardMedia
                    component="img"
                    height="300"
                    image={CON.GET_IMAGE + asset.assetID}
                    alt={props.product.name}
                />
                <CardActions>
                    <Button variant={"outlined"} size={"medium"} onClick={() => {
                        removeImage(props.product.productID, asset.productAssetID)
                    }}>
                        Remove
                    </Button>
                </CardActions>
            </Card>
        );
    };

    //
    // const removeImage = (removeAt) => {
    //     let newLst = selectedFiles.filter((item, index) => index !== removeAt);
    //     if (newLst.length <= 0) { setIsFilePicked(false); }
    //     setSelectedFiles(newLst);
    // }
    const changeHandler = async (event) => {
        let data = new FormData();
        data.append("productID", props.product.productID);

        if (event.target.files.length > 0) {
            data.append(`file`, event.target.files[0]);
        }

        ProductService.addImage(data).then(response =>{
            if (response){
                if (response.status === true){
                    console.log(response)
                    dispatch(updateProduct({
                        product: {
                            ...props.product,
                            images: response.result
                        }
                    }));
                    setDidReceivesResponse(true);
                    setDidSave(true);
                    showAlertMsg(response.message, 'success');
                } else {
                    setDidReceivesResponse(true);
                    setDidSave(false);
                    showAlertMsg(response.message, 'error');
                }
            } else {
                showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
            }
        }).catch(err => {
            console.log(err);
        });
    };


    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.product.name}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductValue type={'NAME'} product={props.product}/>
                        </div>
                    </div>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Brand
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.product.brand.name}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductValue type={'BRAND'} product={props.product}/>
                        </div>
                    </div>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Category
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.product.category.name}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductValue type={'CATEGORY'} product={props.product}/>
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.product.status)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductValue type={'STATUS'} product={props.product}/>
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductValue type={'DELETE'} product={props.product}/>
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Featured
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.product.isFeatured}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductValue type={'IS_FEATURED'} product={props.product}/>
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductValue type={'DELETE'} product={props.product}/>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Description
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.product.description !== null ? props.product.description : "None"}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeProductValue type={'DESCRIPTION'} product={props.product}/>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div>
                        <Typography variant="h3" display="block" gutterBottom>
                        Images
                            <Button variant="outlined" component="label" style={{marginLeft: 10}}>
                                Add Product Image
                                <input hidden accept="image/*" onChange={changeHandler} multiple={false} type="file" />
                            </Button>
                        </Typography>

                    </div>
                    <Divider />
                    {props.product.images !== null &&
                        (props.product.images.length > 0 &&
                            (props.product.images.map((asset, index) => {
                                return renderImagePreview(asset, index);
                            }))
                        )
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default ProductGeneralInfo;
