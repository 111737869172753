import axios from "axios";
import CON from "../CON";

class CourierService {
    create(accountID, storeID) {
        return axios
            .post(CON.IP + "/courier/create", {accountID, storeID})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeStatus(courierID, newValue) {
        return axios
            .post(CON.IP + "/courier/changeStatus", {courierID, newValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }
}

export default new CourierService();
