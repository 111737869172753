import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MetaPresetSelect = (props) => {

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="meta-preset-value-select-label" required>Preset Values</InputLabel>
                <Select
                    labelId="meta-preset-value-select-label"
                    id="meta-preset-value-select-label"
                    value={props.value}
                    label="Preset Values"
                    onChange={(event) => {props.setValue(event.target.value);}}
                >
                    {props.meta.presets.map((preset,index) => (
                        <MenuItem key={preset.metaPresetID} value={preset}>
                            {preset.value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default MetaPresetSelect;
