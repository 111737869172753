import { createSlice } from '@reduxjs/toolkit'

export const categoryReducer = createSlice({
    name: 'categoryReducer',
    initialState: {
        categories: [],
    },
    reducers: {
        setCategories: (state, action) => {
            if (action.payload.categories){
                state.categories = action.payload.categories;
            }
        },
        addCategory: (state, action) => {
            if (action.payload.category){
                state.categories = [
                    ...state.categories,
                    action.payload.category
                ];
            }
        },
        updateCategory: (state, action) => {
            if (action.payload.category){
                let newArray = state.categories;
                let objIndex = newArray.findIndex((obj => obj.categoryID === action.payload.category.categoryID));
                newArray[objIndex] = action.payload.category;
                state.categories = newArray;
            }
        },
    },
})

export const { setCategories, addCategory, updateCategory } = categoryReducer.actions

export default categoryReducer;
