import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";

const ProductSelect = (props) => {
    const products = useSelector(state => state.productManager.products);

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="product-select-label" required={(props.required !== undefined) && (props.required != null) ? props.required : false} >Products</InputLabel>
                <Select
                    labelId="product-select-label"
                    id="product-select-label"
                    value={props.product}
                    label="Products"
                    disabled={(props.disabled !== undefined) && (props.disabled != null) ? !props.disabled : false}
                    onChange={(event) => {props.setProduct(event.target.value);}}
                >
                    {products.map((product,index) => (
                        <MenuItem key={product.productID} value={product}>
                            {product.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default ProductSelect;
