import englishText from "./englishText";

const getText = (lang, text) => {
    let value = ""
    switch (lang){
        case 'ENG':
            value = englishText[text];
            if (value) {
                return value;
            } else {
                return "Unknown text";
            }
        default:
            return 'Unknown language selected';
    }
}


export default getText;
