import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CategorySelect from "../Stock/CategorySelect";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ProductSelect from "../Stock/ProductSelect";
import ProductStockSelect from "../Stock/ProductVatiationSelect";
import RewardSelect from "./RewardSelect";
import BrandSelect from "../Brand/BrandSelect";

const RewardPromotionInfoCreation = (props) =>  {

    return (
        <Box noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item  xs={12} sm={12}>
                    <FormGroup style={{display: "flex", flexDirection: "row"}}>
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.isCategory} />} onChange={(event, checked) => { props.setIsCategory(checked); props.setIsProduct(false); props.setProduct(null); if (!checked) props.setCategory(null); }} label="Category" />
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.isProduct} />} onChange={(event, checked) => { props.setIsProduct(checked); props.setIsCategory(false); props.setIsBrand(false); props.setCategory(null); props.setBrand(null);if (!checked) props.setProduct(null); }} label="Product" />
                        <FormControlLabel style={{ margin: '0 auto' }} control={<Switch disabled={!props.isCategory} checked={props.isBrand} />} onChange={(event, checked) => { props.setIsBrand(checked); if (!checked) props.setBrand(null); }} label="Brand" />
                    </FormGroup>
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <RewardSelect rewardType={props.rewardType} handleRewardType={props.handleRewardType} />
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <TextField id="discount" disabled={(props.rewardType === 'FREE_ITEM') || (props.rewardType === 'BUY_ONE_GET_ONE') || (props.rewardType === 'BUY_ONE_GET_ONE_X_OFF')} value={props.discount} onChange={event => {
                        (event.target.value === "" ? props.setDiscount(null) : props.setDiscount(event.target.value))
                    }} name="discount"  label="Discount" placeholder="0" fullWidth />
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <TextField id="quantity" disabled={(props.rewardType !== 'FREE_ITEM')} value={props.quantity} onChange={event => {
                        (event.target.value === "" ? props.setQuantity(null) : props.setQuantity(event.target.value))
                    }} name="quantity" label="Quantity" placeholder="0" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CategorySelect category={props.category} setCategory={props.setCategory} isParent={true} disabled={props.isCategory} required={false} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ProductSelect product={props.product} setProduct={props.setProduct} isParent={false} disabled={props.isProduct} required={false} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <BrandSelect required={true} disabled={!props.isBrand} brand={props.brand} setBrand={props.setBrand} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {props.product != null && props.product.stock != null && <ProductStockSelect product={props.product} productStock={props.productStock} setProductStock={props.setProductStock} />}
                </Grid>
            </Grid>
        </Box>
    );
}

export default RewardPromotionInfoCreation;
