import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {Alert, CardActions, CardMedia} from "@mui/material";
import {useDispatch} from "react-redux";
import StateSelect from "../StateSelect";
import BrandService from "../../services/brandService";
import Card from "@mui/material/Card";
import {addBrand} from "../../reducers/brandReducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateBrand = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('Rising Storm Brewing');
    const [address, setAddress] = React.useState('5750 S Lima Rd');
    const [city, setCity] = React.useState('Avon');
    const [zipCode, setZipCode] = React.useState('14414');
    const [phoneNumber, setPhoneNumber] = React.useState('+15855723009');
    const [email, setEmail] = React.useState('contactus@risingstormbrewing.com');
    const [website, setWebsite] = React.useState('https://www.risingstormbrewing.com/');
    const [country, setCountry] = React.useState({
        name: "United States",
        abbreviation: "US"
    });
    const [state, setState] = React.useState( '');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const [logo, setLogo] = React.useState(null);
    const [logoPreview, setLogoPreview] = React.useState(null);

    const uploadHandler = async (event) => {
        let file = event.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = async () => {
                setLogo(event.target.files[0])
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setName('');
        setAddress('');
        setCity('');
        setZipCode('');
        setState('');
        setPhoneNumber('');
        setEmail('');
        setWebsite('')
        setLogo(null);
        setLogoPreview(null);
    }

    const handleAdd = () => {
        if (name === ''){
            showAlertMsg('Name field is required!', 'error');
            return;
        }

        if (logo === null){
            showAlertMsg('Logo image is required!', 'error');
            return;
        }

        let data = new FormData();
        data.append("name", name);
        data.append("address", address);
        data.append("city", city);
        data.append("state", state);
        data.append("country", country.abbreviation);
        data.append("zipCode", zipCode);
        data.append("phoneNumber", phoneNumber);
        data.append("email", email);
        data.append("logo", logo);

        BrandService.create(data).then(response => {
            if (response.status === true){
                dispatch(addBrand({
                    brand: response.result
                }));
                showAlertMsg(response.message, 'success');
                clearFields();
            } else {
                showAlertMsg(response.message, 'error');
            }
        }).catch((err) => {
            console.log(err);
            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
        });
    };

    return (
        <div>
            <Button variant="outlined" style={{minWidth:175}} onClick={handleClickOpen}>
                Create Brand
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='lg'
                aria-labelledby="create-new-brand-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="create-new-brand-dialog-title" onClose={handleClose}>
                    Creating New Brand
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                    name="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={website}
                                    onChange={event => setWebsite(event.target.value)}
                                    name="website"
                                    fullWidth
                                    id="website"
                                    label="Website"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    name="email"
                                    fullWidth
                                    id="email"
                                    label="Email"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={phoneNumber}
                                    onChange={event => setPhoneNumber(event.target.value)}
                                    name="phoneNumber"
                                    fullWidth
                                    id="phoneNumber"
                                    label="Phone Number"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={12}>
                                <TextField
                                    value={address}
                                    onChange={(event => setAddress(event.target.value))}
                                    fullWidth
                                    id="address"
                                    label="Address"
                                    name="address"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    value={city}
                                    onChange={event => setCity(event.target.value)}
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <StateSelect state={state} setState={setState} />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    value={country.name}
                                    id="country"
                                    label="Country"
                                    name="country"
                                    disabled
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    value={zipCode}
                                    onChange={event => setZipCode(event.target.value)}
                                    fullWidth
                                    id="zipCode"
                                    label="Zip Code"
                                    name="zipCode"
                                />
                            </Grid>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <Button style={{margin: 10}} variant="outlined" component="label">
                                Upload Logo
                                <input hidden accept="image/*" multiple={false} onChange={uploadHandler} type="file" />
                            </Button>
                            {logoPreview !== null ? (
                                <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                                    <CardMedia
                                        component="img"
                                        width="140"
                                        image={logoPreview}
                                        alt={logo.name}
                                    />
                                    <CardActions>
                                        <Button variant={"outlined"} size={"small"} onClick={() => {
                                            setLogo(null);
                                            setLogoPreview(null);
                                        }}>
                                            Remove
                                        </Button>
                                    </CardActions>
                                </Card>
                            ) : (
                                <p>Upload Brand Logo</p>
                            )}
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} autoFocus onClick={handleAdd}>
                        Create Brand
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateBrand;
