import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from "../../components/Copyright/Copyright";
import {Navigate, useNavigate} from "react-router-dom";
import AuthService from "../../services/authService";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../reducers/authenticationReducer";
import {Alert, Link} from '@mui/material'
export default function SignIn() {
    const navigate = useNavigate();
    const loginStatus = useSelector(state => state.authManager.loginStatus);

    const dispatch = useDispatch();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMSg] = React.useState("");

    const handleError = (msg, state) => {
        switch (state) {
            case true:
                setError(true);
                setErrorMSg(msg);
                break;
            case false:
                setError(false);
                setErrorMSg("");
                break;
            default:
                setError(false);
                setErrorMSg("");
                break;
        }
    }

    const handleSubmit = () => {
        if (username.length > 0 && password.length > 0){
            handleError("", false);

            AuthService.login(username, password).then(accessToken => {
                if (accessToken){
                    dispatch(login({
                        accessToken
                    }));
                } else {
                    handleError("Username or password is incorrect", true);
                }
            });
        }

        handleError("Must enter authorization key or username & password.", true);
    };

    if (loginStatus){
        return (
            <Navigate to={'/admin/dashboard'} />
        );
    } else {
        return (
            <Container component="main" maxWidth="xs">
                {error === true &&
                    <Alert severity="error" style={{margin: '0 auto', width: '90%'}}>{errorMsg}</Alert>
                }
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                        <TextField margin="normal" value={username} required fullWidth id="username" label="Username" name="username" autoComplete="username" onChange={(event) => {
                            setUsername(event.target.value)
                        }} />
                        <TextField margin="normal" value={password} required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" onChange={(event) => {
                            setPassword(event.target.value)
                        }} />
                        <Button type="submit" onClick={() => {
                            handleSubmit()
                        }} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link style={{ color: '#a9a9a9' }} onClick={() => { navigate('/admin/reset') }} variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        );
    }
}
