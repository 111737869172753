import { createSlice } from '@reduxjs/toolkit'

export const orderReducer = createSlice({
    name: 'orderReducer',
    initialState: {
        orders: [],
        alertLst: []
    },
    reducers: {
        setOrders: (state, action) => {
            if (action.payload.orders){
                state.orders = action.payload.orders;
            }
        },
        setAlertLst: (state, action) => {
            if (action.payload.alertLst){
                state.alertLst = action.payload.alertLst;
            }
        },
        addOrder: (state, action) => {
            if (action.payload.order){
                state.orders = [
                    ...state.orders,
                    action.payload.order
                ];
            }
        },
        updateOrder: (state, action) => {
            if (action.payload.order){
                let newArray = state.orders;
                let objIndex = newArray.findIndex((obj => obj.orderID === action.payload.order.orderID));
                newArray[objIndex] = action.payload.order;
                state.orders = newArray;
            }
        },
    },
})

export const { setOrders, addOrder, updateOrder, setAlertLst } = orderReducer.actions

export default orderReducer;
