import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Drawer, IconButton, Typography} from "@mui/material";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import background from "../../assets/background.jpg";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import {Navigate, useNavigate} from "react-router-dom";
import {clearToken} from "../../reducers/authenticationReducer";
import DeleteAccount from "../../components/DeleteAccount";

const SettingsView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [value, setValue] = React.useState(0);
    const ref = React.useRef(null);

    const tokenData = useSelector(state => state.authManager.tokenData);

    React.useEffect(() => {

    }, []);

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box style={{ width: 250, backgroundColor: '#9c9a9a', height: '100vh' }} role="presentation" onClick={toggleDrawer(false)}>
            <div style={{display: 'flex', marginLeft: 20, marginTop: 20}}>
                <AccountCircleIcon fontSize={'large'} />
                <Typography style={{fontSize: 25, marginLeft: 10}}>{tokenData !== null ? tokenData.firstname + ' ' + tokenData.lastname : '---'}</Typography>
            </div>
            <List>
                <ListItem>
                    <ListItemButton onClick={() => { navigate('/home'); }}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { navigate('/settings'); }}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Settings"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List style={{ position: 'absolute', bottom: 0 }}>
                <ListItem>
                    <ListItemButton onClick={() => { dispatch(clearToken()); }}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Sign Out"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    if (tokenData === null){
        return (
            <Navigate to={'/login'} />
        );
    } else {
        return (
            <Box style={{ height: '100vh'}} sx={{ pb: 7 }} ref={ref}>
                <div
                    style={{
                        backgroundImage: `url(${background})`,
                        backgroundSize: 'cover',
                        height: '100%',
                        filter: 'blur(6px)', // Adjust the blur value as needed
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1, // Ensure the background is behind the content
                    }}
                />
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
                <Paper sx={{ backgroundColor: '#9c9a9a', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100 }} elevation={3}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <IconButton onClick={toggleDrawer(true)} aria-label="side-drawer-toggle">
                                <MenuIcon />
                            </IconButton>
                        </div>
                    </div>
                </Paper>

                <div style={{paddingTop: '4vh'}}>
                    <Box>
                        <Typography style={styles.sectionHeader}>Profile</Typography>
                        <Box style={{marginLeft: 15, marginBottom: 10, marginTop: 5, flexDirection: 'row'}}>
                            <AccountCircleIcon fontSize={'large'}/>
                        </Box>

                        <Box style={{flexDirection: 'row', marginBottom: 10}}>
                            <Typography style={styles.label}>First Name: {tokenData !== null && tokenData.firstname}</Typography>
                        </Box>
                        <Box style={{flexDirection: 'row', marginBottom: 10}}>
                            <Typography style={styles.label}>Last Name: {tokenData !== null && tokenData.lastname}</Typography>
                        </Box>
                        <Box style={{flexDirection: 'row'}}>
                            <Typography style={styles.label}>Phone Number: {tokenData !== null && tokenData.phoneNumber}</Typography>
                        </Box>
                        {/*<View style={{flexDirection: 'row', marginBottom: 10, justifyContent: 'center'}}>*/}
                        {/*    <TouchableOpacity style={styles.changePasswordBtn} onPress={() => openChangeValue('PASSWORD')}>*/}
                        {/*        <LinearGradient colors={['#cdcbcc', '#aaaaaa']} style={styles.changePasswordBtn}>*/}
                        {/*            <Text style={[styles.textChangePasswordBtn, {color:'#fff'}]}>Change Password</Text>*/}
                        {/*        </LinearGradient>*/}
                        {/*    </TouchableOpacity>*/}
                        {/*</View>*/}
                    </Box>

                    <Divider />

                    <Box style={{ textAlign: 'center' }}>
                        <Button style={{
                            width: '70vw',
                            height: 40,
                            marginTop: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            borderRadius: 10
                        }} variant="contained" onClick={() => { navigate('/reset'); }}>
                            Reset Password
                        </Button>
                        <DeleteAccount />
                    </Box>
                </div>

                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        style={{ backgroundColor: '#9c9a9a' }}
                    >
                        <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
                    </BottomNavigation>
                </Paper>
            </Box>
        );
    }
};


const styles = {
    sectionHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        marginLeft: 15,
        color: '#ffffff',
    },
    label: {
        fontSize: 20,
        fontWeight: '500',
        alignSelf: 'flex-start',
        marginLeft: 15,
        marginRight: 5,
        color: '#ffffff',
    },
    changePasswordBtn: {
        width: '70vw',
        height: 35,
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        borderRadius: 10
    },
    textChangePasswordBtn: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    btn: {
        width: '45vw',
        height: 25,
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        borderRadius: 10
    },
    deleteAccountBtn: {
        width: '93vw',
        height: 25,
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        borderRadius: 10
    },
    btnText: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    background: {
        flex: 1,
        resizeMode: 'cover', // or 'stretch' or 'contain' based on your preference
    },
};

export default SettingsView;

