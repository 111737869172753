import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Alert} from "@mui/material";
import {useDispatch} from "react-redux";
import AccountSelect from "../AccountManagement/AccountSelect";
import CourierService from "../../services/courierService";
import {updateStore} from "../../reducers/storeReducer";
import StoreCourierSelect from "./StoreCourierSelect";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateStoreCourier = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [account, setAccount] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setAccount('');
    }

    const handleAdd = () => {
        if (account === ''){
            showAlertMsg('Must select account!', 'error');
            return;
        }

        CourierService.create(account.accountID, props.store.storeID).then(response => {
            if (response.status === true){
                dispatch(updateStore({
                    store: {
                        ...response.result,
                        couriers: [
                            ...props.store.couriers,
                            response.result
                        ]
                    }
                }));
                showAlertMsg(response.message, 'success');
                clearFields();
            } else {
                showAlertMsg(response.message, 'error');
            }
        }).catch((err) => {
            console.log(err);
            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
        });
    };

    return (
        <div>
            <Button variant="outlined" style={{minWidth:175}} onClick={handleClickOpen}>
                Create Courier
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='lg'
                aria-labelledby="create-new-store-courier-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="create-new-store-courier-dialog-title" onClose={handleClose}>
                    Creating New Store Courier
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item  xs={12} sm={6}>
                               <StoreCourierSelect account={account} setAccount={setAccount} />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} autoFocus onClick={handleAdd}>
                        Create Store Courier
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateStoreCourier;
