import axios from 'axios';
import CON from '../CON';

class PostService {

    getPosts() {
    return axios
      .post(CON.API_URL + 'post/u/getAllU' )
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log('API failed:' + err);
        return null;
      });
  }
}

export default new PostService();
