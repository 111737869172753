import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useNavigate} from "react-router-dom";
import {Alert} from "@mui/lab";
import AuthorizationService from "../../services/AuthorizationService";
import background from "../../assets/background.jpg";
import Link from "@mui/material/Link";


const AdminResetPasswordView = () => {
    const navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [accountId, setAccountId] = React.useState(null);
    const [token, setToken] = React.useState("");
    const [code, setCode] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [repPassword, setRepPassword] = React.useState("");


    const [isWaitingForCode, setIsWaitingForCode] = React.useState(false);
    const [isWaitingForNewPassword, setIsWaitingForNewPassword] = React.useState(false);


    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 6000);
    }

    const clearFields = () => {
        setPassword('');
        setPhoneNumber('');
        setRepPassword('');
        setIsWaitingForCode(false);
        setIsWaitingForNewPassword(false);
    }

    const handleCodeRequest = () => {
        if (phoneNumber === ''){
            showAlertMsg('Must enter email.', 'error');
            return;
        }

        AuthorizationService.createPasswordResetCode(phoneNumber).then(results => {
            if (results !== null){
                if (results.status){
                    showAlertMsg(results.message, 'success');
                    setToken(results.result);
                    setAccountId(results.accountID);
                    setIsWaitingForCode(true);
                } else {
                    setIsWaitingForCode(false);
                    showAlertMsg(results.message, 'error');
                }
            }
        }).catch(err => {
            console.log(err);
            return null;
        });

    };

    const handlePasswordRest = () => {
        if (password === ''){
            showAlertMsg('Must enter password', 'error');
            return;
        }

        if (repPassword === ''){
            showAlertMsg('Must enter password', 'error');
            return;
        }

        if (password !== repPassword){
            showAlertMsg(`Password don't match`, 'error');
            return;
        }

        AuthorizationService.resetPassword(token, password, repPassword).then(results => {
            if (results !== null){
                if (results.status){
                    showAlertMsg(results.message, 'success');
                    clearFields();
                } else {
                    setIsWaitingForCode(false);
                    showAlertMsg(results.message, 'error');
                }
            }
        }).catch(err => {
            console.log(err);
            return null;
        });

    };

    const handleCodeVerification = () => {
        if (code === ''){
            showAlertMsg('Must enter a code.', 'error');
            return;
        }

        AuthorizationService.verifyPasswordResetCode(accountId, code).then(results => {
            if (results !== null){
                if (results.status){
                    showAlertMsg(results.message, 'success');
                    setIsWaitingForNewPassword(true);
                    setIsWaitingForCode(false);
                } else {
                    showAlertMsg(results.message, 'error');
                }
            }
        }).catch(err => {
            console.log(err);
            return null;
        });

    };

    return (
        <Container component="main" style={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 8,
            py: [3, 6],
        }} maxWidth="xs">
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    height: '100%',
                    filter: 'blur(6px)', // Adjust the blur value as needed
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1, // Ensure the background is behind the content
                }}
            />
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Password Reset
                </Typography>
                <Box style={{ marginTop: 3, padding: 20, borderRadius: 6, borderColor: "transparent", backgroundColor: 'rgba(255,255,255,0.53)' }}>
                    <Grid container spacing={2}>
                        {showAlert &&
                            <Alert style={{margin: '0 auto'}} severity={alertSeverity}>{alertMsg}</Alert>
                        }
                        {isWaitingForNewPassword ?
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    value={password}
                                    type={"password"}
                                    style={{marginBottom: 10, backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12}}
                                    onChange={(event) => {
                                        setPassword(event.target.value)
                                    }}
                                />
                                <TextField
                                    required
                                    fullWidth
                                    id="repPassword"
                                    label="Repeat Password"
                                    name="repPassword"
                                    type={"password"}
                                    value={repPassword}
                                    style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                    onChange={(event) => {
                                        setRepPassword(event.target.value)
                                    }}
                                />
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phoneNumber"
                                    label="Email"
                                    name="phoneNumber"
                                    autoComplete="email"
                                    variant={'filled'}
                                    value={phoneNumber}
                                    style={{marginBottom: 10, backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12}}
                                    onChange={(event) => {
                                        setPhoneNumber(event.target.value)
                                    }}
                                />
                                {isWaitingForCode &&
                                    <TextField
                                        required
                                        fullWidth
                                        id="resetCode"
                                        label="Reset Code"
                                        name="resetCode"
                                        value={code}
                                        style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                        onChange={(event) => {
                                            setCode(event.target.value)
                                        }}
                                    />
                                }
                            </Grid>

                        }
                    </Grid>

                    {isWaitingForNewPassword ?
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() =>{
                                handlePasswordRest()
                            }}
                        >
                            Reset Password
                        </Button>
                        :
                        (isWaitingForCode ?
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() =>{
                                        handleCodeVerification()
                                    }}
                                >
                                    Verify Code
                                </Button>
                                :
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() =>{
                                        handleCodeRequest()
                                    }}
                                >
                                    Request Reset Code
                                </Button>
                        )
                    }


                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link style={{ color: '#444242' }}  onClick={() => {
                                navigate("/admin/login");
                            }} >
                                Back to Login.
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default AdminResetPasswordView;
