import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";

const CategorySelect = (props) => {
    const categories = useSelector(state => state.categoryManager.categories);

    const handleChange = (event) => {
        props.setCategory(event.target.value);
    };

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="category-select-label" required={(props.required !== undefined) && (props.required != null) ? props.required : false} >{props.isParent ? "Parent Category" : "Category"}</InputLabel>
                <Select
                    labelId="category-select-label"
                    id="category-select-label"
                    value={props.category}
                    label="Category"
                    disabled={(props.disabled !== undefined) && (props.disabled != null) ? !props.disabled : false}
                    onChange={handleChange}
                >
                    {categories.map((category, index) => (
                        (props.isParent ?
                                (category.parentID === null &&
                                    <MenuItem key={category.categoryID} value={category}>
                                        {category.name}
                                    </MenuItem>
                                )
                            :
                                (category.parentID !== null &&
                                    <MenuItem key={category.categoryID} value={category}>
                                        {category.name}
                                    </MenuItem>
                                )
                        )

                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default CategorySelect;
