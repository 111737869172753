import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import {useDispatch, useSelector} from "react-redux";
import {changeTableViewMode} from "../../reducers/preferenceReducer";
import getText from "../../language/getText";
import {useEffect} from "react";
import CategoryService from "../../services/categoryService";
import {setCategories} from "../../reducers/categoryReducer";
import CreateCategory from "./CreateCategory";
import CategoryView from "./CategoryView";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCells = [
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: getText(languagePreference.lang, 'PCM10'),
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: getText(languagePreference.lang, 'SM2'),
        },
        {
            id: 'parentName',
            numeric: false,
            disablePadding: false,
            label: getText(languagePreference.lang, 'PCM12'),
        },
        {
            id: 'status',
            numeric: true,
            disablePadding: false,
            label: getText(languagePreference.lang, 'AM13'),
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: '',
            // label: getText(languagePreference.lang, 'AM14'),
        },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, ...(numSelected > 0 &&
                    {bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),})
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="categoryTableTitle"
                component="div"
            >
                {getText(languagePreference.lang, 'PCM2')}
            </Typography>
            <CreateCategory />
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const CategoryTable = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const categories = useSelector(state => state.categoryManager.categories);
    const isDense = useSelector(state => state.preferenceManager.isDense);
    const dispatch = useDispatch();

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // const newSelecteds = rows.map((n) => n.name);
            // setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case "ARCHIVE":
                return getText(languagePreference.lang, 'PCM7');
            case "VISIBLE":
                return getText(languagePreference.lang, 'PCM8');
            case "HIDDEN":
                return getText(languagePreference.lang, 'PCM9');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const handleChangeDense = (event) => {
        dispatch(changeTableViewMode());
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (props.searchResults === null ? categories.length : props.searchResults.length)) : 0;


    useEffect(() => {

    }, []);

    return (
        <Box sx={{ display: 'inline-block', verticalAlign: 'top' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 500 }}
                        aria-labelledby="tableTitle"
                        size={isDense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={props.searchResults === null ? categories.length : props.searchResults.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort((props.searchResults === null ? categories : props.searchResults), getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.categoryID);
                                    const labelId = `enhanced-table-checkbox-${row.categoryID}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.categoryID}
                                            selected={isItemSelected}
                                        >
                                            <TableCell align="center">{row.categoryID}</TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.parentName !== null ? row.parentName : "---"}</TableCell>
                                            <TableCell align="left">{getStatusLabel(row.status)}</TableCell>
                                            <TableCell align="left">
                                                <CategoryView category={row} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: (isDense ? 33 : 53) * emptyRows,}}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 45]}
                    component="div"
                    count={props.searchResults === null ? categories.length : props.searchResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}


export default CategoryTable;
