import React from 'react';
import {Box, Typography} from "@mui/material";
import PostService from "../../services/PostService";
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

const SocialMediaPosts = (props) => {
    const [posts, setPosts] = React.useState([]);
    const [fallbackMessage, setFallbackMessage] = React.useState("");

    // const posts = [
    //     {
    //         title: '2024 RSBC Livonia Membership Alert',
    //         date: '',
    //         content: 'The time has come to sign up for the 2024 Storm Chasers Beer Club.\n\n' +
    //             'Membership includes:\n' +
    //             '+ $1 off full pours\n' +
    //             '+ $2 off 4 packs\n' +
    //             '+ A free 5oz pour of new beers we put on tap\n' +
    //             '+ Six free four packs (of your choice) during the year\n' +
    //             '+ 15% off merchandise\n' +
    //             '+ An exclusive 2024 Storm Chaser glass\n' +
    //             '+ An annual members party\n' +
    //             '+ A free birthday beer\n' +
    //             'Be part of the best beer club around!\n' +
    //             '$175 for a single membership\n' +
    //             '$250 for a couples membership (only benefit that is not doubled is the six free 4 packs)',
    //         media: null,
    //     }
    // ];

    const createPost = (title, date, content, media) => {
        return (
            <Box style={styles.post}>
                <Typography style={styles.title}>{title}</Typography>
                <Typography style={styles.date}>{date}</Typography>
                <Typography style={styles.postContent}>{content}</Typography>
                <Box style={{ display: 'flex', overflow: 'scroll' }} >
                    {media.map((value, index) => {
                        return (
                            <div>
                                <img alt={index} key={index} src={value} style={styles.image} />
                            </div>

                        )
                    })}
                </Box>
            </Box>
        );
    }

    React.useEffect( () => {
        PostService.getPosts().then((res) => {
            if (res.status === true) {
                setPosts(res.results);
            }
            if (res.fallbackMessage) {
                setFallbackMessage(res.fallbackMessage);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <Box style={styles.content}>
            {posts.length > 0 ?
                (posts.map((post, index) => {
                    return (
                        <Box key={index}>
                            {createPost(post.title, post.date, post.content, post.media)}
                        </Box>
                    );
                }))
                :
                <Box>
                    <Typography style={styles.sectionHeader}>{fallbackMessage}</Typography>
                </Box>
            }
        </Box>
    );
}

const styles = {
    content: {
        // height: wp('40%'),
        // backgroundColor: '#c6c1c1',
        padding: 25,
        alignSelf: 'center',
        // justifyContent: 'center',
        // alignItems: 'center',
        borderRadius: 4,
        borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    title: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: '4vw',
    },
    date: {
        color: 'rgba(83,83,83,0.82)',
        fontWeight: 'bold',
        fontSize: '3.5vw',
    },
    postContent: {
        color: '#000',
        marginTop: 10,
        fontSize: '4vw',
        whiteSpace: 'pre-wrap'
    },
    postMedia: {

    },
    image: {
        objectFit: 'contain',
        width: '75vw',
        // height: '45vh',
        borderRadius: 6,
        overflow: 'hidden',
        alignSelf: 'center',
        marginBottom: 15,
        marginRight: 15,
    },
    post: {
        backgroundColor: '#e3dede',
        paddingTop: 25,
        paddingLeft: 15,
        paddingRight: 10,
        marginBottom: 20,
        borderRadius: 6,
    }
};

export default SocialMediaPosts;

