import axios from "axios";
import CON from "../CON";

class StoreService {
    create(name, address, city, state, country, zipCode, phoneNumber) {
        return axios
            .post(CON.IP + "/store/create", { name, address, city, state, country, zipCode, phoneNumber })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    getAll() {
        return axios
            .post(CON.IP + "/store/getAll")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    getAllZipCodes() {
        return axios
            .post(CON.IP + "/store/getAllZipCodes")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    updateZipCodes(zipCodes) {
        return axios
            .post(CON.IP + "/store/updateZipCodes", {zipCodes})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    searchByName(name) {
        return axios
            .post(CON.IP + "/store/searchByName", { name })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeStatus(storeID, newValue) {
        return axios
            .post(CON.IP + "/store/changeStatus",{ storeID, newValue })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

    changeName(storeID, newValue) {
        return axios
            .post(CON.IP + "/store/changeName",{ storeID, newValue })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }
}

export default new StoreService();
