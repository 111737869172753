import { createSlice } from '@reduxjs/toolkit'
import AuthenticationService from "../services/AuthorizationService";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const authenticationReducer = createSlice({
    name: 'authenticationReducer',
    initialState: {
        loginStatus: false,
        accessToken: null,
        token: null,
        tokenData: null,
    },
    reducers: {
        login: (state, action) => {
            if (action.payload.accessToken){
                axios.defaults.headers.common["Authorization"] = `${action.payload.accessToken}`;
                state.accessToken = action.payload.accessToken;
                state.loginStatus = true;
            }
        },
        logout: (state, action) => {
            AuthenticationService.logout()
            state.loginStatus = false;
            state.accessToken = null;
        },
        setToken: (state, action) => {
            state.token = action.payload.token;
            state.tokenData = jwt_decode(action.payload.token);
        },
        clearToken: (state, action) => {
            state.token = null;
            state.tokenData = null;
            axios.defaults.headers.common["Authorization"] = null;
        },
    },
})

export const { login, logout, setToken, clearToken } = authenticationReducer.actions

export default authenticationReducer
