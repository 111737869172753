import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MetaSelect from "./MetaSelect";
import Card from "@mui/material/Card";
import {CardActions, CardMedia} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MetaPresetSelect from "./MetaPresetSelect";
import CON from "../../CON";
import {useSelector} from "react-redux";

// const Dymo = require('dymojs'),
//     dymo = new Dymo();

const StockAttributeInfoCreation = (props) =>  {
    const isDark = useSelector(state => state.preferenceManager.isDark);

    const [value, setValue] = React.useState('');
    const [selectedMetaTag, setSelectedMetaTag] = React.useState('');

    let labelXml = `
<?xml version="1.0" encoding="utf-8"?>
<DieCutLabel Version="8.0" Units="twips">
  <PaperOrientation>Landscape</PaperOrientation>
  <Id>LargeShipping</Id>
  <PaperName>30256 Shipping</PaperName>
  <DrawCommands>
    <RoundRectangle X="0" Y="0" Width="3331" Height="5715" Rx="270" Ry="270"/>
  </DrawCommands>
  <ObjectInfo>
    <TextObject>
      <Name>TEXT</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>AlwaysFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
        <Element>
          <String>T</String>
          <Attributes>
            <Font Family="Helvetica" Size="13" 
            	Bold="False" Italic="False" Underline="False" Strikeout="False"/>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          </Attributes>
        </Element>
        <Element>
          <String>EST123</String>
          <Attributes>
            <Font Family="Helvetica" Size="13" 
            	Bold="False" Italic="False" Underline="False" Strikeout="False"/>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          </Attributes>
        </Element>
      </StyledText>
    </TextObject>
    <Bounds X="335.9998" Y="57.6001" Width="5337.6" Height="3192"/>
  </ObjectInfo>
</DieCutLabel>
`;

    const renderLabel = (labelXml) => {
        // dymo.renderLabel(labelXml).then(imageData => {
        //     console.log(imageData);
        //     // <img src="data:image/png;base64,${imageData}"/>
        //     // <img src={`data:image/png;base64,${imageData}`} alt={"label preview"}/>
        // });
    }

    const removeAttribute = (removeAt) => {
        let newLst = props.metaTags.filter((item, index) => index !== removeAt);
        props.setMetaTags(newLst);
    }

    const renderAttributeCard = (tag, index) => {

        return (
            <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                {(tag.value.assetID !== undefined && tag.value.assetID !== null) &&
                    <CardMedia
                        component="img"
                        style={{width: 100, margin: '0 auto', filter: (isDark ?
                                    "invert(100%) sepia(96%) saturate(6727%) hue-rotate(181deg) brightness(130%) contrast(106%)"
                                    :
                                    "invert(0%) sepia(96%) saturate(0%) hue-rotate(262deg) brightness(98%) contrast(100%);"
                            )}}
                        image={CON.GET_IMAGE + tag.value.assetID}
                        alt={tag.value.value + "-icon"}
                    />
                }
                <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                        ID: {tag.meta.metaID} | Name: {tag.meta.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Value: {tag.value.value !== undefined ? tag.value.value : tag.value}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button variant={"outlined"} size={"small"} onClick={() => {
                        removeAttribute(index);
                    }}>
                        Remove
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const handleAdd = () => {
        props.handleAddAttribute(selectedMetaTag, value, selectedMetaTag.presets.length > 0);
        setValue('');
        setSelectedMetaTag('');
    }

    return (
        <Box noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item  xs={12} sm={6}>
                    <MetaSelect selectedMetaTag={selectedMetaTag} setSelectedMetaTag={setSelectedMetaTag} />
                </Grid>
                <Grid item  xs={12} sm={12}>
                    {selectedMetaTag !== '' &&
                        (selectedMetaTag.presets.length > 0 ?
                                <MetaPresetSelect
                                    meta={selectedMetaTag}
                                    value={value} setValue={setValue}
                                />
                                :
                                <TextField
                                    autoFocus
                                    value={value}
                                    onChange={event => setValue(event.target.value)}
                                    name="value"
                                    required
                                    fullWidth
                                    id="value"
                                    label="value"
                                />
                        )
                    }

                </Grid>
                <Grid item  xs={12} sm={12}>
                    <Button variant="outlined" disabled={selectedMetaTag === '' || value === ''} style={{minWidth:175}} onClick={() => { handleAdd() }}>
                        Add Attribute
                    </Button>
                </Grid>
                <Grid item  xs={12} sm={12}>
                    {props.metaTags.length > 0 ? (
                        (props.metaTags.map((tag, index) => {
                            return renderAttributeCard(tag, index);
                        }))
                    ) : (
                        <p>No attributes set</p>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}


export default StockAttributeInfoCreation;
