import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import {Alert, CardActions, CardMedia, CircularProgress} from "@mui/material";
import {useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {AlertTitle} from "@mui/lab";
import GeneralPromotionInfoCreation from "./GeneralPromotionInfoCreation";
import RewardPromotionInfoCreation from "./RewardPromotionInfoCreation";
import ConditionsPromotionInfoCreation from "./ConditionsPromotionInfoCreation";
import PromotionCreationReview from "./PromotionCreationReview";
import PromotionService from "../../services/promotionService";
import {addProduct} from "../../reducers/productReducer";
import {addPromotion, setPromotions} from "../../reducers/promotionReducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreatePromotion = (props) => {
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = React.useState(0);

    const [isReferral, setIsReferral] = React.useState(false);
    const [isCategory, setIsCategory] = React.useState(false);
    const [isProduct, setIsProduct] = React.useState(false);
    const [isBrand, setIsBrand] = React.useState(false);
    const [requireCode, setRequireCode] = React.useState(false);
    const [minAmountConstraint, setMinAmountConstraint] = React.useState(false);
    const [timeConstraint, setTimeConstraint] = React.useState('');
    const [brand, setBrand] = React.useState(null);

    const [membershipType, setMembershipType] = React.useState(null);
    const [store, setStore] = React.useState(null);
    const [account, setAccount] = React.useState([]);

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [termsAndCondition, setTermsAndCondition] = React.useState('');
    const [discount, setDiscount] = React.useState(null);
    const [quantity, setQuantity] = React.useState(null);
    const [code, setCode] = React.useState('');
    const [minAmount, setMinAmount] = React.useState(0);
    const [category, setCategory] = React.useState('');
    const [product, setProduct] = React.useState('');
    const [rewardType, setRewardType] = React.useState(null);

    const [productStock, setProductStock] = React.useState(null);

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [startTime, setStartTime] = React.useState(null);
    const [endTime, setEndTime] = React.useState(null);

    const [onMonday, setOnMonday] = React.useState(true);
    const [onTuesday, setOnTuesday] = React.useState(true);
    const [onWednesday, setOnWednesday] = React.useState(true);
    const [onThursday, setOnThursday] = React.useState(true);
    const [onFriday, setOnFriday] = React.useState(true);
    const [onSaturday, setOnSaturday] = React.useState(true);
    const [onSunday, setOnSunday] = React.useState(true);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const [didReceivesResponse, setDidReceivesResponse] = React.useState(false);
    const [didSave, setDidSave] = React.useState(false);

    const [selectedFiles, setSelectedFiles] = React.useState([]);

    const [logo, setLogo] = React.useState(null);
    const [logoPreview, setLogoPreview] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setName('');
        setDescription('');
        setCategory('');
        setLogo(null);
        setAccount([]);
    }

    const handleRewardType = (value) => {
        setRewardType(value);
        setAlertMsg('Select a product or category to limit award. Selecting category will select all products in that category.');
        setAlertSeverity('warning');
        setShowAlert(true);
        // setTimeout(() => {
        //     setShowAlert(false);
        //     setAlertSeverity('error');
        //     setAlertMsg('');
        // }, 15000);
    }

    const handleNext = () => {
        console.log(account);
        if (activeStep === 0){
            if (name === ''){
                showAlertMsg('Name field is required!', 'error');
                return;
            }

            if (description === ''){
                showAlertMsg('Description field is required!', 'error');
                return;
            }

            if (!membershipType){
                showAlertMsg('Membership Type field is required!', 'error');
                return;
            }

            if (startDate === null){
                showAlertMsg('Start Date field is required!', 'error');
                return;
            }

            // if (endDate === ''){
            //     showAlertMsg('End Date field is required!', 'error');
            //     return;
            // }

            if (logo === null){
                showAlertMsg('Background is required!', 'error');
                return;
            }



            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (activeStep === 1){
            if (rewardType === ''){
                showAlertMsg('Must select a reward type!', 'error');
                return;
            }

            if (rewardType === 'FREE_ITEM'){
                if (quantity === null){
                    showAlertMsg('Quantity field is required for free item reward type!', 'error');
                    return;
                }

                if (quantity <= 0){
                    showAlertMsg('Quantity minimum 1!', 'error');
                    return;
                }

                if (category === '' && product === ''){
                    showAlertMsg('Must select either a Product or Category for free item reward type!', 'error');
                    return;
                }
            }

            if (rewardType === 'BUY_ONE_GET_ONE'){
                if (product === ''){
                    showAlertMsg('Must select a Product for buy one get one reward type!', 'error');
                    return;
                }
            }

            if (rewardType === 'BUY_ONE_GET_ONE_X_OFF_VARIABLE' || rewardType === 'BUY_ONE_GET_ONE_X_OFF_EXACT'){
                if (product === ''){
                    showAlertMsg('Must select a Product for buy one get one reward type!', 'error');
                    return;
                }

                if (discount === null){
                    showAlertMsg('Discount field is required for buy one get one x off (variable) reward type!', 'error');
                    return;
                }
            }

            if (rewardType === 'VARIABLE_DISCOUNT' || rewardType === 'EXACT_DISCOUNT'){
                if (discount === null){
                    showAlertMsg('Discount field is required for variable discount reward type!', 'error');
                    return;
                }
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (activeStep === 2){
            // if (requireCode === true){
            //     if (code === ''){
            //         showAlertMsg('Code field is required!', 'error');
            //         return;
            //     }
            // }
            //
            // if (minAmountConstraint === true){
            //     if (minAmount === ''){
            //         showAlertMsg('Minimum Amount field is required!', 'error');
            //         return;
            //     }
            // }

            if (timeConstraint === true){
                if (startTime === null){
                    showAlertMsg('Start Time field is required!', 'error');
                    return;
                }

                if (endTime === null){
                    showAlertMsg('End Time field is required!', 'error');
                    return;
                }
            }

            if (description === ''){
                showAlertMsg('Description field is required!', 'error');
                return;
            }

            if (startDate === ''){
                showAlertMsg('Start Date field is required!', 'error');
                return;
            }

            if (endDate === ''){
                showAlertMsg('End Date field is required!', 'error');
                return;
            }



            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        const formatDateNumber = (value) => {
            if (value <= 9){
                return `0${value}`
            } else {
                return value;
            }
        }

        if (activeStep === 3){
            let data = new FormData();
            data.append("name", name);
            data.append("startDate", `${startDate.getFullYear()}-${formatDateNumber(startDate.getMonth()+1)}-${formatDateNumber(startDate.getDate())}`);
            data.append("endDate", (endDate ? `${endDate.getFullYear()}-${formatDateNumber(endDate.getMonth()+1)}-${formatDateNumber(endDate.getDate())}` : null));
            data.append("description", description);
            data.append("termsAndCondition", termsAndCondition);
            data.append("product", JSON.stringify(product));
            data.append("productStock", JSON.stringify(productStock));
            data.append("category", JSON.stringify(category));
            data.append("brand", JSON.stringify(brand));
            data.append("rewardType", rewardType);
            data.append("isCategory", (isCategory === true ? 1 : 0));
            data.append("isProduct", (isProduct === true ? 1 : 0));
            data.append("isReferral", (isReferral === true ? 1 : 0));
            data.append("isBrand", (isBrand === true ? 1 : 0));
            data.append("minAmount", minAmount);
            data.append("discount", discount);
            data.append("quantity", quantity);
            data.append("minAmountConstraint", minAmountConstraint);
            data.append("timeConstraint", timeConstraint);
            data.append("requireCode", (requireCode === true ? 1 : 0));
            data.append("code", code);
            data.append("startTime", (startTime !== null ? `${startTime.getHours()}:${startTime.getMinutes()}` : null));
            data.append("endTime", (endTime !== null ? `${endTime.getHours()}:${endTime.getMinutes()}` : null));
            data.append("onlyMonday", (onMonday === true ? 1 : 0));
            data.append("onlyTuesday", (onTuesday === true ? 1 : 0));
            data.append("onlyWednesday", (onWednesday === true ? 1 : 0));
            data.append("onlyThursday", (onThursday === true ? 1 : 0));
            data.append("onlyFriday", (onFriday === true ? 1 : 0));
            data.append("onlySaturday", (onSaturday === true ? 1 : 0));
            data.append("onlySunday", (onSunday === true ? 1 : 0));
            data.append("background", logo);
            data.append("membershipType", membershipType);
            data.append("store", JSON.stringify(store));
            data.append("account", JSON.stringify(account));


            PromotionService.create(data).then(response => {
                if (response){
                    console.log(response);
                    if (response.status === true){
                        dispatch(addPromotion({
                            promotion: response.result
                        }));
                        setDidReceivesResponse(true);
                        setDidSave(true);
                        PromotionService.getAll().then((response) =>{
                            console.log(response.results)
                            if (response.status === true){
                                dispatch(setPromotions({
                                    promotions: response.results
                                }));
                            } else {
                                // show error could not get data
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                        showAlertMsg(response.message, 'success');
                        clearFields();
                        handleClose();
                    } else {
                        setDidReceivesResponse(true);
                        setDidSave(false);
                        showAlertMsg(response.message, 'error');
                    }
                } else {
                    showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                }
            }).catch((err) => {
                console.log(err);
            });

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        clearFields();
        setDidReceivesResponse(false);
        setDidSave(false);
        setActiveStep(0);
    };

    const EndView = () => {
        return (
            (didReceivesResponse ?
                    (didSave ?
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                <strong>Promotion Created</strong> — {alertMsg}
                            </Alert>
                            :
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                <strong>Promotion Not Created </strong> — Unfortunately something went wrong in the backend
                                refresh the browser and try again. If you still are experiencing problems contact the IT department.
                            </Alert>
                    )
                    :
                    <CircularProgress color="primary" />
            )
        )
    };

    const steps = [
        {
            label: 'General Information',
            content: <GeneralPromotionInfoCreation
                name={name} setName={setName}
                startDate={startDate} setStartDate={setStartDate}
                endDate={endDate} setEndDate={setEndDate}
                description={description} setDescription={setDescription}
                logo={logo} setLogo={setLogo}
                logoPreview={logoPreview} setLogoPreview={setLogoPreview}
                termsAndCondition={termsAndCondition} setTermsAndCondition={setTermsAndCondition}
                membershipType={membershipType} setMembershipType={setMembershipType}
                store={store} setStore={setStore}
                account={account} setAccount={setAccount}
            />
        },
        {
            label: 'Reward',
            content: <RewardPromotionInfoCreation
                isCategory={isCategory} setIsCategory={setIsCategory}
                isProduct={isProduct} setIsProduct={setIsProduct}
                discount={discount} setDiscount={setDiscount}
                category={category} setCategory={setCategory}
                product={product} setProduct={setProduct}
                isBrand={isBrand} setIsBrand={setIsBrand}
                brand={brand} setBrand={setBrand}
                productStock={productStock} setProductStock={setProductStock}
                quantity={quantity} setQuantity={setQuantity}
                rewardType={rewardType} handleRewardType={handleRewardType}
            />
        },
        {
            label: 'Conditions',
            content: <ConditionsPromotionInfoCreation
                code={code} setCode={setCode}
                startTime={startTime} setStartTime={setStartTime}
                endTime={endTime} setEndTime={setEndTime}
                timeConstraint={timeConstraint} setTimeConstraint={setTimeConstraint}
                minAmountConstraint={minAmountConstraint} setMinAmountConstraint={setMinAmountConstraint}
                isReferral={isReferral} setIsReferral={setIsReferral}
                mondayOnly={onMonday} setMondayOnly={setOnMonday}
                tuesdayOnly={onTuesday} setTuesdayOnly={setOnTuesday}
                wednesdayOnly={onWednesday} setWednesdayOnly={setOnWednesday}
                thursdayOnly={onThursday} setThursdayOnly={setOnThursday}
                fridayOnly={onFriday} setFridayOnly={setOnFriday}
                saturdayOnly={onSaturday} setSaturdayOnly={setOnSaturday}
                sundayOnly={onSunday} setSundayOnly={setOnSunday}
                minAmount={minAmount} setMinAmount={setMinAmount}
                product={product} setProduct={setProduct}
                quantity={quantity} setQuantity={setQuantity}
                requireCode={requireCode} setRequireCode={setRequireCode}
            />
        },
        {
            label: 'Review',
            content: <PromotionCreationReview
                name={name} startDate={startDate}
                endDate={endDate} description={description}
                isCategory={isCategory} isProduct={isProduct}
                discount={discount} category={category}
                product={product} quantity={quantity}
                rewardType={rewardType} code={code}
                startTime={startTime} endTime={endTime}
                termsAndCondition={termsAndCondition} setTermsAndCondition={setTermsAndCondition}
                timeConstraint={timeConstraint} minAmountConstraint={minAmountConstraint}
                isReferral={isReferral} mondayOnly={onMonday}
                tuesdayOnly={onTuesday} wednesdayOnly={onWednesday}
                thursdayOnly={onThursday} fridayOnly={onFriday}
                saturdayOnly={onSaturday} sundayOnly={onSunday}
                minAmount={minAmount} requireCode={requireCode}
                logo={logo} setLogo={setLogo}
                logoPreview={logoPreview} setLogoPreview={setLogoPreview}
            />
        },
        {
            label: 'Create',
            content: <EndView />
        }
    ];

    return (
        <div>
            <Button size={"small"} variant="outlined" style={{minWidth:175, marginLeft: 10}} onClick={handleClickOpen}>
                Create Promotion
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='xl'
                aria-labelledby="new-promotion-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="new-promotion-dialog-title" onClose={handleClose}>
                    Creating New Promotion
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3, mb: 3 }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((step, index) => {
                                const stepProps = {};
                                const labelProps = {};

                                return (
                                    <Step key={index} {...stepProps}>
                                        <StepLabel {...labelProps}>{step.label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                    {steps[activeStep].content}
                </DialogContent>
                <DialogActions>
                    {activeStep + 1-1 === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, mr: 2 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button variant={"contained"} onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button variant={"contained"} onClick={handleNext}>
                                    {activeStep + 1 === steps.length - 1 ? 'Create Promotion' : 'Next'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreatePromotion;
