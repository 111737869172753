import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import QrCodeIcon from '@mui/icons-material/QrCode';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedLocations} from '../../reducers/locationReducer';
import {Button, Drawer, IconButton, Link, Typography} from "@mui/material";

import RewardService from '../../services/RewardService';
import CategoryService from '../../services/categoryService';
import LocationService from '../../services/LocationService';
import {setRewards} from '../../reducers/rewardReducer';
import {setCategories} from '../../reducers/categoryReducer';
import ProductService from '../../services/productService';
import {setDrinks} from '../../reducers/drinkReducer';
import {setLocations} from '../../reducers/locationReducer';
import DrinkCard from "../../components/Drinks/DrinkCard";
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import SocialMediaPosts from "../../components/Home/SocialMediaPosts";
import CategoryCard from "../../components/Category/CategoryCard";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import background from "../../assets/background.jpg";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import {Navigate, useNavigate} from "react-router-dom";
import {clearToken} from "../../reducers/authenticationReducer";
import RewardCard from "../../components/Reward/RewardCard";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const HomeView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [value, setValue] = React.useState(0);
    const ref = React.useRef(null);

    const locations = useSelector(state => state.locationManager.locations);
    const tokenData = useSelector(state => state.authManager.tokenData);
    const selectedLocation = useSelector(state => state.locationManager.selectedLocation);
    const categories = useSelector(state => state.categoryManager.categories);
    const drinks = useSelector(state => state.drinkManager.drinks);
    const rewards = useSelector(state => state.rewardManager.rewards);

    function switchLocation(index) {
        dispatch(setSelectedLocations({
            selectedLocation: index
        }));
    }

    function getLocationName() {
        let locName = "null";
        for (let i = 0; i < locations.length; i++) {
            if (selectedLocation === null){
                dispatch(setSelectedLocations({
                    selectedLocation: 0
                }));
                locName = locations[0].name;
            } else if (selectedLocation === i){
                locName = locations[i].name;
            }
        }
        return locName;
    }

    const RenderFeaturedDrinks = () => {
      return (
          <ScrollMenu>
              {drinks.map((item, index) => {
                  // Assuming that the logic to determine whether to show the drink or not is correct
                  // and doesn't need adjustment for the web version
                  if (locations.length > 0) {
                      if (item.stock && item.stock.length > 0) {
                          if (locations[selectedLocation].storeID === item.stock[0].store.storeID && item.isFeatured) {
                              return <DrinkCard key={index} drink={item} />;
                          }
                      }
                  } else {
                      return <DrinkCard key={index} drink={item} />;
                  }
                  return null;
              })}
          </ScrollMenu>
      )
    }

    const RenderContent = () => {
      switch (value) {
          case 0:
              return (
                  <div style={{paddingBottom: 20}}>
                      <Typography style={styles.sectionHeader}>Featured</Typography>
                      {RenderFeaturedDrinks()}
                      <Box style={{marginTop: 20}}>
                          <Typography style={styles.sectionHeader}>Posts</Typography>
                          <div style={{ }}>
                              <SocialMediaPosts />
                          </div>
                      </Box>
                  </div>
              )
          case 1:
              return (
                  <div>
                      <Typography style={styles.sectionHeader}>Featured</Typography>
                      {RenderFeaturedDrinks()}
                      <Box style={{ textAlign: 'center', marginTop: 20 }}>
                          {categories.map((item, index) => {
                              if (item.parentID === null) {
                                  return (
                                      <CategoryCard key={index} category={item} />
                                  );
                              }
                          })}
                      </Box>
                  </div>
              )
          case 2:
              return (
                  <div>
                      <Typography style={styles.sectionHeader}>Rewards</Typography>
                      <Box style={{ textAlign: 'center', marginTop: 20 }}>
                          {rewards.length > 0 ?
                              <Box style={{ alignItems: 'center', justifyContent: 'center'}}>
                                  {rewards.map((item, index) => {
                                      return (
                                          <RewardCard key={index} reward={item} type={'REWARD'} />
                                      )
                                  })}
                              </Box>
                              :
                              <Box style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                  <Typography style={styles.sectionHeader}>No Rewards Available</Typography>
                              </Box>
                          }
                      </Box>
                  </div>
              )
          default:
              return ""
      }
    }

    React.useEffect(() => {
        if (tokenData !== null) {
            CategoryService.getCategories().then((res) => {
                if (res.status === true) {
                    dispatch(setCategories({
                        categories: res.results
                    }));
                }
            }).catch((err) => {
                console.log(err);
            });

            RewardService.getRewards(tokenData.accountID).then((res) => {
                if (res.status === true) {
                    dispatch(setRewards({
                        rewards: res.results
                    }));
                } else {
                    dispatch(setRewards({
                        rewards: []
                    }));
                }
            }).catch((err) => {
                console.log(err);
            });

            ProductService.getProducts().then((res) => {
                if (res.status === true) {
                    dispatch(setDrinks({
                        drinks: res.results
                    }));
                }
            }).catch((err) => {
                console.log(err);
            });

            LocationService.getLocation().then((res) => {
                if (res.status === true) {
                    dispatch(setLocations({
                        locations: res.results
                    }));
                } else {

                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const [age, setAge] = React.useState('');

    const DrawerList = (
        <Box style={{ width: 250, backgroundColor: '#9c9a9a', height: '100vh' }} role="presentation" onClick={toggleDrawer(false)}>
            <div style={{display: 'flex', marginLeft: 20, marginTop: 20}}>
                <AccountCircleIcon fontSize={'large'} />
                <Typography style={{fontSize: 25, marginLeft: 10}}>{tokenData !== null ? tokenData.firstname + ' ' + tokenData.lastname : '---'}</Typography>
            </div>
            <List>
                <ListItem>
                    <ListItemButton onClick={() => { navigate('/home'); }}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { navigate('/settings'); }}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Settings"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List style={{ position: 'absolute', bottom: 0 }}>
                <ListItem>
                    <ListItemButton onClick={() => { dispatch(clearToken()); }}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Sign Out"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    if (tokenData === null){
        return (
            <Navigate to={'/login'} />
        );
    } else {
        return (
            <Box style={{ height: '100vh'}} sx={{ pb: 7 }} ref={ref}>
                <div
                    style={{
                        backgroundImage: `url(${background})`,
                        backgroundSize: 'cover',
                        height: '100%',
                        filter: 'blur(6px)', // Adjust the blur value as needed
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1, // Ensure the background is behind the content
                    }}
                />
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
                <Paper sx={{ backgroundColor: '#9c9a9a', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100 }} elevation={3}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <IconButton onClick={toggleDrawer(true)} aria-label="side-drawer-toggle">
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <div style={{ position: 'absolute', right: 0 }}>
                            <FormControl sx={{ mr: 1, minWidth: 120 }} size="small">
                                {/*<InputLabel id="location-select-label"></InputLabel>*/}
                                <Select
                                    labelId="dlocation-select-label"
                                    id="location-select"
                                    value={selectedLocation}
                                    label=""
                                    onChange={(event) => {
                                        console.log("event.target.value")
                                        console.log(event.target.value)
                                        switchLocation(event.target.value);
                                    }}
                                >
                                    {locations.map((index, value) => {
                                        return (
                                            <MenuItem key={index} value={value}>{locations[value].name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            {/*<Link onPress={() => switchLocation(selectedLocation)}>*/}
                            {/*    <Typography style={{fontSize: 22, color: "#ffffff", marginRight: 20}}>*/}
                            {/*        {getLocationName()}*/}
                            {/*    </Typography>*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                </Paper>

                <div style={{paddingTop: '4vh'}}>
                    {RenderContent()}
                </div>

                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        style={{ backgroundColor: '#9c9a9a' }}
                    >
                        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                        <BottomNavigationAction label="Tap Room" icon={<SportsBarIcon />} />
                        <BottomNavigationAction label="Rewards" icon={<QrCodeIcon />} />
                    </BottomNavigation>
                </Paper>
            </Box>
        );
    }
};

const styles = {
    sectionHeader: {
        fontSize: 25,
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        marginLeft: 15,
        color: '#000',
        textAlign: 'left'
    },
    background: {
        flex: 1,
        resizeMode: 'cover', // or 'stretch' or 'contain' based on your preference
        // justifyContent: 'center', // Align children components vertically in the center
    },
};

export default HomeView;

