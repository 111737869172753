import { createSlice } from '@reduxjs/toolkit'

export const rewardReducer = createSlice({
    name: 'rewardReducer',
    initialState: {
        rewards: []
    },
    reducers: {
        setRewards: (state, action) => {
            state.rewards = action.payload.rewards;
        },
    },
})

export const { setRewards } = rewardReducer.actions

export default rewardReducer;
