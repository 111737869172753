import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UsersTable from "./UsersTable";
import RolesTable from "./RolesTable";
import UsersTableFilter from "./UsersTableFilter";
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import AccountService from "../../services/accountService";
import {useEffect} from "react";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AccountManagement = () => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState(null);

    const [value, setValue] = React.useState(0);

    const handleSearch = (term) => {
        if (term !== ''){
            AccountService.search(term).then((response) =>{
                if (response.status === true){
                    setSearchResults(response.accounts);
                } else {
                    setSearchResults([]);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            setSearchResults(null);
        }
    };

    useEffect(() => {

    });

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={(event, value) => { setValue(value) }} aria-label="basic tabs example">
                    <Tab label={getText(languagePreference.lang, 'AM31')} {...a11yProps(0)} />
                    <Tab label={getText(languagePreference.lang, 'AM2')} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <UsersTableFilter searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearch={handleSearch} />
                <UsersTable searchResults={searchResults} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <RolesTable />
            </TabPanel>
        </Box>
    );
}

export default AccountManagement;
