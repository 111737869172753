import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";

const BrandSelect = (props) => {
    const brands = useSelector(state => state.brandManager.brands);

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="brand-select-label" required={props.required}>Brand</InputLabel>
                <Select
                    labelId="brand-select-label"
                    id="brand-select-label"
                    value={props.brand}
                    label="Brand"
                    disabled={props.disabled}
                    onChange={(event) => props.setBrand(event.target.value)}
                >
                    {brands.map((brand, index) => (
                        <MenuItem key={brand.brandID} value={brand}>
                            {brand.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default BrandSelect;
