import * as React from 'react';
import {useSelector} from "react-redux";
import StoreCouriersTable from "./StoreCouriersTable";

const StoreCouriers = (props) => {

    return (
        <div style={{flexGrow: 1}}>
            <StoreCouriersTable store={props.store} />
        </div>
    );
}

export default StoreCouriers;
