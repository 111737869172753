import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector} from "react-redux";

const VariationSelect = (props) => {
    const variations = useSelector(state => state.productManager.variations);

    return (
        <Box style={{minWidth: 125}}>
            <FormControl fullWidth>
                <InputLabel id="variation-select-label" required>Variations</InputLabel>
                <Select
                    labelId="variation-select-label"
                    id="variation-select-label"
                    value={props.selectedVariation}
                    label="Variations"
                    onChange={(event) => {props.setSelectedVariation(event.target.value);}}
                >
                    {variations.map((variation,index) => (
                        <MenuItem key={variation.variationID} value={variation}>
                            {variation.name} | {variation.unit}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default VariationSelect;
