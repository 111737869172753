import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import ChangeMetaValue from "./ChangeMetaValue";
import ChangeStockValue from "./ChangeStockValue";

const StockGeneralInfo = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const getStatusLabel = (status) => {
        switch (status) {
            case "VOID":
                return getText(languagePreference.lang, 'STOCK11');
            case "ACTIVE":
                return getText(languagePreference.lang, 'AM23');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Available
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.stock.available}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeStockValue type={'QTY'} stock={props.stock} product={props.product} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Price
                            </Typography>
                            <Typography variant="h5" component="h2">
                                ${props.stock.price.price}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeStockValue type={'PRICE'} stock={props.stock} product={props.product} />
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.stock.status)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeStockValue type={'VOID'} stock={props.stock} product={props.product} />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    {(props.stock.voidReason !== null && props.stock.voidReason !== '' && props.stock.voidReason !== undefined) &&
                        <div className={"d-inline-block m-1"}>
                            <div className="d-inline-block align-middle">
                                <Typography variant="caption" display="block" gutterBottom>
                                    Void Reason
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    {props.stock.voidReason}
                                </Typography>
                            </div>
                        </div>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default StockGeneralInfo;
