import React from 'react';
import PropTypes from 'prop-types';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MetaGeneralInfo from "./MetaGeneralInfo";
import MetaPresets from "./MetaPresets";
import ProductGeneralInfo from "./ProductGeneralInfo";
import ProductStock from "./ProductStock";
import ProductVariation from "./ProductVariation";
import ProductMeta from "./ProductMeta";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


const ProductDetailView = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const createTab = (label, index, icon) => {
        return (
            <Tab label={label} icon={icon} {...a11yProps(index)} />
        );
    }

    const createTabPanel = (index, content) => {
        return (
            <TabPanel value={value} index={index}>
                {content}
            </TabPanel>
        );
    }

    const sections = [
        {
            visibility: true,
            label: 'General',
            icon: '',
            content: <ProductGeneralInfo product={props.product} />
        },
        {
            visibility: true,
            label: 'Product Variations',
            icon: '',
            content: <ProductVariation product={props.product} />
        },
        {
            visibility: true,
            label: 'Stock/Inventory',
            icon: '',
            content: <ProductStock product={props.product} />
        },
        {
            visibility: true,
            label: 'Meta Tags',
            icon: '',
            content: <ProductMeta product={props.product} />
        }
    ];

    let sectionTabIndex = -1;
    let sectionTabPanelIndex = -1;

    return (
        <div style={{
            flexGrow: 1,
            width: '100%',
            // backgroundColor: theme.palette.background.paper,
        }}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="on" indicatorcolor="primary" textcolor="primary" aria-label="scrollable tabs panel">
                    {sections.map((value, index) => {
                        if (value.visibility){
                            sectionTabIndex++;
                            return createTab(value.label, sectionTabIndex, value.icon);
                        }
                    })}
                </Tabs>
            </AppBar>
            {sections.map((value, index) => {
                if (value.visibility){
                    sectionTabPanelIndex++;
                    return createTabPanel(sectionTabPanelIndex, value.content);
                }
            })}
        </div>
    );
};

export default ProductDetailView;
