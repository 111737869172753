import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import getText from "../../language/getText";
import {Alert, CardActions, CardMedia, FormControlLabel, FormGroup, Switch} from "@mui/material";
import Card from "@mui/material/Card";
import CON from "../../CON";
import CardContent from "@mui/material/CardContent";
import {useEffect} from "react";
import Button from "@mui/material/Button";


const PromotionCreationReview = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const isDark = useSelector(state => state.preferenceManager.isDark);

    const formatDateNumber = (value) => {
        if (value <= 9){
            return `0${value}`
        } else {
            return value;
        }
    };

    useEffect(() => {

    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    General Information
                </Typography>

                <hr />

                <div className="d-inline-block m-1">
                    <div className="m-1">
                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'PCM17')}:
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.name}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'PCM18')}:
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.description}
                            </Typography>
                        </div>
                        <br />
                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'PM11')}:
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.startDate != null ? `${formatDateNumber(props.startDate.getMonth()+1)}-${formatDateNumber(props.startDate.getDate())}-${props.startDate.getFullYear()}` : 'N/A'}
                            </Typography>
                        </div>

                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                {getText(languagePreference.lang, 'PM12')}:
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.endDate != null ? `${formatDateNumber(props.endDate.getMonth()+1)}-${formatDateNumber(props.endDate.getDate())}-${props.endDate.getFullYear()}` : 'N/A'}
                            </Typography>
                        </div>
                        <br />
                        <div style={{margin: 15, display: "inline-block"}}>
                            <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                                Terms & Conditions
                            </Typography>

                            <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                                {props.termsAndCondition}
                            </Typography>
                        </div>

                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                {props.logoPreview !== null ? (
                    <Card style={{maxWidth: 345, display: "inline-block", margin: 10, textAlign: "center"}}>
                        <CardMedia
                            component="img"
                            width="140"
                            image={props.logoPreview}
                            alt={props.logo.name}
                        />
                        <CardActions>
                            <Button variant={"outlined"} size={"small"} onClick={() => {
                                props.setLogo(null);
                                props.setLogoPreview(null);
                            }}>
                                Remove
                            </Button>
                        </CardActions>
                    </Card>
                ) : (
                    <p>Upload background</p>
                )}
            </Grid>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    Reward
                </Typography>

                <hr />

                <div className="d-inline-block m-1">
                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM13')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            {props.rewardType != null ? props.rewardType : 'N/A'}
                        </Typography>
                    </div>

                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM14')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            {props.discount != null ? props.discount : 'N/A'}
                        </Typography>
                    </div>

                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM17')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            x{props.quantity != null ? props.quantity : 'N/A'}
                        </Typography>
                    </div>

                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM15')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            {props.category != null ? props.category.name : 'N/A'}
                        </Typography>
                    </div>

                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM16')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            {props.product !== null ? props.product.name : 'N/A'}
                        </Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} >
                <Typography variant="h5" display="block" gutterBottom>
                    Conditions
                </Typography>

                <hr />

                <div className="d-inline-block m-1">
                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant={"subtitle1"}>Day Constraints</Typography>
                        <FormGroup style={{display: "flex", flexDirection: "row"}}>
                            <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.mondayOnly} disabled />} label="Monday" />
                            <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.tuesdayOnly} disabled />} label="Tuesday" />
                            <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.wednesdayOnly} disabled />} label="Wednesday" />
                            <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.thursdayOnly} disabled />} label="Thursday" />
                            <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.fridayOnly} disabled />}  label="Friday" />
                            <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.saturdayOnly} disabled />} label="Saturday" />
                            <FormControlLabel style={{ margin: '0 auto' }} control={<Switch checked={props.sundayOnly} disabled />} label="Sunday" />
                        </FormGroup>
                    </div>

                    <br />

                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM18')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            {props.minAmount != null ? props.minAmount : 'N/A'}
                        </Typography>
                    </div>

                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM19')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            {props.code != null ? props.code : 'N/A'}
                        </Typography>
                    </div>

                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM20')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            {props.startTime != null ? (`${props.startTime.getHours().toString()}:${props.startTime.getMinutes().toString()}`) : 'N/A'}
                        </Typography>
                    </div>

                    <div style={{margin: 15, display: "inline-block"}}>
                        <Typography variant="h3" style={{fontWeight: "bold", fontSize: 15, display: "inline-block", marginRight: 5}}>
                            {getText(languagePreference.lang, 'PM21')}:
                        </Typography>

                        <Typography variant="body1" style={{fontSize: 15, display: "inline-block"}}>
                            {props.endTime != null ? (`${props.endTime.getHours().toString()}:${props.endTime.getMinutes().toString()}`) : 'N/A'}
                        </Typography>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

export default PromotionCreationReview;
