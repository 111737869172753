import axios from 'axios';
import jwt_decode from "jwt-decode";
import CON from '../CON';

class AuthorizationService {
    login(username, password, deviceInfo) {
        return axios
            .post(CON.API_URL + 'auth/u/login', {username, password, deviceInfo})
            .then(response => {
                if (response.data) {
                    if (response.data.result !== '' || response.data.result !== null){
                        localStorage.setItem("token", JSON.stringify(response.data.accessToken));
                        //AXIOS AUTHORIZATION HEADER
                        axios.defaults.headers.common["Authorization"] = `${response.data.accessToken}`;
                    }
                    return response.data;
                } else {
                    return null;
                }
            })
            .catch(err => {
                console.log('API failed:' + err);
                return null;
            });
    }

  register(firstname, lastname, phoneNumber, email, dob, gender, geoData, password, passwordRep, deviceInfo) {
    return axios
      .post(CON.API_URL + 'account/u/create', {firstname, lastname, phoneNumber, email, dob, gender, geoData, password, passwordRep, deviceInfo})
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log('API failed:' + err);
        return null;
      });
  }

  verify(username, deviceInfo, code) {
    return axios
        .post(CON.API_URL + 'auth/verify', {username, deviceInfo, code})
        .then(response => {
          if (response.data) {
            return response.data;
          } else {
            return null;
          }
        })
        .catch(err => {
          console.log('API failed:' + err);
          return null;
        });
  }

  deleteAccount(token) {
    return axios
        .post(CON.API_URL + 'account/deleteAccount', {token})
        .then(response => {
          if (response.data) {
            return response.data;
          } else {
            return null;
          }
        })
        .catch(err => {
          console.log('API failed:' + err);
          return null;
        });
  }

    updateAccountMissingInformation(email, dob, userToken) {
        return axios
            .post(CON.API_URL + 'account/updateAccountMissingInformation', {email, dob, userToken})
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return null;
                }
            })
            .catch(err => {
                console.log('API failed:' + err);
                return null;
            });
    }

  createPasswordResetCode(phoneNumber) {
    return axios
      .post(CON.API_URL + 'auth/u/reset', {phoneNumber})
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log('API failed:' + err);
        return null;
      });
  }

  verifyPasswordResetCode(accountID, code) {
    return axios
      .post(CON.API_URL + 'auth/u/verifyResetCode', {accountID, code})
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log('API failed:' + err);
        return null;
      });
  }

  requestVerificationCode(phoneNumber) {
    return axios
      .post(CON.IP + 'requestVerificationCode', {phoneNumber})
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log('API failed:' + err);
        return null;
      });
  }

    resetPassword(token, password, repPassword) {
        return axios
            .post(CON.IP + "/auth/u/resetPassword", { token, password, repPassword })
            .then((response) => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('*** API Call Failed ***')
                    console.log('No data received!')
                    return null;
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
                return null;
            });
    }

  getIPAddress() {
    return axios
        .post("https://geolocation-db.com/json/")
        .then((response) => {
          if (response.data) {
            return response.data;
          } else {
            console.log('API failed: No data received!');
            return null;
          }
        }).catch(err => {
          console.log('*** API Call Failed ***');
          console.log(err.toString());
          return null;
        });
  }

    logout() {
        localStorage.removeItem("accessToken");
    }

    getTokenData() {
        if (this.hasLocalToken()){
            return jwt_decode(this.getLocalToken());
        } else {
            return null;
        }
    }

    decodeTokenData(token) {
        return jwt_decode(token);
    }

    checkCode(codes){
        if (this.hasLocalToken()){
            for (let i = 0; i < codes.length; i++) {
                if (this.getTokenData().roles.some(el => el.code === codes[i])){
                    return true;
                }
            }
            return false;
        }
    }

    getLocalToken() {
        if (localStorage.getItem('accessToken') === '' || localStorage.getItem('accessToken') !== null){
            return JSON.parse(localStorage.getItem('accessToken'));
        } else {
            return null;
        }
    }

    hasLocalToken() {
        return localStorage.getItem('accessToken') === '' || localStorage.getItem('accessToken') !== null;
    }

}

export default new AuthorizationService();
