import axios from 'axios';
import CON from '../CON';

class ProductService {

    getProducts() {
    return axios
      .post(CON.API_URL + 'product/u/getAllU')
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log('API failed:' + err);
        return null;
      });
  }

    create(data) {
        return axios
            .post(CON.IP + "/product/create", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    addImage(data) {
        return axios
            .post(CON.IP + "/product/addImage", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    removeImage(productID, productAssetID) {
        return axios
            .post(CON.IP + "/product/removeImage", {productID, productAssetID})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    createProductionVariation(data) {
        return axios
            .post(CON.IP + "/product/createProductVariation", data)
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    getAll() {
        return axios
            .post(CON.IP + "/product/getAll")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    getAllVariation() {
        return axios
            .post(CON.IP + "/product/variation/getAll")
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    searchByName(name) {
        return axios
            .post(CON.IP + "/product/searchByName", { name })
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeName(productID, newValue) {
        return axios
            .post(CON.IP + "/product/changeName", {productID, newValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeDescription(productID, newValue) {
        return axios
            .post(CON.IP + "/product/changeDescription", {productID, newValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeVariationName(productVariationID, name) {
        return axios
            .post(CON.IP + "/product/changeVariationName", {productVariationID, name})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeVariationDescription(productVariationID, description) {
        return axios
            .post(CON.IP + "/product/changeVariationDescription", {productVariationID, description})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeStatus(productID, newValue) {
        return axios
            .post(CON.IP + "/product/changeStatus", {productID, newValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeIsFeatured(productID, newValue) {
        return axios
            .post(CON.IP + "/product/isFeatured", {productID, newValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    changeBrand(productID, brandID) {
        return axios
            .post(CON.IP + "/product/changeBrand", {productID, brandID})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    addMeta(productID, meta, value, isPresetValue) {
        return axios
            .post(CON.IP + "/product/addMeta", {productID, meta, value, isPresetValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    addVariationMeta(productID, productVariationID, meta, value, isPresetValue) {
        return axios
            .post(CON.IP + "/product/addVariationMeta", {productID, productVariationID, meta, value, isPresetValue})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }

    removeMeta(productID, productMetaID) {
        return axios
            .post(CON.IP + "/product/removeMeta", {productID, productMetaID})
            .then((response) => {
                if (response.data) {
                    if (response.data !== null){
                        return response.data;
                    } else {
                        console.log('*** API Call Failed ***')
                        return null;
                    }
                }
            }).catch(err => {
                console.log('*** API Call Failed ***')
                console.log(err.toString())
            });
    }
}

export default new ProductService();
