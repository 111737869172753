import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from "../../components/Copyright/Copyright";
import {Navigate, useNavigate} from "react-router-dom";
import AuthenticationService from "../../services/AuthorizationService";
import {useDispatch, useSelector} from "react-redux";
import {login, setToken} from "../../reducers/authenticationReducer";
import {Alert, Link} from '@mui/material'
import background from "../../assets/background.jpg";
import GetMissingAccountInfo from "../../components/GetMissingAccountInfo";

export default function LoginView() {
    const navigate = useNavigate();
    const loginStatus = useSelector(state => state.authManager.loginStatus);
    const token = useSelector(state => state.authManager.token);

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [accountData, setAccountData] = React.useState(null);
    const [userToken, setUserToken] = React.useState(null);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMSg] = React.useState("");

    const handleError = (msg, state) => {
        switch (state) {
            case true:
                setError(true);
                setErrorMSg(msg);
                break;
            case false:
                setError(false);
                setErrorMSg("");
                break;
            default:
                setError(false);
                setErrorMSg("");
                break;
        }
    }

    const handleSubmit = () => {
        if (username.length > 0 && password.length > 0){
            handleError("", false);
            let deviceInfo = {
                ipAddress: null,
                uuid: null,
                deviceBrand: null,
                deviceID: null,
                deviceModel: null,
                deviceName: null,
                appVersion: null,
                buildNumber: null,
            };
            AuthenticationService.login(username, password, deviceInfo).then(res => {
                if (res.result){
                    // console.log(res.result)
                    let adata = AuthenticationService.decodeTokenData(res.result);

                    if (!adata.email || !adata.dob){
                        // console.log(adata)
                        setOpen(true);
                        setAccountData(adata)
                        setUserToken(res.result);
                    } else {
                        dispatch(setToken({
                            token: res.result
                        }));
                    }
                } else {
                    handleError("Username or password is incorrect", true);
                }
            });
        }
        handleError("Must enter username & password.", true);
    };

    if (token !== null){
        return (
            <Navigate to={'/home'} />
        );
    } else {
        return (
            <Container component="main" maxWidth="xs">
                <div
                    style={{
                        backgroundImage: `url(${background})`,
                        backgroundSize: 'cover',
                        height: '100%',
                        filter: 'blur(6px)', // Adjust the blur value as needed
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1, // Ensure the background is behind the content
                    }}
                />
                {error === true &&
                    <Alert severity="error" style={{margin: '0 auto', width: '90%'}}>{errorMsg}</Alert>
                }
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box style={{ marginTop: 1, padding: 10, borderRadius: 6, borderColor: "transparent", backgroundColor: 'rgba(255,255,255,0.53)' }}>
                        <TextField style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }} variant={'filled'} margin="normal" value={username} required fullWidth id="username" label="Email" name="username" autoComplete="username" onChange={(event) => {
                            setUsername(event.target.value)
                        }} />
                        <TextField style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }} variant={'filled'} margin="normal" value={password} required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" onChange={(event) => {
                            setPassword(event.target.value)
                        }} />
                        <Button type="submit" onClick={() => {
                            handleSubmit()
                        }} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link style={{ color: '#444242' }} onClick={() => { navigate('/reset') }} variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Link style={{ color: '#444242' }} onClick={() => { navigate('/signup') }} variant="body2">*/}
                            {/*        {"Don't have an account? Sign Up"}*/}
                            {/*    </Link>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
                <GetMissingAccountInfo open={open} setOpen={setOpen} accountData={accountData} userToken={userToken} />
            </Container>
        );
    }
}
