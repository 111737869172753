import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDispatch, useSelector} from "react-redux"; // Assuming you're using react-router for navigation
import {clearToken} from "../reducers/authenticationReducer";
import AuthorizationService from "../services/AuthorizationService";

const DeleteAccount = (props) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const tokenData = useSelector(state => state.authManager.tokenData);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function signOut() {
        dispatch(clearToken());
    }

    function deleteAccount(){
        AuthorizationService.deleteAccount(tokenData).then((res) => {
            if (res.status === true) {
                signOut();
                alert(res.message)
            } else {
                alert(res.message)
            }
        }).catch((err) => {
            alert("Account deletion failed. Please try again later.")
            console.log(err);
        });
    }

    return (
        <div>
            <Button color={'error'} style={styles.subscriptionBtn} variant="contained" onClick={handleClickOpen}>
                Delete Account
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Account Deletion Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Confirm account deletion
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteAccount} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const styles = {
    modalView: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    contentTitle: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 5,
    },
    mainText: {
        color: '#fff',
        fontWeight: '600',
        fontSize: 17,
        marginBottom: 10,
    },
    content: {
        width: '90vw',
        backgroundColor: '#18191a',
        padding: 10,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    textInput: {
        width: '70vw',
        backgroundColor: '#999999',
        padding: 5,
        borderRadius: 6,
        fontSize: '6vw',
    },
    subscriptionBtn: {
        width: '70vw',
        height: 40,
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        borderRadius: 10
    },
    subscriptionBtnText: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    action: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#f2f2f2',
        paddingBottom: 5
    },
    message: {
        fontSize: 20,
        alignSelf: 'center',
        fontWeight: 'bold',
    },
    text: {
        fontSize: 18,
        fontWeight: '300',
        alignSelf: 'flex-start',
        marginLeft: 15,
        marginRight: 5,
        color: '#ffffff',
    },
    subHeader: {
        fontSize: 16,
        fontWeight: '700',
        alignSelf: 'flex-start',
        marginLeft: 15,
        marginRight: 5,
        color: '#ffffff',
    },
};

export default DeleteAccount;
