import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {Alert, CardActions, CardMedia} from "@mui/material";
import {useDispatch} from "react-redux";
import StateSelect from "../StateSelect";
import BrandService from "../../services/brandService";
import Card from "@mui/material/Card";
import {addBrand} from "../../reducers/brandReducer";
import ProductService from "../../services/productService";
import {updateProduct} from "../../reducers/productReducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateProductVariation = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');


    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setName('');
        setDescription('');
    }

    const handleAdd = () => {
        if (name === ''){
            showAlertMsg('Name field is required!', 'error');
            return;
        }

        let data = new FormData();
        data.append("productID", props.product.productID);
        data.append("value", name);
        data.append("description", description);
        ProductService.createProductionVariation(data).then(response => {
            if (response.status === true){
                dispatch(updateProduct({
                    product: {
                        ...props.product,
                        productVariations: [
                            ...props.product.productVariations,
                            {
                                productID: props.product.productID,
                                productVariationID: response.result,
                                value: name,
                                description: (((description !== null) && (description !== '')) ? description : null)
                            }
                        ]
                    }
                }));
                showAlertMsg(response.message, 'success');
                clearFields();
            } else {
                showAlertMsg(response.message, 'error');
            }
        }).catch((err) => {
            console.log(err);
            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
        });
    };

    return (
        <div>
            <Button variant="outlined" style={{minWidth:175}} onClick={handleClickOpen}>
                Create Variation
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='lg'
                aria-labelledby="create-new-product-variation-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="create-new-product-variation-dialog-title" onClose={handleClose}>
                    Creating New Product Variation
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item  xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                    name="value"
                                    required
                                    fullWidth
                                    id="value"
                                    label="Name"
                                />
                            </Grid>
                            <Grid item  xs={12} sm={12}>
                                <TextField
                                    id="product-description-multiline"
                                    value={description}
                                    onChange={event => setDescription(event.target.value)}
                                    label="Description"
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} autoFocus onClick={handleAdd}>
                        Create Variation
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateProductVariation;
