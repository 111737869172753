import { createSlice } from '@reduxjs/toolkit'

export const storeReducer = createSlice({
    name: 'storeReducer',
    initialState: {
        stores: [],
        zipCodes: {
            enabled: [],
            disabled: [],
        }
    },
    reducers: {
        setStores: (state, action) => {
            if (action.payload.stores){
                state.stores = action.payload.stores;
            }
        },
        setZipCodes: (state, action) => {
            if (action.payload.zipCodes){
                state.zipCodes = action.payload.zipCodes;
            }
        },
        addStore: (state, action) => {
            if (action.payload.store){
                state.stores = [
                    ...state.stores,
                    action.payload.store
                ];
            }
        },
        updateStore: (state, action) => {
            if (action.payload.store){
                let newArray = state.stores;
                let objIndex = newArray.findIndex((obj => obj.storeID === action.payload.store.storeID));
                newArray[objIndex] = action.payload.store;
                state.products = newArray;
            }
        },
    },
})

export const { setStores, addStore, updateStore, setZipCodes } = storeReducer.actions

export default storeReducer;
