import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {useDispatch, useSelector} from "react-redux";
import {setZipCodes} from "../../reducers/storeReducer";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const ZipCodeTransfer = () => {
    const dispatch = useDispatch();

    const zipCodes = useSelector(state => state.storeManager.zipCodes);

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([0, 1, 2, 3]);
    const [right, setRight] = React.useState([4, 5, 6, 7]);

    const leftChecked = intersection(checked, zipCodes.disabled);
    const rightChecked = intersection(checked, zipCodes.enabled);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        dispatch(setZipCodes({
            zipCodes: {
                enabled: zipCodes.enabled.concat(zipCodes.disabled),
                disabled: []
            }
        }));
    };

    const handleCheckedRight = () => {
        dispatch(setZipCodes({
            zipCodes: {
                enabled: zipCodes.enabled.concat(leftChecked),
                disabled: not(zipCodes.disabled, leftChecked)
            }
        }));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        dispatch(setZipCodes({
            zipCodes: {
                enabled: not(zipCodes.enabled, rightChecked),
                disabled: zipCodes.disabled.concat(rightChecked)
            }
        }));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        dispatch(setZipCodes({
            zipCodes: {
                enabled: [],
                disabled: zipCodes.disabled.concat(zipCodes.enabled)
            }
        }));
    };

    const customList = (items) => (
        <Paper sx={{ width: 300, height: 300, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value.zipCodeID}-label`;
                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.city} | ${value.zipCode}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList(zipCodes.disabled)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={zipCodes.disabled.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={zipCodes.enabled.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(zipCodes.enabled)}</Grid>
        </Grid>
    );
}

export default ZipCodeTransfer;
